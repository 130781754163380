// Generated by `build/lib/terraform/generator/dynamodb_model.ts`

import {PlaceGeometry} from '@shared/api/definitions/google_places_api';
import {DoNotUseData} from '@shared/dynamo_tables_for_tests';
import {
  ExpertTicketProductBasePayload,
  ExpertTicketProductPayload,
  ExpertTicketProviderPayload,
  ExpertTicketTicketPayload,
} from '@shared/lib/hoobiiz/hoobiiz_expert_ticket_db_models';
import {HoobiizPermissionName} from '@shared/lib/hoobiiz/hoobiiz_groups_and_users_permissions';
import {
  asArrayOrThrow,
  asBooleanOrThrow,
  asConstantOrThrow,
  asMapOrThrow,
  asNumberOrThrow,
  asStringOrThrow,
  Brand,
  errorAsString,
} from '@shared/lib/type_utils';

export type AccountId = Brand<string, 'AccountId'>;
export type DataLakeId = Brand<string, 'DataLakeId'>;
export type EmailString = Brand<string, 'EmailString'>;
export type FrontendUserId = Brand<string, 'FrontendUserId'>;
export type SessionDuration = Brand<number, 'SessionDuration'>;
export type StreamArn = Brand<string, 'StreamArn'>;
export type ShardId = Brand<string, 'ShardId'>;
export type SequenceNumber = Brand<string, 'SequenceNumber'>;
export type ServiceName = Brand<string, 'ServiceName'>;
export type ServiceNodeId = Brand<string, 'ServiceNodeId'>;
export type TargetString = Brand<string, 'TargetString'>;
export type BuildId = Brand<string, 'BuildId'>;
export type CommitHash = Brand<string, 'CommitHash'>;
export type DatalakeElementItemId = Brand<string, 'DatalakeElementItemId'>;
export type TaskId = Brand<string, 'TaskId'>;
export type ReportingId = Brand<string, 'ReportingId'>;
export type HoobiizOrderItemId = Brand<string, 'HoobiizOrderItemId'>;
export type InstanceRequestId = Brand<string, 'InstanceRequestId'>;
export type ParameterKey = Brand<string, 'ParameterKey'>;
export type InstanceTypeName = Brand<string, 'InstanceTypeName'>;
export type DomainName = Brand<string, 'DomainName'>;
export type DatalakeApiToken = Brand<string, 'DatalakeApiToken'>;
export type DashboardId = Brand<string, 'DashboardId'>;
export type ServiceAdArtisanId = Brand<string, 'ServiceAdArtisanId'>;
export type ServiceAdCustomerId = Brand<string, 'ServiceAdCustomerId'>;
export type ServiceAdRequestId = Brand<string, 'ServiceAdRequestId'>;
export type StripePaymentIntentId = Brand<string, 'StripePaymentIntentId'>;
export type HoobiizMediaId = Brand<string, 'HoobiizMediaId'>;
export type HoobiizCat1Id = Brand<string, 'HoobiizCat1Id'>;
export type HoobiizCat2Id = Brand<string, 'HoobiizCat2Id'>;
export type HoobiizCat3Id = Brand<string, 'HoobiizCat3Id'>;
export type HoobiizVendorId = Brand<string, 'HoobiizVendorId'>;
export type Markdown = Brand<string, 'Markdown'>;
export type HoobiizActivityId = Brand<string, 'HoobiizActivityId'>;
export type HoobiizStockId = Brand<string, 'HoobiizStockId'>;
export type HoobiizStockBatchId = Brand<string, 'HoobiizStockBatchId'>;
export type HoobiizStockWeeklyTemplateId = Brand<string, 'HoobiizStockWeeklyTemplateId'>;
export type HoobiizExpertTicketStockId = Brand<string, 'HoobiizExpertTicketStockId'>;
export type HoobiizOfferId = Brand<string, 'HoobiizOfferId'>;
export type HoobiizTicketFileId = Brand<string, 'HoobiizTicketFileId'>;
export type HoobiizTicketPurchaseId = Brand<string, 'HoobiizTicketPurchaseId'>;
export type HoobiizTicketInfoId = Brand<string, 'HoobiizTicketInfoId'>;
export type HoobiizCartItemId = Brand<string, 'HoobiizCartItemId'>;
export type HoobiizTicketInfoOptionId = Brand<string, 'HoobiizTicketInfoOptionId'>;
export type HoobiizExpertTicketInfoId = Brand<string, 'HoobiizExpertTicketInfoId'>;
export type HoobiizExpertTicketProductId = Brand<string, 'HoobiizExpertTicketProductId'>;
export type HoobiizExpertTicketProviderId = Brand<string, 'HoobiizExpertTicketProviderId'>;
export type HoobiizExpertTicketProductBaseId = Brand<string, 'HoobiizExpertTicketProductBaseId'>;
export type HoobiizExpertTicketTicketId = Brand<string, 'HoobiizExpertTicketTicketId'>;
export type HoobiizExpertTicketMetadataKey = Brand<string, 'HoobiizExpertTicketMetadataKey'>;
export type HoobiizUserGroupId = Brand<string, 'HoobiizUserGroupId'>;
export type DoNotUseId = Brand<string, 'DoNotUseId'>;

export enum DoNotUseType {
  Foo = 10,
  Bar = 20,
}

export const DoNotUseTypeDebugString: Record<DoNotUseType, string> = {
  [DoNotUseType.Foo]: 'Foo',
  [DoNotUseType.Bar]: 'Bar',
};

export enum BuildStatus {
  Created = 10,
  InProgress = 20,
  Success = 30,
  Failure = 40,
  Canceled = 50,
  Allocated = 60,
}

export const BuildStatusDebugString: Record<BuildStatus, string> = {
  [BuildStatus.Created]: 'Created',
  [BuildStatus.InProgress]: 'InProgress',
  [BuildStatus.Success]: 'Success',
  [BuildStatus.Failure]: 'Failure',
  [BuildStatus.Canceled]: 'Canceled',
  [BuildStatus.Allocated]: 'Allocated',
};

export enum ServiceAdCustomerStatus {
  PendingAccountValidation = 10,
  AccountValidated = 20,
}

export const ServiceAdCustomerStatusDebugString: Record<ServiceAdCustomerStatus, string> = {
  [ServiceAdCustomerStatus.PendingAccountValidation]: 'PendingAccountValidation',
  [ServiceAdCustomerStatus.AccountValidated]: 'AccountValidated',
};

export enum ServiceAdRequestStatus {
  PendingPhoneValidation = 10,
  PendingArtisanAcceptation = 20,
  ArtisanAccepted = 30,
  ArtisanDenied = 40,
}

export const ServiceAdRequestStatusDebugString: Record<ServiceAdRequestStatus, string> = {
  [ServiceAdRequestStatus.PendingPhoneValidation]: 'PendingPhoneValidation',
  [ServiceAdRequestStatus.PendingArtisanAcceptation]: 'PendingArtisanAcceptation',
  [ServiceAdRequestStatus.ArtisanAccepted]: 'ArtisanAccepted',
  [ServiceAdRequestStatus.ArtisanDenied]: 'ArtisanDenied',
};

export enum TaskStatus {
  Created = 10,
  Scheduled = 15,
  InFlight = 20,
  Success = 30,
  Error = 40,
  Interrupted = 50,
}

export const TaskStatusDebugString: Record<TaskStatus, string> = {
  [TaskStatus.Created]: 'Created',
  [TaskStatus.Scheduled]: 'Scheduled',
  [TaskStatus.InFlight]: 'InFlight',
  [TaskStatus.Success]: 'Success',
  [TaskStatus.Error]: 'Error',
  [TaskStatus.Interrupted]: 'Interrupted',
};

export enum TaskType {
  AnalyticsDedup = 10,
  AnalyticsAggregation = 20,
  Build = 30,
  DataLake = 40,
  QontoDataSource = 50,
  Reporting = 60,
  HoobiizTickets = 70,
  HoobiizMedia = 80,
  ExpertTicket = 90,
  HoobiizPdf = 100,
}

export const TaskTypeDebugString: Record<TaskType, string> = {
  [TaskType.AnalyticsDedup]: 'AnalyticsDedup',
  [TaskType.AnalyticsAggregation]: 'AnalyticsAggregation',
  [TaskType.Build]: 'Build',
  [TaskType.DataLake]: 'DataLake',
  [TaskType.QontoDataSource]: 'QontoDataSource',
  [TaskType.Reporting]: 'Reporting',
  [TaskType.HoobiizTickets]: 'HoobiizTickets',
  [TaskType.HoobiizMedia]: 'HoobiizMedia',
  [TaskType.ExpertTicket]: 'ExpertTicket',
  [TaskType.HoobiizPdf]: 'HoobiizPdf',
};

export enum RedisValueType {
  FrontendUserInvite = 15,
  FrontendUserSession = 25,
  FrontendMagicLink = 35,
  CaptchaToken = 40,
  RateLimiting = 50,
  FrontendUserEmailVerification = 60,
}

export const RedisValueTypeDebugString: Record<RedisValueType, string> = {
  [RedisValueType.FrontendUserInvite]: 'FrontendUserInvite',
  [RedisValueType.FrontendUserSession]: 'FrontendUserSession',
  [RedisValueType.FrontendMagicLink]: 'FrontendMagicLink',
  [RedisValueType.CaptchaToken]: 'CaptchaToken',
  [RedisValueType.RateLimiting]: 'RateLimiting',
  [RedisValueType.FrontendUserEmailVerification]: 'FrontendUserEmailVerification',
};

export enum InstanceRequestType {
  InstanceType = 10,
  MinVcpusMemory = 20,
  Termination = 30,
}

export const InstanceRequestTypeDebugString: Record<InstanceRequestType, string> = {
  [InstanceRequestType.InstanceType]: 'InstanceType',
  [InstanceRequestType.MinVcpusMemory]: 'MinVcpusMemory',
  [InstanceRequestType.Termination]: 'Termination',
};

export enum DatalakeElementTypeGroup {
  DataSource = 10,
  Transformation = 20,
  Aggregation = 30,
  Output = 40,
}

export const DatalakeElementTypeGroupDebugString: Record<DatalakeElementTypeGroup, string> = {
  [DatalakeElementTypeGroup.DataSource]: 'DataSource',
  [DatalakeElementTypeGroup.Transformation]: 'Transformation',
  [DatalakeElementTypeGroup.Aggregation]: 'Aggregation',
  [DatalakeElementTypeGroup.Output]: 'Output',
};

export enum DatalakeElementType {
  WebEventsDataSource = 10,
  FileDropDataSource = 20,
  QontoDataSource = 30,
  IpGeolocTransformation = 40,
  CalculationsTransformation = 50,
  ExtractStringTransformation = 60,
  SumsAggregation = 70,
  GraphOutput = 80,
  RestApiOutput = 90,
  LabelTransformation = 100,
}

export const DatalakeElementTypeDebugString: Record<DatalakeElementType, string> = {
  [DatalakeElementType.WebEventsDataSource]: 'WebEventsDataSource',
  [DatalakeElementType.FileDropDataSource]: 'FileDropDataSource',
  [DatalakeElementType.QontoDataSource]: 'QontoDataSource',
  [DatalakeElementType.IpGeolocTransformation]: 'IpGeolocTransformation',
  [DatalakeElementType.CalculationsTransformation]: 'CalculationsTransformation',
  [DatalakeElementType.ExtractStringTransformation]: 'ExtractStringTransformation',
  [DatalakeElementType.SumsAggregation]: 'SumsAggregation',
  [DatalakeElementType.GraphOutput]: 'GraphOutput',
  [DatalakeElementType.RestApiOutput]: 'RestApiOutput',
  [DatalakeElementType.LabelTransformation]: 'LabelTransformation',
};

export enum DatalakePartitionType {
  None = 10,
  Hourly = 20,
  Daily = 30,
}

export const DatalakePartitionTypeDebugString: Record<DatalakePartitionType, string> = {
  [DatalakePartitionType.None]: 'None',
  [DatalakePartitionType.Hourly]: 'Hourly',
  [DatalakePartitionType.Daily]: 'Daily',
};

export enum ReportingBreakdown {
  Minutely = 10,
  Hourly = 20,
}

export const ReportingBreakdownDebugString: Record<ReportingBreakdown, string> = {
  [ReportingBreakdown.Minutely]: 'Minutely',
  [ReportingBreakdown.Hourly]: 'Hourly',
};

export enum DatalakeOutputColumnType {
  String = 10,
  Number = 20,
  Boolean = 30,
}

export const DatalakeOutputColumnTypeDebugString: Record<DatalakeOutputColumnType, string> = {
  [DatalakeOutputColumnType.String]: 'String',
  [DatalakeOutputColumnType.Number]: 'Number',
  [DatalakeOutputColumnType.Boolean]: 'Boolean',
};

export enum DatalakeTimeBreakdown {
  Hourly = 10,
}

export const DatalakeTimeBreakdownDebugString: Record<DatalakeTimeBreakdown, string> = {
  [DatalakeTimeBreakdown.Hourly]: 'Hourly',
};

export enum DatalakeRestApiFormat {
  Json = 10,
  Csv = 20,
}

export const DatalakeRestApiFormatDebugString: Record<DatalakeRestApiFormat, string> = {
  [DatalakeRestApiFormat.Json]: 'Json',
  [DatalakeRestApiFormat.Csv]: 'Csv',
};

export enum DatalakeCalculationOperator {
  Multiply = 10,
}

export const DatalakeCalculationOperatorDebugString: Record<DatalakeCalculationOperator, string> = {
  [DatalakeCalculationOperator.Multiply]: 'Multiply',
};

export enum HoobiizCartItemType {
  Ticket = 10,
  ExpertTicket = 20,
}

export const HoobiizCartItemTypeDebugString: Record<HoobiizCartItemType, string> = {
  [HoobiizCartItemType.Ticket]: 'Ticket',
  [HoobiizCartItemType.ExpertTicket]: 'ExpertTicket',
};

export enum HoobiizOrderItemType {
  Ticket = 10,
  ExpertTicket = 20,
}

export const HoobiizOrderItemTypeDebugString: Record<HoobiizOrderItemType, string> = {
  [HoobiizOrderItemType.Ticket]: 'Ticket',
  [HoobiizOrderItemType.ExpertTicket]: 'ExpertTicket',
};

export enum HoobiizOrderStatus {
  Pending = 10,
  Canceled = 20,
  Success = 30,
  PendingConfirmation = 40,
  Rejected = 50,
}

export const HoobiizOrderStatusDebugString: Record<HoobiizOrderStatus, string> = {
  [HoobiizOrderStatus.Pending]: 'Pending',
  [HoobiizOrderStatus.Canceled]: 'Canceled',
  [HoobiizOrderStatus.Success]: 'Success',
  [HoobiizOrderStatus.PendingConfirmation]: 'PendingConfirmation',
  [HoobiizOrderStatus.Rejected]: 'Rejected',
};

export enum Currency {
  Euro = 'eur',
}

export const CurrencyDebugString: Record<Currency, string> = {
  [Currency.Euro]: 'Euro',
};

export enum HoobiizVisibility {
  Public = 'Public',
  Private = 'Private',
  PrivateHidden = 'PrivateHidden',
}

export const HoobiizVisibilityDebugString: Record<HoobiizVisibility, string> = {
  [HoobiizVisibility.Public]: 'Public',
  [HoobiizVisibility.Private]: 'Private',
  [HoobiizVisibility.PrivateHidden]: 'PrivateHidden',
};

export enum HoobiizWeekday {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export const HoobiizWeekdayDebugString: Record<HoobiizWeekday, string> = {
  [HoobiizWeekday.Monday]: 'Monday',
  [HoobiizWeekday.Tuesday]: 'Tuesday',
  [HoobiizWeekday.Wednesday]: 'Wednesday',
  [HoobiizWeekday.Thursday]: 'Thursday',
  [HoobiizWeekday.Friday]: 'Friday',
  [HoobiizWeekday.Saturday]: 'Saturday',
  [HoobiizWeekday.Sunday]: 'Sunday',
};

export enum HoobiizTicketPurchaseStatus {
  Pending = 'Pending',
  CanceledUser = 'CanceledUser',
  CanceledTimeout = 'CanceledTimeout',
  AwaitingVendor = 'AwaitingVendor',
  DeclinedVendor = 'DeclinedVendor',
  AwaitingAdmin = 'AwaitingAdmin',
  DeclinedAdmin = 'DeclinedAdmin',
  Success = 'Success',
}

export const HoobiizTicketPurchaseStatusDebugString: Record<HoobiizTicketPurchaseStatus, string> = {
  [HoobiizTicketPurchaseStatus.Pending]: 'Pending',
  [HoobiizTicketPurchaseStatus.CanceledUser]: 'CanceledUser',
  [HoobiizTicketPurchaseStatus.CanceledTimeout]: 'CanceledTimeout',
  [HoobiizTicketPurchaseStatus.AwaitingVendor]: 'AwaitingVendor',
  [HoobiizTicketPurchaseStatus.DeclinedVendor]: 'DeclinedVendor',
  [HoobiizTicketPurchaseStatus.AwaitingAdmin]: 'AwaitingAdmin',
  [HoobiizTicketPurchaseStatus.DeclinedAdmin]: 'DeclinedAdmin',
  [HoobiizTicketPurchaseStatus.Success]: 'Success',
};

export enum FrontendUserAuthMethodType {
  MagicLink = 'MagicLink',
  MfaCode = 'MfaCode',
  Password = 'Password',
}

export const FrontendUserAuthMethodTypeDebugString: Record<FrontendUserAuthMethodType, string> = {
  [FrontendUserAuthMethodType.MagicLink]: 'MagicLink',
  [FrontendUserAuthMethodType.MfaCode]: 'MfaCode',
  [FrontendUserAuthMethodType.Password]: 'Password',
};

export enum HoobiizActivityAddressType {
  Vendor = 'Vendor',
  AtHome = 'AtHome',
  Manual = 'Manual',
}

export const HoobiizActivityAddressTypeDebugString: Record<HoobiizActivityAddressType, string> = {
  [HoobiizActivityAddressType.Vendor]: 'Vendor',
  [HoobiizActivityAddressType.AtHome]: 'AtHome',
  [HoobiizActivityAddressType.Manual]: 'Manual',
};

export enum HoobiizStockReservationType {
  /**
   * Tickets from the stock can be used at any time within a period.
   */
  Flexible = 'Flexible',
  /**
   * Tickets from the stock can only be used at a specific day and time.
   */
  Fixed = 'Fixed',
}

export const HoobiizStockReservationTypeDebugString: Record<HoobiizStockReservationType, string> = {
  [HoobiizStockReservationType.Flexible]: 'Flexible',
  [HoobiizStockReservationType.Fixed]: 'Fixed',
};

export enum HoobiizOfferTargetType {
  Global = 'Global',
  Vendor = 'Vendor',
  Activity = 'Activity',
}

export const HoobiizOfferTargetTypeDebugString: Record<HoobiizOfferTargetType, string> = {
  [HoobiizOfferTargetType.Global]: 'Global',
  [HoobiizOfferTargetType.Vendor]: 'Vendor',
  [HoobiizOfferTargetType.Activity]: 'Activity',
};

export enum HoobiizOfferFlashType {
  Disabled = 'Disabled',
  Period = 'Period',
}

export const HoobiizOfferFlashTypeDebugString: Record<HoobiizOfferFlashType, string> = {
  [HoobiizOfferFlashType.Disabled]: 'Disabled',
  [HoobiizOfferFlashType.Period]: 'Period',
};

export enum FrontendUserDataContentType {
  Onescale = 'Onescale',
  Hoobiiz = 'Hoobiiz',
}

export const FrontendUserDataContentTypeDebugString: Record<FrontendUserDataContentType, string> = {
  [FrontendUserDataContentType.Onescale]: 'Onescale',
  [FrontendUserDataContentType.Hoobiiz]: 'Hoobiiz',
};

export enum HoobiizDiscountType {
  Percent = 'Percent',
  Amount = 'Amount',
}

export const HoobiizDiscountTypeDebugString: Record<HoobiizDiscountType, string> = {
  [HoobiizDiscountType.Percent]: 'Percent',
  [HoobiizDiscountType.Amount]: 'Amount',
};

export enum HoobiizFlexibleStockScheduleType {
  Inherit = 'Inherit',
  Override = 'Override',
}

export const HoobiizFlexibleStockScheduleTypeDebugString: Record<
  HoobiizFlexibleStockScheduleType,
  string
> = {
  [HoobiizFlexibleStockScheduleType.Inherit]: 'Inherit',
  [HoobiizFlexibleStockScheduleType.Override]: 'Override',
};

export enum HoobiizStockModeType {
  /**
   * The ticket data was provided in the admin beforehand
   */
  Pregenerated = 'Pregenerated',
  /**
   * The ticket data is generated on the fly after the purchase
   */
  Automatic = 'Automatic',
  /**
   * The ticket data is generated on the fly once the vendor approve the
   * purchase
   */
  VendorConfirm = 'VendorConfirm',
  /**
   * The ticket data will be provided by the admin once they approve the
   * purchase
   */
  AdminConfirm = 'AdminConfirm',
}

export const HoobiizStockModeTypeDebugString: Record<HoobiizStockModeType, string> = {
  [HoobiizStockModeType.Pregenerated]: 'Pregenerated',
  [HoobiizStockModeType.Automatic]: 'Automatic',
  [HoobiizStockModeType.VendorConfirm]: 'VendorConfirm',
  [HoobiizStockModeType.AdminConfirm]: 'AdminConfirm',
};

export function parseDoNotUseType(value: number): DoNotUseType {
  if (![DoNotUseType.Foo, DoNotUseType.Bar].includes(value as DoNotUseType)) {
    throw new Error(`Invalid value ${value} for enum DoNotUseType`);
  }
  return value as DoNotUseType;
}
export function parseBuildStatus(value: number): BuildStatus {
  if (
    ![
      BuildStatus.Created,
      BuildStatus.InProgress,
      BuildStatus.Success,
      BuildStatus.Failure,
      BuildStatus.Canceled,
      BuildStatus.Allocated,
    ].includes(value as BuildStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum BuildStatus`);
  }
  return value as BuildStatus;
}
export function parseServiceAdCustomerStatus(value: number): ServiceAdCustomerStatus {
  if (
    ![
      ServiceAdCustomerStatus.PendingAccountValidation,
      ServiceAdCustomerStatus.AccountValidated,
    ].includes(value as ServiceAdCustomerStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum ServiceAdCustomerStatus`);
  }
  return value as ServiceAdCustomerStatus;
}
export function parseServiceAdRequestStatus(value: number): ServiceAdRequestStatus {
  if (
    ![
      ServiceAdRequestStatus.PendingPhoneValidation,
      ServiceAdRequestStatus.PendingArtisanAcceptation,
      ServiceAdRequestStatus.ArtisanAccepted,
      ServiceAdRequestStatus.ArtisanDenied,
    ].includes(value as ServiceAdRequestStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum ServiceAdRequestStatus`);
  }
  return value as ServiceAdRequestStatus;
}
export function parseTaskStatus(value: number): TaskStatus {
  if (
    ![
      TaskStatus.Created,
      TaskStatus.Scheduled,
      TaskStatus.InFlight,
      TaskStatus.Success,
      TaskStatus.Error,
      TaskStatus.Interrupted,
    ].includes(value as TaskStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum TaskStatus`);
  }
  return value as TaskStatus;
}
export function parseTaskType(value: number): TaskType {
  if (
    ![
      TaskType.AnalyticsDedup,
      TaskType.AnalyticsAggregation,
      TaskType.Build,
      TaskType.DataLake,
      TaskType.QontoDataSource,
      TaskType.Reporting,
      TaskType.HoobiizTickets,
      TaskType.HoobiizMedia,
      TaskType.ExpertTicket,
      TaskType.HoobiizPdf,
    ].includes(value as TaskType)
  ) {
    throw new Error(`Invalid value ${value} for enum TaskType`);
  }
  return value as TaskType;
}
export function parseRedisValueType(value: number): RedisValueType {
  if (
    ![
      RedisValueType.FrontendUserInvite,
      RedisValueType.FrontendUserSession,
      RedisValueType.FrontendMagicLink,
      RedisValueType.CaptchaToken,
      RedisValueType.RateLimiting,
      RedisValueType.FrontendUserEmailVerification,
    ].includes(value as RedisValueType)
  ) {
    throw new Error(`Invalid value ${value} for enum RedisValueType`);
  }
  return value as RedisValueType;
}
export function parseInstanceRequestType(value: number): InstanceRequestType {
  if (
    ![
      InstanceRequestType.InstanceType,
      InstanceRequestType.MinVcpusMemory,
      InstanceRequestType.Termination,
    ].includes(value as InstanceRequestType)
  ) {
    throw new Error(`Invalid value ${value} for enum InstanceRequestType`);
  }
  return value as InstanceRequestType;
}
export function parseDatalakeElementTypeGroup(value: number): DatalakeElementTypeGroup {
  if (
    ![
      DatalakeElementTypeGroup.DataSource,
      DatalakeElementTypeGroup.Transformation,
      DatalakeElementTypeGroup.Aggregation,
      DatalakeElementTypeGroup.Output,
    ].includes(value as DatalakeElementTypeGroup)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeElementTypeGroup`);
  }
  return value as DatalakeElementTypeGroup;
}
export function parseDatalakeElementType(value: number): DatalakeElementType {
  if (
    ![
      DatalakeElementType.WebEventsDataSource,
      DatalakeElementType.FileDropDataSource,
      DatalakeElementType.QontoDataSource,
      DatalakeElementType.IpGeolocTransformation,
      DatalakeElementType.CalculationsTransformation,
      DatalakeElementType.ExtractStringTransformation,
      DatalakeElementType.SumsAggregation,
      DatalakeElementType.GraphOutput,
      DatalakeElementType.RestApiOutput,
      DatalakeElementType.LabelTransformation,
    ].includes(value as DatalakeElementType)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeElementType`);
  }
  return value as DatalakeElementType;
}
export function parseDatalakePartitionType(value: number): DatalakePartitionType {
  if (
    ![
      DatalakePartitionType.None,
      DatalakePartitionType.Hourly,
      DatalakePartitionType.Daily,
    ].includes(value as DatalakePartitionType)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakePartitionType`);
  }
  return value as DatalakePartitionType;
}
export function parseReportingBreakdown(value: number): ReportingBreakdown {
  if (
    ![ReportingBreakdown.Minutely, ReportingBreakdown.Hourly].includes(value as ReportingBreakdown)
  ) {
    throw new Error(`Invalid value ${value} for enum ReportingBreakdown`);
  }
  return value as ReportingBreakdown;
}
export function parseDatalakeOutputColumnType(value: number): DatalakeOutputColumnType {
  if (
    ![
      DatalakeOutputColumnType.String,
      DatalakeOutputColumnType.Number,
      DatalakeOutputColumnType.Boolean,
    ].includes(value as DatalakeOutputColumnType)
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeOutputColumnType`);
  }
  return value as DatalakeOutputColumnType;
}
export function parseDatalakeTimeBreakdown(value: number): DatalakeTimeBreakdown {
  if (![DatalakeTimeBreakdown.Hourly].includes(value as DatalakeTimeBreakdown)) {
    throw new Error(`Invalid value ${value} for enum DatalakeTimeBreakdown`);
  }
  return value as DatalakeTimeBreakdown;
}
export function parseDatalakeRestApiFormat(value: number): DatalakeRestApiFormat {
  if (
    ![DatalakeRestApiFormat.Json, DatalakeRestApiFormat.Csv].includes(
      value as DatalakeRestApiFormat
    )
  ) {
    throw new Error(`Invalid value ${value} for enum DatalakeRestApiFormat`);
  }
  return value as DatalakeRestApiFormat;
}
export function parseDatalakeCalculationOperator(value: number): DatalakeCalculationOperator {
  if (![DatalakeCalculationOperator.Multiply].includes(value as DatalakeCalculationOperator)) {
    throw new Error(`Invalid value ${value} for enum DatalakeCalculationOperator`);
  }
  return value as DatalakeCalculationOperator;
}
export function parseHoobiizCartItemType(value: number): HoobiizCartItemType {
  if (
    ![HoobiizCartItemType.Ticket, HoobiizCartItemType.ExpertTicket].includes(
      value as HoobiizCartItemType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizCartItemType`);
  }
  return value as HoobiizCartItemType;
}
export function parseHoobiizOrderItemType(value: number): HoobiizOrderItemType {
  if (
    ![HoobiizOrderItemType.Ticket, HoobiizOrderItemType.ExpertTicket].includes(
      value as HoobiizOrderItemType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOrderItemType`);
  }
  return value as HoobiizOrderItemType;
}
export function parseHoobiizOrderStatus(value: number): HoobiizOrderStatus {
  if (
    ![
      HoobiizOrderStatus.Pending,
      HoobiizOrderStatus.Canceled,
      HoobiizOrderStatus.Success,
      HoobiizOrderStatus.PendingConfirmation,
      HoobiizOrderStatus.Rejected,
    ].includes(value as HoobiizOrderStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOrderStatus`);
  }
  return value as HoobiizOrderStatus;
}
export function parseCurrency(value: string): Currency {
  if (![Currency.Euro].includes(value as Currency)) {
    throw new Error(`Invalid value ${value} for enum Currency`);
  }
  return value as Currency;
}
export function parseHoobiizVisibility(value: string): HoobiizVisibility {
  if (
    ![
      HoobiizVisibility.Public,
      HoobiizVisibility.Private,
      HoobiizVisibility.PrivateHidden,
    ].includes(value as HoobiizVisibility)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizVisibility`);
  }
  return value as HoobiizVisibility;
}
export function parseHoobiizWeekday(value: string): HoobiizWeekday {
  if (
    ![
      HoobiizWeekday.Monday,
      HoobiizWeekday.Tuesday,
      HoobiizWeekday.Wednesday,
      HoobiizWeekday.Thursday,
      HoobiizWeekday.Friday,
      HoobiizWeekday.Saturday,
      HoobiizWeekday.Sunday,
    ].includes(value as HoobiizWeekday)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizWeekday`);
  }
  return value as HoobiizWeekday;
}
export function parseHoobiizTicketPurchaseStatus(value: string): HoobiizTicketPurchaseStatus {
  if (
    ![
      HoobiizTicketPurchaseStatus.Pending,
      HoobiizTicketPurchaseStatus.CanceledUser,
      HoobiizTicketPurchaseStatus.CanceledTimeout,
      HoobiizTicketPurchaseStatus.AwaitingVendor,
      HoobiizTicketPurchaseStatus.DeclinedVendor,
      HoobiizTicketPurchaseStatus.AwaitingAdmin,
      HoobiizTicketPurchaseStatus.DeclinedAdmin,
      HoobiizTicketPurchaseStatus.Success,
    ].includes(value as HoobiizTicketPurchaseStatus)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizTicketPurchaseStatus`);
  }
  return value as HoobiizTicketPurchaseStatus;
}
export function parseFrontendUserAuthMethodType(value: string): FrontendUserAuthMethodType {
  if (
    ![
      FrontendUserAuthMethodType.MagicLink,
      FrontendUserAuthMethodType.MfaCode,
      FrontendUserAuthMethodType.Password,
    ].includes(value as FrontendUserAuthMethodType)
  ) {
    throw new Error(`Invalid value ${value} for enum FrontendUserAuthMethodType`);
  }
  return value as FrontendUserAuthMethodType;
}
export function parseHoobiizActivityAddressType(value: string): HoobiizActivityAddressType {
  if (
    ![
      HoobiizActivityAddressType.Vendor,
      HoobiizActivityAddressType.AtHome,
      HoobiizActivityAddressType.Manual,
    ].includes(value as HoobiizActivityAddressType)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizActivityAddressType`);
  }
  return value as HoobiizActivityAddressType;
}
export function parseHoobiizStockReservationType(value: string): HoobiizStockReservationType {
  if (
    ![HoobiizStockReservationType.Flexible, HoobiizStockReservationType.Fixed].includes(
      value as HoobiizStockReservationType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizStockReservationType`);
  }
  return value as HoobiizStockReservationType;
}
export function parseHoobiizOfferTargetType(value: string): HoobiizOfferTargetType {
  if (
    ![
      HoobiizOfferTargetType.Global,
      HoobiizOfferTargetType.Vendor,
      HoobiizOfferTargetType.Activity,
    ].includes(value as HoobiizOfferTargetType)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOfferTargetType`);
  }
  return value as HoobiizOfferTargetType;
}
export function parseHoobiizOfferFlashType(value: string): HoobiizOfferFlashType {
  if (
    ![HoobiizOfferFlashType.Disabled, HoobiizOfferFlashType.Period].includes(
      value as HoobiizOfferFlashType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizOfferFlashType`);
  }
  return value as HoobiizOfferFlashType;
}
export function parseFrontendUserDataContentType(value: string): FrontendUserDataContentType {
  if (
    ![FrontendUserDataContentType.Onescale, FrontendUserDataContentType.Hoobiiz].includes(
      value as FrontendUserDataContentType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum FrontendUserDataContentType`);
  }
  return value as FrontendUserDataContentType;
}
export function parseHoobiizDiscountType(value: string): HoobiizDiscountType {
  if (
    ![HoobiizDiscountType.Percent, HoobiizDiscountType.Amount].includes(
      value as HoobiizDiscountType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizDiscountType`);
  }
  return value as HoobiizDiscountType;
}
export function parseHoobiizFlexibleStockScheduleType(
  value: string
): HoobiizFlexibleStockScheduleType {
  if (
    ![HoobiizFlexibleStockScheduleType.Inherit, HoobiizFlexibleStockScheduleType.Override].includes(
      value as HoobiizFlexibleStockScheduleType
    )
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizFlexibleStockScheduleType`);
  }
  return value as HoobiizFlexibleStockScheduleType;
}
export function parseHoobiizStockModeType(value: string): HoobiizStockModeType {
  if (
    ![
      HoobiizStockModeType.Pregenerated,
      HoobiizStockModeType.Automatic,
      HoobiizStockModeType.VendorConfirm,
      HoobiizStockModeType.AdminConfirm,
    ].includes(value as HoobiizStockModeType)
  ) {
    throw new Error(`Invalid value ${value} for enum HoobiizStockModeType`);
  }
  return value as HoobiizStockModeType;
}

export const enumDebug = {
  DoNotUseType: DoNotUseTypeDebugString,
  BuildStatus: BuildStatusDebugString,
  ServiceAdCustomerStatus: ServiceAdCustomerStatusDebugString,
  ServiceAdRequestStatus: ServiceAdRequestStatusDebugString,
  TaskStatus: TaskStatusDebugString,
  TaskType: TaskTypeDebugString,
  RedisValueType: RedisValueTypeDebugString,
  InstanceRequestType: InstanceRequestTypeDebugString,
  DatalakeElementTypeGroup: DatalakeElementTypeGroupDebugString,
  DatalakeElementType: DatalakeElementTypeDebugString,
  DatalakePartitionType: DatalakePartitionTypeDebugString,
  ReportingBreakdown: ReportingBreakdownDebugString,
  DatalakeOutputColumnType: DatalakeOutputColumnTypeDebugString,
  DatalakeTimeBreakdown: DatalakeTimeBreakdownDebugString,
  DatalakeRestApiFormat: DatalakeRestApiFormatDebugString,
  DatalakeCalculationOperator: DatalakeCalculationOperatorDebugString,
  HoobiizCartItemType: HoobiizCartItemTypeDebugString,
  HoobiizOrderItemType: HoobiizOrderItemTypeDebugString,
  HoobiizOrderStatus: HoobiizOrderStatusDebugString,
  Currency: CurrencyDebugString,
  HoobiizVisibility: HoobiizVisibilityDebugString,
  HoobiizWeekday: HoobiizWeekdayDebugString,
  HoobiizTicketPurchaseStatus: HoobiizTicketPurchaseStatusDebugString,
  FrontendUserAuthMethodType: FrontendUserAuthMethodTypeDebugString,
  HoobiizActivityAddressType: HoobiizActivityAddressTypeDebugString,
  HoobiizStockReservationType: HoobiizStockReservationTypeDebugString,
  HoobiizOfferTargetType: HoobiizOfferTargetTypeDebugString,
  HoobiizOfferFlashType: HoobiizOfferFlashTypeDebugString,
  FrontendUserDataContentType: FrontendUserDataContentTypeDebugString,
  HoobiizDiscountType: HoobiizDiscountTypeDebugString,
  HoobiizFlexibleStockScheduleType: HoobiizFlexibleStockScheduleTypeDebugString,
  HoobiizStockModeType: HoobiizStockModeTypeDebugString,
};

export interface AddressLookupResult {
  placeId: string;
  address: string;
  addressDetails: {
    streetNumber?: string;
    route?: string;
    city?: string;
    country?: string;
    postalCode?: string;
  };
  main: string;
  secondary: string;
  geometry: PlaceGeometry;
}

export function parseAddressLookupResult(json: Record<string, unknown>): AddressLookupResult {
  try {
    const parsedAddressLookupResultMap = asMapOrThrow(json);
    const parsedAddressLookupResultPlaceId = asStringOrThrow(
      parsedAddressLookupResultMap['placeId']
    );
    const parsedAddressLookupResultAddress = asStringOrThrow(
      parsedAddressLookupResultMap['address']
    );
    const parsedAddressLookupResultAddressDetailsMap = asMapOrThrow(
      parsedAddressLookupResultMap['addressDetails']
    );
    const parsedAddressLookupResultAddressDetailsStreetNumber =
      parsedAddressLookupResultAddressDetailsMap['streetNumber'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['streetNumber']);
    const parsedAddressLookupResultAddressDetailsRoute =
      parsedAddressLookupResultAddressDetailsMap['route'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['route']);
    const parsedAddressLookupResultAddressDetailsCity =
      parsedAddressLookupResultAddressDetailsMap['city'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['city']);
    const parsedAddressLookupResultAddressDetailsCountry =
      parsedAddressLookupResultAddressDetailsMap['country'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['country']);
    const parsedAddressLookupResultAddressDetailsPostalCode =
      parsedAddressLookupResultAddressDetailsMap['postalCode'] === undefined
        ? undefined
        : asStringOrThrow(parsedAddressLookupResultAddressDetailsMap['postalCode']);
    const parsedAddressLookupResultAddressDetails = {
      streetNumber: parsedAddressLookupResultAddressDetailsStreetNumber,
      route: parsedAddressLookupResultAddressDetailsRoute,
      city: parsedAddressLookupResultAddressDetailsCity,
      country: parsedAddressLookupResultAddressDetailsCountry,
      postalCode: parsedAddressLookupResultAddressDetailsPostalCode,
    };
    const parsedAddressLookupResultMain = asStringOrThrow(parsedAddressLookupResultMap['main']);
    const parsedAddressLookupResultSecondary = asStringOrThrow(
      parsedAddressLookupResultMap['secondary']
    );
    const parsedAddressLookupResultGeometry = parsedAddressLookupResultMap[
      'geometry'
    ] as PlaceGeometry;
    const parsedAddressLookupResult = {
      placeId: parsedAddressLookupResultPlaceId,
      address: parsedAddressLookupResultAddress,
      addressDetails: parsedAddressLookupResultAddressDetails,
      main: parsedAddressLookupResultMain,
      secondary: parsedAddressLookupResultSecondary,
      geometry: parsedAddressLookupResultGeometry,
    };
    return parsedAddressLookupResult;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse AddressLookupResult payload: ${errorAsString(
        err
      )}. Payload for AddressLookupResult was ${JSON.stringify(json)}`
    );
  }
}

export interface CurrencyAmount {
  currency: Currency;
  cents: number;
}

export function parseCurrencyAmount(json: Record<string, unknown>): CurrencyAmount {
  try {
    const parsedCurrencyAmountMap = asMapOrThrow(json);
    const parsedCurrencyAmountCurrency = parseCurrency(
      asStringOrThrow(parsedCurrencyAmountMap['currency'])
    );
    const parsedCurrencyAmountCents = asNumberOrThrow(parsedCurrencyAmountMap['cents']);
    const parsedCurrencyAmount = {
      currency: parsedCurrencyAmountCurrency,
      cents: parsedCurrencyAmountCents,
    };
    return parsedCurrencyAmount;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse CurrencyAmount payload: ${errorAsString(
        err
      )}. Payload for CurrencyAmount was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTimePeriod {
  startTs: number;
  endTs: number;
}

export function parseHoobiizTimePeriod(json: Record<string, unknown>): HoobiizTimePeriod {
  try {
    const parsedHoobiizTimePeriodMap = asMapOrThrow(json);
    const parsedHoobiizTimePeriodStartTs = asNumberOrThrow(parsedHoobiizTimePeriodMap['startTs']);
    const parsedHoobiizTimePeriodEndTs = asNumberOrThrow(parsedHoobiizTimePeriodMap['endTs']);
    const parsedHoobiizTimePeriod = {
      startTs: parsedHoobiizTimePeriodStartTs,
      endTs: parsedHoobiizTimePeriodEndTs,
    };
    return parsedHoobiizTimePeriod;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTimePeriod payload: ${errorAsString(
        err
      )}. Payload for HoobiizTimePeriod was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTimeOfDay {
  startHour: number;
  startMinute: number;
  endHour: number;
  endMinute: number;
}

export function parseHoobiizTimeOfDay(json: Record<string, unknown>): HoobiizTimeOfDay {
  try {
    const parsedHoobiizTimeOfDayMap = asMapOrThrow(json);
    const parsedHoobiizTimeOfDayStartHour = asNumberOrThrow(parsedHoobiizTimeOfDayMap['startHour']);
    const parsedHoobiizTimeOfDayStartMinute = asNumberOrThrow(
      parsedHoobiizTimeOfDayMap['startMinute']
    );
    const parsedHoobiizTimeOfDayEndHour = asNumberOrThrow(parsedHoobiizTimeOfDayMap['endHour']);
    const parsedHoobiizTimeOfDayEndMinute = asNumberOrThrow(parsedHoobiizTimeOfDayMap['endMinute']);
    const parsedHoobiizTimeOfDay = {
      startHour: parsedHoobiizTimeOfDayStartHour,
      startMinute: parsedHoobiizTimeOfDayStartMinute,
      endHour: parsedHoobiizTimeOfDayEndHour,
      endMinute: parsedHoobiizTimeOfDayEndMinute,
    };
    return parsedHoobiizTimeOfDay;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTimeOfDay payload: ${errorAsString(
        err
      )}. Payload for HoobiizTimeOfDay was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizOpeningHoursOverride {
  day: number;
  reason: string;
}

export function parseHoobiizOpeningHoursOverride(
  json: Record<string, unknown>
): HoobiizOpeningHoursOverride {
  try {
    const parsedHoobiizOpeningHoursOverrideMap = asMapOrThrow(json);
    const parsedHoobiizOpeningHoursOverrideDay = asNumberOrThrow(
      parsedHoobiizOpeningHoursOverrideMap['day']
    );
    const parsedHoobiizOpeningHoursOverrideReason = asStringOrThrow(
      parsedHoobiizOpeningHoursOverrideMap['reason']
    );
    const parsedHoobiizOpeningHoursOverride = {
      day: parsedHoobiizOpeningHoursOverrideDay,
      reason: parsedHoobiizOpeningHoursOverrideReason,
    };
    return parsedHoobiizOpeningHoursOverride;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOpeningHoursOverride payload: ${errorAsString(
        err
      )}. Payload for HoobiizOpeningHoursOverride was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizVendorContact {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export function parseHoobiizVendorContact(json: Record<string, unknown>): HoobiizVendorContact {
  try {
    const parsedHoobiizVendorContactMap = asMapOrThrow(json);
    const parsedHoobiizVendorContactFirstName = asStringOrThrow(
      parsedHoobiizVendorContactMap['firstName']
    );
    const parsedHoobiizVendorContactLastName = asStringOrThrow(
      parsedHoobiizVendorContactMap['lastName']
    );
    const parsedHoobiizVendorContactEmail = asStringOrThrow(parsedHoobiizVendorContactMap['email']);
    const parsedHoobiizVendorContactPhone = asStringOrThrow(parsedHoobiizVendorContactMap['phone']);
    const parsedHoobiizVendorContact = {
      firstName: parsedHoobiizVendorContactFirstName,
      lastName: parsedHoobiizVendorContactLastName,
      email: parsedHoobiizVendorContactEmail,
      phone: parsedHoobiizVendorContactPhone,
    };
    return parsedHoobiizVendorContact;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorContact payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorContact was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTicketData {
  files?: {id: HoobiizTicketFileId}[];
  code?: {value: string};
}

export function parseHoobiizTicketData(json: Record<string, unknown>): HoobiizTicketData {
  try {
    const parsedHoobiizTicketDataMap = asMapOrThrow(json);
    const parsedHoobiizTicketDataFiles =
      parsedHoobiizTicketDataMap['files'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizTicketDataMap['files']).map(element => {
            const parsedElementMap = asMapOrThrow(element);
            const parsedElementId = asStringOrThrow<HoobiizTicketFileId>(parsedElementMap['id']);
            const parsedElement = {
              id: parsedElementId,
            };
            return parsedElement;
          });
    const parsedHoobiizTicketDataCodeMap =
      parsedHoobiizTicketDataMap['code'] === undefined
        ? undefined
        : asMapOrThrow(parsedHoobiizTicketDataMap['code']);
    const parsedHoobiizTicketDataCode =
      parsedHoobiizTicketDataCodeMap === undefined
        ? undefined
        : (() => {
            const parsedHoobiizTicketDataCodeValue = asStringOrThrow(
              parsedHoobiizTicketDataCodeMap['value']
            );
            return {
              value: parsedHoobiizTicketDataCodeValue,
            };
          })();
    const parsedHoobiizTicketData = {
      files: parsedHoobiizTicketDataFiles,
      code: parsedHoobiizTicketDataCode,
    };
    return parsedHoobiizTicketData;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTicketData payload: ${errorAsString(
        err
      )}. Payload for HoobiizTicketData was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizExpertTicketInfo {
  id: HoobiizExpertTicketInfoId;
  label: string;
  description?: string;
  productId: HoobiizExpertTicketProductId;
}

export function parseHoobiizExpertTicketInfo(
  json: Record<string, unknown>
): HoobiizExpertTicketInfo {
  try {
    const parsedHoobiizExpertTicketInfoMap = asMapOrThrow(json);
    const parsedHoobiizExpertTicketInfoId = asStringOrThrow<HoobiizExpertTicketInfoId>(
      parsedHoobiizExpertTicketInfoMap['id']
    );
    const parsedHoobiizExpertTicketInfoLabel = asStringOrThrow(
      parsedHoobiizExpertTicketInfoMap['label']
    );
    const parsedHoobiizExpertTicketInfoDescription =
      parsedHoobiizExpertTicketInfoMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizExpertTicketInfoMap['description']);
    const parsedHoobiizExpertTicketInfoProductId = asStringOrThrow<HoobiizExpertTicketProductId>(
      parsedHoobiizExpertTicketInfoMap['productId']
    );
    const parsedHoobiizExpertTicketInfo = {
      id: parsedHoobiizExpertTicketInfoId,
      label: parsedHoobiizExpertTicketInfoLabel,
      description: parsedHoobiizExpertTicketInfoDescription,
      productId: parsedHoobiizExpertTicketInfoProductId,
    };
    return parsedHoobiizExpertTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizExpertTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizExpertTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizPermission {
  name: HoobiizPermissionName;
  value: boolean;
}

export function parseHoobiizPermission(json: Record<string, unknown>): HoobiizPermission {
  try {
    const parsedHoobiizPermissionMap = asMapOrThrow(json);
    const parsedHoobiizPermissionName = parsedHoobiizPermissionMap['name'] as HoobiizPermissionName;
    const parsedHoobiizPermissionValue = asBooleanOrThrow(parsedHoobiizPermissionMap['value']);
    const parsedHoobiizPermission = {
      name: parsedHoobiizPermissionName,
      value: parsedHoobiizPermissionValue,
    };
    return parsedHoobiizPermission;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizPermission payload: ${errorAsString(
        err
      )}. Payload for HoobiizPermission was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizDiscountPercent {
  type: HoobiizDiscountType.Percent;
  percent: number;
}

export function parseHoobiizDiscountPercent(
  json: Record<string, unknown>
): Omit<HoobiizDiscountPercent, 'type'> {
  try {
    const parsedHoobiizDiscountPercentMap = asMapOrThrow(json);
    const parsedHoobiizDiscountPercentPercent = asNumberOrThrow(
      parsedHoobiizDiscountPercentMap['percent']
    );
    const parsedHoobiizDiscountPercent = {
      percent: parsedHoobiizDiscountPercentPercent,
    };
    return parsedHoobiizDiscountPercent;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizDiscountPercent: ${errorAsString(err)}`);
  }
}

export interface HoobiizDiscountAmount {
  type: HoobiizDiscountType.Amount;
  amount: CurrencyAmount;
}

export function parseHoobiizDiscountAmount(
  json: Record<string, unknown>
): Omit<HoobiizDiscountAmount, 'type'> {
  try {
    const parsedHoobiizDiscountAmountMap = asMapOrThrow(json);
    const parsedHoobiizDiscountAmountAmount = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizDiscountAmountMap['amount'])
    );
    const parsedHoobiizDiscountAmount = {
      amount: parsedHoobiizDiscountAmountAmount,
    };
    return parsedHoobiizDiscountAmount;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizDiscountAmount: ${errorAsString(err)}`);
  }
}

export type HoobiizDiscount = HoobiizDiscountPercent | HoobiizDiscountAmount;

export function parseHoobiizDiscount(json: Record<string, unknown>): HoobiizDiscount {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizDiscountType.Percent) {
      return {type, ...parseHoobiizDiscountPercent(json)};
    } else if (type === HoobiizDiscountType.Amount) {
      return {type, ...parseHoobiizDiscountAmount(json)};
    }
    throw new Error(`Failure to parse HoobiizDiscount: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizDiscount: ${errorAsString(err)}.`);
  }
}

export interface HoobiizWeekPeriods {
  Monday: HoobiizTimeOfDay[];
  Tuesday: HoobiizTimeOfDay[];
  Wednesday: HoobiizTimeOfDay[];
  Thursday: HoobiizTimeOfDay[];
  Friday: HoobiizTimeOfDay[];
  Saturday: HoobiizTimeOfDay[];
  Sunday: HoobiizTimeOfDay[];
}

export function parseHoobiizWeekPeriods(json: Record<string, unknown>): HoobiizWeekPeriods {
  try {
    const parsedHoobiizWeekPeriodsMap = asMapOrThrow(json);
    const parsedHoobiizWeekPeriodsMonday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Monday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsTuesday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Tuesday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsWednesday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Wednesday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsThursday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Thursday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsFriday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Friday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsSaturday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Saturday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriodsSunday = asArrayOrThrow(
      parsedHoobiizWeekPeriodsMap['Sunday']
    ).map(element => {
      const parsedElement = parseHoobiizTimeOfDay(asMapOrThrow(element));
      return parsedElement;
    });
    const parsedHoobiizWeekPeriods = {
      Monday: parsedHoobiizWeekPeriodsMonday,
      Tuesday: parsedHoobiizWeekPeriodsTuesday,
      Wednesday: parsedHoobiizWeekPeriodsWednesday,
      Thursday: parsedHoobiizWeekPeriodsThursday,
      Friday: parsedHoobiizWeekPeriodsFriday,
      Saturday: parsedHoobiizWeekPeriodsSaturday,
      Sunday: parsedHoobiizWeekPeriodsSunday,
    };
    return parsedHoobiizWeekPeriods;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizWeekPeriods payload: ${errorAsString(
        err
      )}. Payload for HoobiizWeekPeriods was ${JSON.stringify(json)}`
    );
  }
}

/**
 * The ticket data was provided in the admin beforehand
 */
export interface HoobiizStockModePregenerated {
  type: HoobiizStockModeType.Pregenerated;
  data: HoobiizTicketData;
}

export function parseHoobiizStockModePregenerated(
  json: Record<string, unknown>
): Omit<HoobiizStockModePregenerated, 'type'> {
  try {
    const parsedHoobiizStockModePregeneratedMap = asMapOrThrow(json);
    const parsedHoobiizStockModePregeneratedData = parseHoobiizTicketData(
      asMapOrThrow(parsedHoobiizStockModePregeneratedMap['data'])
    );
    const parsedHoobiizStockModePregenerated = {
      data: parsedHoobiizStockModePregeneratedData,
    };
    return parsedHoobiizStockModePregenerated;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockModePregenerated: ${errorAsString(err)}`);
  }
}

/**
 * The ticket data is generated on the fly after the purchase
 */
export interface HoobiizStockModeAutomatic {
  type: HoobiizStockModeType.Automatic;
  sendEmail: boolean;
}

export function parseHoobiizStockModeAutomatic(
  json: Record<string, unknown>
): Omit<HoobiizStockModeAutomatic, 'type'> {
  try {
    const parsedHoobiizStockModeAutomaticMap = asMapOrThrow(json);
    const parsedHoobiizStockModeAutomaticSendEmail = asBooleanOrThrow(
      parsedHoobiizStockModeAutomaticMap['sendEmail']
    );
    const parsedHoobiizStockModeAutomatic = {
      sendEmail: parsedHoobiizStockModeAutomaticSendEmail,
    };
    return parsedHoobiizStockModeAutomatic;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockModeAutomatic: ${errorAsString(err)}`);
  }
}

/**
 * The ticket data is generated on the fly once the vendor approve the
 * purchase
 */
export interface HoobiizStockModeVendorConfirm {
  type: HoobiizStockModeType.VendorConfirm;
  email?: string;
}

export function parseHoobiizStockModeVendorConfirm(
  json: Record<string, unknown>
): Omit<HoobiizStockModeVendorConfirm, 'type'> {
  try {
    const parsedHoobiizStockModeVendorConfirmMap = asMapOrThrow(json);
    const parsedHoobiizStockModeVendorConfirmEmail =
      parsedHoobiizStockModeVendorConfirmMap['email'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizStockModeVendorConfirmMap['email']);
    const parsedHoobiizStockModeVendorConfirm = {
      email: parsedHoobiizStockModeVendorConfirmEmail,
    };
    return parsedHoobiizStockModeVendorConfirm;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockModeVendorConfirm: ${errorAsString(err)}`);
  }
}

/**
 * The ticket data will be provided by the admin once they approve the
 * purchase
 */
export interface HoobiizStockModeAdminConfirm {
  type: HoobiizStockModeType.AdminConfirm;
}

export function parseHoobiizStockModeAdminConfirm(): Omit<HoobiizStockModeAdminConfirm, 'type'> {
  return {};
}

export type HoobiizStockMode =
  | HoobiizStockModePregenerated
  | HoobiizStockModeAutomatic
  | HoobiizStockModeVendorConfirm
  | HoobiizStockModeAdminConfirm;

export function parseHoobiizStockMode(json: Record<string, unknown>): HoobiizStockMode {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizStockModeType.Pregenerated) {
      return {type, ...parseHoobiizStockModePregenerated(json)};
    } else if (type === HoobiizStockModeType.Automatic) {
      return {type, ...parseHoobiizStockModeAutomatic(json)};
    } else if (type === HoobiizStockModeType.VendorConfirm) {
      return {type, ...parseHoobiizStockModeVendorConfirm(json)};
    } else if (type === HoobiizStockModeType.AdminConfirm) {
      return {type, ...parseHoobiizStockModeAdminConfirm()};
    }
    throw new Error(`Failure to parse HoobiizStockMode: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockMode: ${errorAsString(err)}.`);
  }
}

export interface HoobiizTicketInfoOption {
  id: HoobiizTicketInfoOptionId;
  label: string;
  description?: string;
  buyingPrice: CurrencyAmount;
  publicPrice: CurrencyAmount;
  youpiizPrice: CurrencyAmount;
  minQuantity?: number;
  maxQuantity?: number;
}

export function parseHoobiizTicketInfoOption(
  json: Record<string, unknown>
): HoobiizTicketInfoOption {
  try {
    const parsedHoobiizTicketInfoOptionMap = asMapOrThrow(json);
    const parsedHoobiizTicketInfoOptionId = asStringOrThrow<HoobiizTicketInfoOptionId>(
      parsedHoobiizTicketInfoOptionMap['id']
    );
    const parsedHoobiizTicketInfoOptionLabel = asStringOrThrow(
      parsedHoobiizTicketInfoOptionMap['label']
    );
    const parsedHoobiizTicketInfoOptionDescription =
      parsedHoobiizTicketInfoOptionMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizTicketInfoOptionMap['description']);
    const parsedHoobiizTicketInfoOptionBuyingPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoOptionMap['buyingPrice'])
    );
    const parsedHoobiizTicketInfoOptionPublicPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoOptionMap['publicPrice'])
    );
    const parsedHoobiizTicketInfoOptionYoupiizPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoOptionMap['youpiizPrice'])
    );
    const parsedHoobiizTicketInfoOptionMinQuantity =
      parsedHoobiizTicketInfoOptionMap['minQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoOptionMap['minQuantity']);
    const parsedHoobiizTicketInfoOptionMaxQuantity =
      parsedHoobiizTicketInfoOptionMap['maxQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoOptionMap['maxQuantity']);
    const parsedHoobiizTicketInfoOption = {
      id: parsedHoobiizTicketInfoOptionId,
      label: parsedHoobiizTicketInfoOptionLabel,
      description: parsedHoobiizTicketInfoOptionDescription,
      buyingPrice: parsedHoobiizTicketInfoOptionBuyingPrice,
      publicPrice: parsedHoobiizTicketInfoOptionPublicPrice,
      youpiizPrice: parsedHoobiizTicketInfoOptionYoupiizPrice,
      minQuantity: parsedHoobiizTicketInfoOptionMinQuantity,
      maxQuantity: parsedHoobiizTicketInfoOptionMaxQuantity,
    };
    return parsedHoobiizTicketInfoOption;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTicketInfoOption payload: ${errorAsString(
        err
      )}. Payload for HoobiizTicketInfoOption was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizPrices {
  price1: CurrencyAmount;
  price2: CurrencyAmount;
  price3: CurrencyAmount;
}

export function parseHoobiizPrices(json: Record<string, unknown>): HoobiizPrices {
  try {
    const parsedHoobiizPricesMap = asMapOrThrow(json);
    const parsedHoobiizPricesPrice1 = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizPricesMap['price1'])
    );
    const parsedHoobiizPricesPrice2 = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizPricesMap['price2'])
    );
    const parsedHoobiizPricesPrice3 = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizPricesMap['price3'])
    );
    const parsedHoobiizPrices = {
      price1: parsedHoobiizPricesPrice1,
      price2: parsedHoobiizPricesPrice2,
      price3: parsedHoobiizPricesPrice3,
    };
    return parsedHoobiizPrices;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizPrices payload: ${errorAsString(
        err
      )}. Payload for HoobiizPrices was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizUserProfile {
  permissions?: HoobiizPermission[];
  whiteLabeling?: {logo?: HoobiizMediaId};
}

export function parseHoobiizUserProfile(json: Record<string, unknown>): HoobiizUserProfile {
  try {
    const parsedHoobiizUserProfileMap = asMapOrThrow(json);
    const parsedHoobiizUserProfilePermissions =
      parsedHoobiizUserProfileMap['permissions'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizUserProfileMap['permissions']).map(element => {
            const parsedElement = parseHoobiizPermission(asMapOrThrow(element));
            return parsedElement;
          });
    const parsedHoobiizUserProfileWhiteLabelingMap =
      parsedHoobiizUserProfileMap['whiteLabeling'] === undefined
        ? undefined
        : asMapOrThrow(parsedHoobiizUserProfileMap['whiteLabeling']);
    const parsedHoobiizUserProfileWhiteLabeling =
      parsedHoobiizUserProfileWhiteLabelingMap === undefined
        ? undefined
        : (() => {
            const parsedHoobiizUserProfileWhiteLabelingLogo =
              parsedHoobiizUserProfileWhiteLabelingMap['logo'] === undefined
                ? undefined
                : asStringOrThrow<HoobiizMediaId>(parsedHoobiizUserProfileWhiteLabelingMap['logo']);
            return {
              logo: parsedHoobiizUserProfileWhiteLabelingLogo,
            };
          })();
    const parsedHoobiizUserProfile = {
      permissions: parsedHoobiizUserProfilePermissions,
      whiteLabeling: parsedHoobiizUserProfileWhiteLabeling,
    };
    return parsedHoobiizUserProfile;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizUserProfile payload: ${errorAsString(
        err
      )}. Payload for HoobiizUserProfile was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserDataContentOnescale {
  type: FrontendUserDataContentType.Onescale;
  accountId: AccountId;
  isRoot: boolean;
}

export function parseFrontendUserDataContentOnescale(
  json: Record<string, unknown>
): Omit<FrontendUserDataContentOnescale, 'type'> {
  try {
    const parsedFrontendUserDataContentOnescaleMap = asMapOrThrow(json);
    const parsedFrontendUserDataContentOnescaleAccountId = asStringOrThrow<AccountId>(
      parsedFrontendUserDataContentOnescaleMap['accountId']
    );
    const parsedFrontendUserDataContentOnescaleIsRoot = asBooleanOrThrow(
      parsedFrontendUserDataContentOnescaleMap['isRoot']
    );
    const parsedFrontendUserDataContentOnescale = {
      accountId: parsedFrontendUserDataContentOnescaleAccountId,
      isRoot: parsedFrontendUserDataContentOnescaleIsRoot,
    };
    return parsedFrontendUserDataContentOnescale;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserDataContentOnescale: ${errorAsString(err)}`);
  }
}

export interface FrontendUserDataContentHoobiiz {
  type: FrontendUserDataContentType.Hoobiiz;
  firstName: string;
  lastName: string;
  groups?: HoobiizUserGroupId[];
  overrides?: HoobiizUserProfile;
}

export function parseFrontendUserDataContentHoobiiz(
  json: Record<string, unknown>
): Omit<FrontendUserDataContentHoobiiz, 'type'> {
  try {
    const parsedFrontendUserDataContentHoobiizMap = asMapOrThrow(json);
    const parsedFrontendUserDataContentHoobiizFirstName = asStringOrThrow(
      parsedFrontendUserDataContentHoobiizMap['firstName']
    );
    const parsedFrontendUserDataContentHoobiizLastName = asStringOrThrow(
      parsedFrontendUserDataContentHoobiizMap['lastName']
    );
    const parsedFrontendUserDataContentHoobiizGroups =
      parsedFrontendUserDataContentHoobiizMap['groups'] === undefined
        ? undefined
        : asArrayOrThrow(parsedFrontendUserDataContentHoobiizMap['groups']).map(element => {
            const parsedElement = asStringOrThrow<HoobiizUserGroupId>(element);
            return parsedElement;
          });
    const parsedFrontendUserDataContentHoobiizOverrides =
      parsedFrontendUserDataContentHoobiizMap['overrides'] === undefined
        ? undefined
        : parseHoobiizUserProfile(
            asMapOrThrow(parsedFrontendUserDataContentHoobiizMap['overrides'])
          );
    const parsedFrontendUserDataContentHoobiiz = {
      firstName: parsedFrontendUserDataContentHoobiizFirstName,
      lastName: parsedFrontendUserDataContentHoobiizLastName,
      groups: parsedFrontendUserDataContentHoobiizGroups,
      overrides: parsedFrontendUserDataContentHoobiizOverrides,
    };
    return parsedFrontendUserDataContentHoobiiz;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserDataContentHoobiiz: ${errorAsString(err)}`);
  }
}

export type FrontendUserDataContent =
  | FrontendUserDataContentOnescale
  | FrontendUserDataContentHoobiiz;

export function parseFrontendUserDataContent(
  json: Record<string, unknown>
): FrontendUserDataContent {
  try {
    const type = asStringOrThrow(json.type);
    if (type === FrontendUserDataContentType.Onescale) {
      return {type, ...parseFrontendUserDataContentOnescale(json)};
    } else if (type === FrontendUserDataContentType.Hoobiiz) {
      return {type, ...parseFrontendUserDataContentHoobiiz(json)};
    }
    throw new Error(`Failure to parse FrontendUserDataContent: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserDataContent: ${errorAsString(err)}.`);
  }
}

export interface HoobiizOpeningHours {
  weekdays: HoobiizWeekPeriods;
  overrides?: HoobiizOpeningHoursOverride[];
}

export function parseHoobiizOpeningHours(json: Record<string, unknown>): HoobiizOpeningHours {
  try {
    const parsedHoobiizOpeningHoursMap = asMapOrThrow(json);
    const parsedHoobiizOpeningHoursWeekdays = parseHoobiizWeekPeriods(
      asMapOrThrow(parsedHoobiizOpeningHoursMap['weekdays'])
    );
    const parsedHoobiizOpeningHoursOverrides =
      parsedHoobiizOpeningHoursMap['overrides'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizOpeningHoursMap['overrides']).map(element => {
            const parsedElement = parseHoobiizOpeningHoursOverride(asMapOrThrow(element));
            return parsedElement;
          });
    const parsedHoobiizOpeningHours = {
      weekdays: parsedHoobiizOpeningHoursWeekdays,
      overrides: parsedHoobiizOpeningHoursOverrides,
    };
    return parsedHoobiizOpeningHours;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOpeningHours payload: ${errorAsString(
        err
      )}. Payload for HoobiizOpeningHours was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTicketInfo {
  id: HoobiizTicketInfoId;
  label: string;
  description?: string;
  buyingPrice: CurrencyAmount;
  publicPrice: CurrencyAmount;
  youpiizPrice: CurrencyAmount;
  fees?: CurrencyAmount;
  span?: number;
  visibility: HoobiizVisibility;
  minQuantity?: number;
  maxQuantity?: number;
  options?: HoobiizTicketInfoOption[];
}

export function parseHoobiizTicketInfo(json: Record<string, unknown>): HoobiizTicketInfo {
  try {
    const parsedHoobiizTicketInfoMap = asMapOrThrow(json);
    const parsedHoobiizTicketInfoId = asStringOrThrow<HoobiizTicketInfoId>(
      parsedHoobiizTicketInfoMap['id']
    );
    const parsedHoobiizTicketInfoLabel = asStringOrThrow(parsedHoobiizTicketInfoMap['label']);
    const parsedHoobiizTicketInfoDescription =
      parsedHoobiizTicketInfoMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizTicketInfoMap['description']);
    const parsedHoobiizTicketInfoBuyingPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoMap['buyingPrice'])
    );
    const parsedHoobiizTicketInfoPublicPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoMap['publicPrice'])
    );
    const parsedHoobiizTicketInfoYoupiizPrice = parseCurrencyAmount(
      asMapOrThrow(parsedHoobiizTicketInfoMap['youpiizPrice'])
    );
    const parsedHoobiizTicketInfoFees =
      parsedHoobiizTicketInfoMap['fees'] === undefined
        ? undefined
        : parseCurrencyAmount(asMapOrThrow(parsedHoobiizTicketInfoMap['fees']));
    const parsedHoobiizTicketInfoSpan =
      parsedHoobiizTicketInfoMap['span'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoMap['span']);
    const parsedHoobiizTicketInfoVisibility = parseHoobiizVisibility(
      asStringOrThrow(parsedHoobiizTicketInfoMap['visibility'])
    );
    const parsedHoobiizTicketInfoMinQuantity =
      parsedHoobiizTicketInfoMap['minQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoMap['minQuantity']);
    const parsedHoobiizTicketInfoMaxQuantity =
      parsedHoobiizTicketInfoMap['maxQuantity'] === undefined
        ? undefined
        : asNumberOrThrow(parsedHoobiizTicketInfoMap['maxQuantity']);
    const parsedHoobiizTicketInfoOptions =
      parsedHoobiizTicketInfoMap['options'] === undefined
        ? undefined
        : asArrayOrThrow(parsedHoobiizTicketInfoMap['options']).map(element => {
            const parsedElement = parseHoobiizTicketInfoOption(asMapOrThrow(element));
            return parsedElement;
          });
    const parsedHoobiizTicketInfo = {
      id: parsedHoobiizTicketInfoId,
      label: parsedHoobiizTicketInfoLabel,
      description: parsedHoobiizTicketInfoDescription,
      buyingPrice: parsedHoobiizTicketInfoBuyingPrice,
      publicPrice: parsedHoobiizTicketInfoPublicPrice,
      youpiizPrice: parsedHoobiizTicketInfoYoupiizPrice,
      fees: parsedHoobiizTicketInfoFees,
      span: parsedHoobiizTicketInfoSpan,
      visibility: parsedHoobiizTicketInfoVisibility,
      minQuantity: parsedHoobiizTicketInfoMinQuantity,
      maxQuantity: parsedHoobiizTicketInfoMaxQuantity,
      options: parsedHoobiizTicketInfoOptions,
    };
    return parsedHoobiizTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizFlexibleStockScheduleInherit {
  type: HoobiizFlexibleStockScheduleType.Inherit;
}

export function parseHoobiizFlexibleStockScheduleInherit(): Omit<
  HoobiizFlexibleStockScheduleInherit,
  'type'
> {
  return {};
}

export interface HoobiizFlexibleStockScheduleOverride {
  type: HoobiizFlexibleStockScheduleType.Override;
  schedule: HoobiizOpeningHours;
}

export function parseHoobiizFlexibleStockScheduleOverride(
  json: Record<string, unknown>
): Omit<HoobiizFlexibleStockScheduleOverride, 'type'> {
  try {
    const parsedHoobiizFlexibleStockScheduleOverrideMap = asMapOrThrow(json);
    const parsedHoobiizFlexibleStockScheduleOverrideSchedule = parseHoobiizOpeningHours(
      asMapOrThrow(parsedHoobiizFlexibleStockScheduleOverrideMap['schedule'])
    );
    const parsedHoobiizFlexibleStockScheduleOverride = {
      schedule: parsedHoobiizFlexibleStockScheduleOverrideSchedule,
    };
    return parsedHoobiizFlexibleStockScheduleOverride;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizFlexibleStockScheduleOverride: ${errorAsString(err)}`);
  }
}

export type HoobiizFlexibleStockSchedule =
  | HoobiizFlexibleStockScheduleInherit
  | HoobiizFlexibleStockScheduleOverride;

export function parseHoobiizFlexibleStockSchedule(
  json: Record<string, unknown>
): HoobiizFlexibleStockSchedule {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizFlexibleStockScheduleType.Inherit) {
      return {type, ...parseHoobiizFlexibleStockScheduleInherit()};
    } else if (type === HoobiizFlexibleStockScheduleType.Override) {
      return {type, ...parseHoobiizFlexibleStockScheduleOverride(json)};
    }
    throw new Error(`Failure to parse HoobiizFlexibleStockSchedule: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizFlexibleStockSchedule: ${errorAsString(err)}.`);
  }
}

export interface HoobiizVendorLocation {
  name: string;
  hideName?: boolean;
  address: AddressLookupResult;
  hideAddress?: boolean;
  phone: string;
  hidePhone?: boolean;
  openingHours: HoobiizOpeningHours;
  hideOpeningHours?: boolean;
}

export function parseHoobiizVendorLocation(json: Record<string, unknown>): HoobiizVendorLocation {
  try {
    const parsedHoobiizVendorLocationMap = asMapOrThrow(json);
    const parsedHoobiizVendorLocationName = asStringOrThrow(parsedHoobiizVendorLocationMap['name']);
    const parsedHoobiizVendorLocationHideName =
      parsedHoobiizVendorLocationMap['hideName'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hideName']);
    const parsedHoobiizVendorLocationAddress = parseAddressLookupResult(
      asMapOrThrow(parsedHoobiizVendorLocationMap['address'])
    );
    const parsedHoobiizVendorLocationHideAddress =
      parsedHoobiizVendorLocationMap['hideAddress'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hideAddress']);
    const parsedHoobiizVendorLocationPhone = asStringOrThrow(
      parsedHoobiizVendorLocationMap['phone']
    );
    const parsedHoobiizVendorLocationHidePhone =
      parsedHoobiizVendorLocationMap['hidePhone'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hidePhone']);
    const parsedHoobiizVendorLocationOpeningHours = parseHoobiizOpeningHours(
      asMapOrThrow(parsedHoobiizVendorLocationMap['openingHours'])
    );
    const parsedHoobiizVendorLocationHideOpeningHours =
      parsedHoobiizVendorLocationMap['hideOpeningHours'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedHoobiizVendorLocationMap['hideOpeningHours']);
    const parsedHoobiizVendorLocation = {
      name: parsedHoobiizVendorLocationName,
      hideName: parsedHoobiizVendorLocationHideName,
      address: parsedHoobiizVendorLocationAddress,
      hideAddress: parsedHoobiizVendorLocationHideAddress,
      phone: parsedHoobiizVendorLocationPhone,
      hidePhone: parsedHoobiizVendorLocationHidePhone,
      openingHours: parsedHoobiizVendorLocationOpeningHours,
      hideOpeningHours: parsedHoobiizVendorLocationHideOpeningHours,
    };
    return parsedHoobiizVendorLocation;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorLocation payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorLocation was ${JSON.stringify(json)}`
    );
  }
}

export interface AccountDatalakes {
  ids: DataLakeId[];
}

export function parseAccountDatalakes(
  json: Record<string, unknown> | undefined
): AccountDatalakes | undefined {
  if (json === undefined) {
    return undefined;
  }
  try {
    const parsedAccountDatalakesMap = asMapOrThrow(json);
    const parsedAccountDatalakesIds = asArrayOrThrow(parsedAccountDatalakesMap['ids']).map(
      element => {
        const parsedElement = asStringOrThrow<DataLakeId>(element);
        return parsedElement;
      }
    );
    const parsedAccountDatalakes = {
      ids: parsedAccountDatalakesIds,
    };
    return parsedAccountDatalakes;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse AccountDatalakes payload: ${errorAsString(
        err
      )}. Payload for AccountDatalakes was ${JSON.stringify(json)}`
    );
  }
}

export interface AccountItem {
  accountId: AccountId; // a
  createdAt: string; // b
  rootAccountAssociated: boolean; // c
  comment?: string; // d
  datalakes?: AccountDatalakes; // e
}

export interface FrontendUserInviteRedisPayload {
  email: EmailString;
  frontendName: string;
  host: string;
  isAdmin: boolean;
  authMethods: FrontendUserAuthMethodType[];
  userData?: FrontendUserDataContent;
}

export const FrontendUserInviteRedisPayloadMetadata = {
  email: {type: 'string', brand: 'EmailString'},
  frontendName: {type: 'string'},
  host: {type: 'string'},
  isAdmin: {type: 'boolean'},
  authMethods: {
    type: 'array',
    schema: {
      type: 'enum',
      enum: [
        {label: 'MagicLink', value: 'MagicLink'},
        {label: 'MfaCode', value: 'MfaCode'},
        {label: 'Password', value: 'Password'},
      ],
    },
  },
  userData: {type: 'reference', name: 'FrontendUserDataContent', optional: true},
};

export function parseFrontendUserInviteRedisPayload(
  json: Record<string, unknown>
): FrontendUserInviteRedisPayload {
  try {
    const parsedFrontendUserInviteRedisPayloadMap = asMapOrThrow(json);
    const parsedFrontendUserInviteRedisPayloadEmail = asStringOrThrow<EmailString>(
      parsedFrontendUserInviteRedisPayloadMap['email']
    );
    const parsedFrontendUserInviteRedisPayloadFrontendName = asStringOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['frontendName']
    );
    const parsedFrontendUserInviteRedisPayloadHost = asStringOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['host']
    );
    const parsedFrontendUserInviteRedisPayloadIsAdmin = asBooleanOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['isAdmin']
    );
    const parsedFrontendUserInviteRedisPayloadAuthMethods = asArrayOrThrow(
      parsedFrontendUserInviteRedisPayloadMap['authMethods']
    ).map(element => {
      const parsedElement = parseFrontendUserAuthMethodType(asStringOrThrow(element));
      return parsedElement;
    });
    const parsedFrontendUserInviteRedisPayloadUserData =
      parsedFrontendUserInviteRedisPayloadMap['userData'] === undefined
        ? undefined
        : parseFrontendUserDataContent(
            asMapOrThrow(parsedFrontendUserInviteRedisPayloadMap['userData'])
          );
    const parsedFrontendUserInviteRedisPayload = {
      email: parsedFrontendUserInviteRedisPayloadEmail,
      frontendName: parsedFrontendUserInviteRedisPayloadFrontendName,
      host: parsedFrontendUserInviteRedisPayloadHost,
      isAdmin: parsedFrontendUserInviteRedisPayloadIsAdmin,
      authMethods: parsedFrontendUserInviteRedisPayloadAuthMethods,
      userData: parsedFrontendUserInviteRedisPayloadUserData,
    };
    return parsedFrontendUserInviteRedisPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserSessionRedisPayload {
  userId: FrontendUserId;
}

export const FrontendUserSessionRedisPayloadMetadata = {
  userId: {type: 'string', brand: 'FrontendUserId'},
};

export function parseFrontendUserSessionRedisPayload(
  json: Record<string, unknown>
): FrontendUserSessionRedisPayload {
  try {
    const parsedFrontendUserSessionRedisPayloadMap = asMapOrThrow(json);
    const parsedFrontendUserSessionRedisPayloadUserId = asStringOrThrow<FrontendUserId>(
      parsedFrontendUserSessionRedisPayloadMap['userId']
    );
    const parsedFrontendUserSessionRedisPayload = {
      userId: parsedFrontendUserSessionRedisPayloadUserId,
    };
    return parsedFrontendUserSessionRedisPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendMagicLinkRedisPayload {
  userId: FrontendUserId;
}

export const FrontendMagicLinkRedisPayloadMetadata = {
  userId: {type: 'string', brand: 'FrontendUserId'},
};

export function parseFrontendMagicLinkRedisPayload(
  json: Record<string, unknown>
): FrontendMagicLinkRedisPayload {
  try {
    const parsedFrontendMagicLinkRedisPayloadMap = asMapOrThrow(json);
    const parsedFrontendMagicLinkRedisPayloadUserId = asStringOrThrow<FrontendUserId>(
      parsedFrontendMagicLinkRedisPayloadMap['userId']
    );
    const parsedFrontendMagicLinkRedisPayload = {
      userId: parsedFrontendMagicLinkRedisPayloadUserId,
    };
    return parsedFrontendMagicLinkRedisPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface CaptchaTokenRedisPayload {
  createdAt: string;
  validAt?: string;
}

export const CaptchaTokenRedisPayloadMetadata = {
  createdAt: {type: 'string'},
  validAt: {type: 'string', optional: true},
};

export function parseCaptchaTokenRedisPayload(
  json: Record<string, unknown>
): CaptchaTokenRedisPayload {
  try {
    const parsedCaptchaTokenRedisPayloadMap = asMapOrThrow(json);
    const parsedCaptchaTokenRedisPayloadCreatedAt = asStringOrThrow(
      parsedCaptchaTokenRedisPayloadMap['createdAt']
    );
    const parsedCaptchaTokenRedisPayloadValidAt =
      parsedCaptchaTokenRedisPayloadMap['validAt'] === undefined
        ? undefined
        : asStringOrThrow(parsedCaptchaTokenRedisPayloadMap['validAt']);
    const parsedCaptchaTokenRedisPayload = {
      createdAt: parsedCaptchaTokenRedisPayloadCreatedAt,
      validAt: parsedCaptchaTokenRedisPayloadValidAt,
    };
    return parsedCaptchaTokenRedisPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface RateLimitingRedisPayload {
  amount: number;
}

export const RateLimitingRedisPayloadMetadata = {amount: {type: 'number'}};

export function parseRateLimitingRedisPayload(
  json: Record<string, unknown>
): RateLimitingRedisPayload {
  try {
    const parsedRateLimitingRedisPayloadMap = asMapOrThrow(json);
    const parsedRateLimitingRedisPayloadAmount = asNumberOrThrow(
      parsedRateLimitingRedisPayloadMap['amount']
    );
    const parsedRateLimitingRedisPayload = {
      amount: parsedRateLimitingRedisPayloadAmount,
    };
    return parsedRateLimitingRedisPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserEmailVerificationRedisPayload {
  frontendName: string;
  email: EmailString;
  hash: string;
  salt: string;
  userData?: FrontendUserDataContent;
}

export const FrontendUserEmailVerificationRedisPayloadMetadata = {
  frontendName: {type: 'string'},
  email: {type: 'string', brand: 'EmailString'},
  hash: {type: 'string'},
  salt: {type: 'string'},
  userData: {type: 'reference', name: 'FrontendUserDataContent', optional: true},
};

export function parseFrontendUserEmailVerificationRedisPayload(
  json: Record<string, unknown>
): FrontendUserEmailVerificationRedisPayload {
  try {
    const parsedFrontendUserEmailVerificationRedisPayloadMap = asMapOrThrow(json);
    const parsedFrontendUserEmailVerificationRedisPayloadFrontendName = asStringOrThrow(
      parsedFrontendUserEmailVerificationRedisPayloadMap['frontendName']
    );
    const parsedFrontendUserEmailVerificationRedisPayloadEmail = asStringOrThrow<EmailString>(
      parsedFrontendUserEmailVerificationRedisPayloadMap['email']
    );
    const parsedFrontendUserEmailVerificationRedisPayloadHash = asStringOrThrow(
      parsedFrontendUserEmailVerificationRedisPayloadMap['hash']
    );
    const parsedFrontendUserEmailVerificationRedisPayloadSalt = asStringOrThrow(
      parsedFrontendUserEmailVerificationRedisPayloadMap['salt']
    );
    const parsedFrontendUserEmailVerificationRedisPayloadUserData =
      parsedFrontendUserEmailVerificationRedisPayloadMap['userData'] === undefined
        ? undefined
        : parseFrontendUserDataContent(
            asMapOrThrow(parsedFrontendUserEmailVerificationRedisPayloadMap['userData'])
          );
    const parsedFrontendUserEmailVerificationRedisPayload = {
      frontendName: parsedFrontendUserEmailVerificationRedisPayloadFrontendName,
      email: parsedFrontendUserEmailVerificationRedisPayloadEmail,
      hash: parsedFrontendUserEmailVerificationRedisPayloadHash,
      salt: parsedFrontendUserEmailVerificationRedisPayloadSalt,
      userData: parsedFrontendUserEmailVerificationRedisPayloadUserData,
    };
    return parsedFrontendUserEmailVerificationRedisPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type RedisPayload<T extends RedisValueType> = T extends RedisValueType.FrontendUserInvite
  ? FrontendUserInviteRedisPayload
  : T extends RedisValueType.FrontendUserSession
    ? FrontendUserSessionRedisPayload
    : T extends RedisValueType.FrontendMagicLink
      ? FrontendMagicLinkRedisPayload
      : T extends RedisValueType.CaptchaToken
        ? CaptchaTokenRedisPayload
        : T extends RedisValueType.RateLimiting
          ? RateLimitingRedisPayload
          : T extends RedisValueType.FrontendUserEmailVerification
            ? FrontendUserEmailVerificationRedisPayload
            : never;

const redisPayloadParsers = {
  [RedisValueType.FrontendUserInvite]: parseFrontendUserInviteRedisPayload,
  [RedisValueType.FrontendUserSession]: parseFrontendUserSessionRedisPayload,
  [RedisValueType.FrontendMagicLink]: parseFrontendMagicLinkRedisPayload,
  [RedisValueType.CaptchaToken]: parseCaptchaTokenRedisPayload,
  [RedisValueType.RateLimiting]: parseRateLimitingRedisPayload,
  [RedisValueType.FrontendUserEmailVerification]: parseFrontendUserEmailVerificationRedisPayload,
};

export function parseRedisPayload(
  type: RedisValueType,
  json: Record<string, unknown>
): RedisPayload<RedisValueType> {
  return redisPayloadParsers[type](json);
}

export const redisPayloadMetadata = {
  [RedisValueType.FrontendUserInvite]: FrontendUserInviteRedisPayloadMetadata,
  [RedisValueType.FrontendUserSession]: FrontendUserSessionRedisPayloadMetadata,
  [RedisValueType.FrontendMagicLink]: FrontendMagicLinkRedisPayloadMetadata,
  [RedisValueType.CaptchaToken]: CaptchaTokenRedisPayloadMetadata,
  [RedisValueType.RateLimiting]: RateLimitingRedisPayloadMetadata,
  [RedisValueType.FrontendUserEmailVerification]: FrontendUserEmailVerificationRedisPayloadMetadata,
};

export interface RedisItemGeneric<T extends RedisValueType> {
  redisKey: string;
  redisValueType: T;
  expiresAt: number;
  domainId: string;
  redisValue: RedisPayload<T>;
}

export type FrontendUserInviteRedisItem = RedisItemGeneric<RedisValueType.FrontendUserInvite>;
export type FrontendUserSessionRedisItem = RedisItemGeneric<RedisValueType.FrontendUserSession>;
export type FrontendMagicLinkRedisItem = RedisItemGeneric<RedisValueType.FrontendMagicLink>;
export type CaptchaTokenRedisItem = RedisItemGeneric<RedisValueType.CaptchaToken>;
export type RateLimitingRedisItem = RedisItemGeneric<RedisValueType.RateLimiting>;
export type FrontendUserEmailVerificationRedisItem =
  RedisItemGeneric<RedisValueType.FrontendUserEmailVerification>;

export type RedisItem =
  | FrontendUserInviteRedisItem
  | FrontendUserSessionRedisItem
  | FrontendMagicLinkRedisItem
  | CaptchaTokenRedisItem
  | RateLimitingRedisItem
  | FrontendUserEmailVerificationRedisItem;

export interface FrontendUserAuthMethodMagicLink {
  type: FrontendUserAuthMethodType.MagicLink;
}

export function parseFrontendUserAuthMethodMagicLink(): Omit<
  FrontendUserAuthMethodMagicLink,
  'type'
> {
  return {};
}

export interface FrontendUserAuthMethodMfaCode {
  type: FrontendUserAuthMethodType.MfaCode;
  totpSecret: string;
  keyVersion?: number;
}

export function parseFrontendUserAuthMethodMfaCode(
  json: Record<string, unknown>
): Omit<FrontendUserAuthMethodMfaCode, 'type'> {
  try {
    const parsedFrontendUserAuthMethodMfaCodeMap = asMapOrThrow(json);
    const parsedFrontendUserAuthMethodMfaCodeTotpSecret = asStringOrThrow(
      parsedFrontendUserAuthMethodMfaCodeMap['totpSecret']
    );
    const parsedFrontendUserAuthMethodMfaCodeKeyVersion =
      parsedFrontendUserAuthMethodMfaCodeMap['keyVersion'] === undefined
        ? undefined
        : asNumberOrThrow(parsedFrontendUserAuthMethodMfaCodeMap['keyVersion']);
    const parsedFrontendUserAuthMethodMfaCode = {
      totpSecret: parsedFrontendUserAuthMethodMfaCodeTotpSecret,
      keyVersion: parsedFrontendUserAuthMethodMfaCodeKeyVersion,
    };
    return parsedFrontendUserAuthMethodMfaCode;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserAuthMethodMfaCode: ${errorAsString(err)}`);
  }
}

export interface FrontendUserAuthMethodPassword {
  type: FrontendUserAuthMethodType.Password;
  hash: string;
  salt: string;
}

export function parseFrontendUserAuthMethodPassword(
  json: Record<string, unknown>
): Omit<FrontendUserAuthMethodPassword, 'type'> {
  try {
    const parsedFrontendUserAuthMethodPasswordMap = asMapOrThrow(json);
    const parsedFrontendUserAuthMethodPasswordHash = asStringOrThrow(
      parsedFrontendUserAuthMethodPasswordMap['hash']
    );
    const parsedFrontendUserAuthMethodPasswordSalt = asStringOrThrow(
      parsedFrontendUserAuthMethodPasswordMap['salt']
    );
    const parsedFrontendUserAuthMethodPassword = {
      hash: parsedFrontendUserAuthMethodPasswordHash,
      salt: parsedFrontendUserAuthMethodPasswordSalt,
    };
    return parsedFrontendUserAuthMethodPassword;
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserAuthMethodPassword: ${errorAsString(err)}`);
  }
}

export type FrontendUserAuthMethod =
  | FrontendUserAuthMethodMagicLink
  | FrontendUserAuthMethodMfaCode
  | FrontendUserAuthMethodPassword;

export function parseFrontendUserAuthMethod(json: Record<string, unknown>): FrontendUserAuthMethod {
  try {
    const type = asStringOrThrow(json.type);
    if (type === FrontendUserAuthMethodType.MagicLink) {
      return {type, ...parseFrontendUserAuthMethodMagicLink()};
    } else if (type === FrontendUserAuthMethodType.MfaCode) {
      return {type, ...parseFrontendUserAuthMethodMfaCode(json)};
    } else if (type === FrontendUserAuthMethodType.Password) {
      return {type, ...parseFrontendUserAuthMethodPassword(json)};
    }
    throw new Error(`Failure to parse FrontendUserAuthMethod: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse FrontendUserAuthMethod: ${errorAsString(err)}.`);
  }
}

export interface FrontendUserAuth {
  methods: FrontendUserAuthMethod[];
}

export function parseFrontendUserAuth(json: Record<string, unknown>): FrontendUserAuth {
  try {
    const parsedFrontendUserAuthMap = asMapOrThrow(json);
    const parsedFrontendUserAuthMethods = asArrayOrThrow(parsedFrontendUserAuthMap['methods']).map(
      element => {
        const parsedElement = parseFrontendUserAuthMethod(asMapOrThrow(element));
        return parsedElement;
      }
    );
    const parsedFrontendUserAuth = {
      methods: parsedFrontendUserAuthMethods,
    };
    return parsedFrontendUserAuth;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse FrontendUserAuth payload: ${errorAsString(
        err
      )}. Payload for FrontendUserAuth was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserData {
  content?: FrontendUserDataContent;
}

export function parseFrontendUserData(json: Record<string, unknown>): FrontendUserData {
  try {
    const parsedFrontendUserDataMap = asMapOrThrow(json);
    const parsedFrontendUserDataContent =
      parsedFrontendUserDataMap['content'] === undefined
        ? undefined
        : parseFrontendUserDataContent(asMapOrThrow(parsedFrontendUserDataMap['content']));
    const parsedFrontendUserData = {
      content: parsedFrontendUserDataContent,
    };
    return parsedFrontendUserData;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse FrontendUserData payload: ${errorAsString(
        err
      )}. Payload for FrontendUserData was ${JSON.stringify(json)}`
    );
  }
}

export interface FrontendUserItem {
  userId: FrontendUserId; // a
  frontendName: string; // b
  email: EmailString; // c
  createdAt: string; // d
  isAdmin: boolean; // e
  sessionDuration: SessionDuration; // f
  auth: FrontendUserAuth; // g
  data: FrontendUserData; // h
}

export interface LockItem {
  lockName: string; // a
  expiresAt: number; // b
  uid: string; // c
  ttl?: number; // d
}

export interface MaestroDataItem {
  serviceName: string; // a
  targetInfoPayload: string; // b
  adminUpdatePayload?: string; // c
  frozen?: boolean; // d
  slowStartOverride?: number; // e
}

export interface MonitoringItem {
  serviceName: string; // a
  breakdownStartTarget: string; // b
  ttl: number; // c
  count: number; // d
  cpuSum: number; // e
  memorySum: number; // f
  cpuCreditBalanceSum: number; // g
  diskSum: number; // h
  requestsSum: number; // i
}

export interface StreamShardItem {
  streamArn: StreamArn; // a
  shardId: ShardId; // b
  sequenceNumber: SequenceNumber; // c
}

export interface DetailedMonitoringItem {
  version: string; // a
  timeSecondsWithRandomSuffix: string; // b
  ttl: number; // c
  serviceName: ServiceName; // d
  serviceNodeId: ServiceNodeId; // e
  targetString: TargetString; // f
  timeSeconds: number; // g
  cpu: number; // h
  memory: number; // i
  disk: number; // j
  requests: number; // k
  cpuCreditBalance: number; // l
  startTime: string; // m
  drainingTime?: string; // n
  terminationNotice?: string; // o
}

export interface BuildItem {
  buildId: BuildId; // a
  commitHash: CommitHash; // b
  status: BuildStatus; // c
  initiatedAt: number; // d
  lastUpdate: number; // e
  payload?: string; // f
}

export interface AnalyticsDataItem {
  datalakeElementId: DatalakeElementItemId; // a
  uniqueUserCount: number; // b
  topCountries: string; // c
  topLocales: string; // d
  topReferers: string; // e
  from: number; // f
  to: number; // g
  range: string; // h
  pageViews: number; // i
}

export interface TaskHistory {
  statuses: {ts: number; from?: TaskStatus; to: TaskStatus}[];
}

export function parseTaskHistory(
  json: Record<string, unknown> | undefined
): TaskHistory | undefined {
  if (json === undefined) {
    return undefined;
  }
  try {
    const parsedTaskHistoryMap = asMapOrThrow(json);
    const parsedTaskHistoryStatuses = asArrayOrThrow(parsedTaskHistoryMap['statuses']).map(
      element => {
        const parsedElementMap = asMapOrThrow(element);
        const parsedElementTs = asNumberOrThrow(parsedElementMap['ts']);
        const parsedElementFrom =
          parsedElementMap['from'] === undefined
            ? undefined
            : parseTaskStatus(asNumberOrThrow(parsedElementMap['from']));
        const parsedElementTo = parseTaskStatus(asNumberOrThrow(parsedElementMap['to']));
        const parsedElement = {
          ts: parsedElementTs,
          from: parsedElementFrom,
          to: parsedElementTo,
        };
        return parsedElement;
      }
    );
    const parsedTaskHistory = {
      statuses: parsedTaskHistoryStatuses,
    };
    return parsedTaskHistory;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse TaskHistory payload: ${errorAsString(
        err
      )}. Payload for TaskHistory was ${JSON.stringify(json)}`
    );
  }
}

export interface AnalyticsDedupTaskPayload {
  datalakeElementId: DatalakeElementItemId;
  from: number;
  to: number;
}

export const AnalyticsDedupTaskPayloadMetadata = {
  datalakeElementId: {type: 'string', brand: 'DatalakeElementItemId'},
  from: {type: 'number'},
  to: {type: 'number'},
};

export function parseAnalyticsDedupTaskPayload(
  json: Record<string, unknown>
): AnalyticsDedupTaskPayload {
  try {
    const parsedAnalyticsDedupTaskPayloadMap = asMapOrThrow(json);
    const parsedAnalyticsDedupTaskPayloadDatalakeElementId = asStringOrThrow<DatalakeElementItemId>(
      parsedAnalyticsDedupTaskPayloadMap['datalakeElementId']
    );
    const parsedAnalyticsDedupTaskPayloadFrom = asNumberOrThrow(
      parsedAnalyticsDedupTaskPayloadMap['from']
    );
    const parsedAnalyticsDedupTaskPayloadTo = asNumberOrThrow(
      parsedAnalyticsDedupTaskPayloadMap['to']
    );
    const parsedAnalyticsDedupTaskPayload = {
      datalakeElementId: parsedAnalyticsDedupTaskPayloadDatalakeElementId,
      from: parsedAnalyticsDedupTaskPayloadFrom,
      to: parsedAnalyticsDedupTaskPayloadTo,
    };
    return parsedAnalyticsDedupTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface AnalyticsAggregationTaskPayload {
  datalakeElementId: DatalakeElementItemId;
  from: number;
  to: number;
}

export const AnalyticsAggregationTaskPayloadMetadata = {
  datalakeElementId: {type: 'string', brand: 'DatalakeElementItemId'},
  from: {type: 'number'},
  to: {type: 'number'},
};

export function parseAnalyticsAggregationTaskPayload(
  json: Record<string, unknown>
): AnalyticsAggregationTaskPayload {
  try {
    const parsedAnalyticsAggregationTaskPayloadMap = asMapOrThrow(json);
    const parsedAnalyticsAggregationTaskPayloadDatalakeElementId =
      asStringOrThrow<DatalakeElementItemId>(
        parsedAnalyticsAggregationTaskPayloadMap['datalakeElementId']
      );
    const parsedAnalyticsAggregationTaskPayloadFrom = asNumberOrThrow(
      parsedAnalyticsAggregationTaskPayloadMap['from']
    );
    const parsedAnalyticsAggregationTaskPayloadTo = asNumberOrThrow(
      parsedAnalyticsAggregationTaskPayloadMap['to']
    );
    const parsedAnalyticsAggregationTaskPayload = {
      datalakeElementId: parsedAnalyticsAggregationTaskPayloadDatalakeElementId,
      from: parsedAnalyticsAggregationTaskPayloadFrom,
      to: parsedAnalyticsAggregationTaskPayloadTo,
    };
    return parsedAnalyticsAggregationTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface BuildTaskPayload {
  buildId: BuildId;
  envOverride?: string;
}

export const BuildTaskPayloadMetadata = {
  buildId: {type: 'string', brand: 'BuildId'},
  envOverride: {type: 'string', optional: true},
};

export function parseBuildTaskPayload(json: Record<string, unknown>): BuildTaskPayload {
  try {
    const parsedBuildTaskPayloadMap = asMapOrThrow(json);
    const parsedBuildTaskPayloadBuildId = asStringOrThrow<BuildId>(
      parsedBuildTaskPayloadMap['buildId']
    );
    const parsedBuildTaskPayloadEnvOverride =
      parsedBuildTaskPayloadMap['envOverride'] === undefined
        ? undefined
        : asStringOrThrow(parsedBuildTaskPayloadMap['envOverride']);
    const parsedBuildTaskPayload = {
      buildId: parsedBuildTaskPayloadBuildId,
      envOverride: parsedBuildTaskPayloadEnvOverride,
    };
    return parsedBuildTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface DataLakeTaskPayload {
  elementId: DatalakeElementItemId;
  partitionType: DatalakePartitionType;
  start?: number;
}

export const DataLakeTaskPayloadMetadata = {
  elementId: {type: 'string', brand: 'DatalakeElementItemId'},
  partitionType: {
    type: 'enum',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
  },
  start: {type: 'number', optional: true},
};

export function parseDataLakeTaskPayload(json: Record<string, unknown>): DataLakeTaskPayload {
  try {
    const parsedDataLakeTaskPayloadMap = asMapOrThrow(json);
    const parsedDataLakeTaskPayloadElementId = asStringOrThrow<DatalakeElementItemId>(
      parsedDataLakeTaskPayloadMap['elementId']
    );
    const parsedDataLakeTaskPayloadPartitionType = parseDatalakePartitionType(
      asNumberOrThrow(parsedDataLakeTaskPayloadMap['partitionType'])
    );
    const parsedDataLakeTaskPayloadStart =
      parsedDataLakeTaskPayloadMap['start'] === undefined
        ? undefined
        : asNumberOrThrow(parsedDataLakeTaskPayloadMap['start']);
    const parsedDataLakeTaskPayload = {
      elementId: parsedDataLakeTaskPayloadElementId,
      partitionType: parsedDataLakeTaskPayloadPartitionType,
      start: parsedDataLakeTaskPayloadStart,
    };
    return parsedDataLakeTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface QontoDataSourceTaskPayload {
  datalakeId: DataLakeId;
  dataSourceId: DatalakeElementItemId;
}

export const QontoDataSourceTaskPayloadMetadata = {
  datalakeId: {type: 'string', brand: 'DataLakeId'},
  dataSourceId: {type: 'string', brand: 'DatalakeElementItemId'},
};

export function parseQontoDataSourceTaskPayload(
  json: Record<string, unknown>
): QontoDataSourceTaskPayload {
  try {
    const parsedQontoDataSourceTaskPayloadMap = asMapOrThrow(json);
    const parsedQontoDataSourceTaskPayloadDatalakeId = asStringOrThrow<DataLakeId>(
      parsedQontoDataSourceTaskPayloadMap['datalakeId']
    );
    const parsedQontoDataSourceTaskPayloadDataSourceId = asStringOrThrow<DatalakeElementItemId>(
      parsedQontoDataSourceTaskPayloadMap['dataSourceId']
    );
    const parsedQontoDataSourceTaskPayload = {
      datalakeId: parsedQontoDataSourceTaskPayloadDatalakeId,
      dataSourceId: parsedQontoDataSourceTaskPayloadDataSourceId,
    };
    return parsedQontoDataSourceTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ReportingTaskPayload {
  reportingId: ReportingId;
  breakdown: ReportingBreakdown;
  date: string;
}

export const ReportingTaskPayloadMetadata = {
  reportingId: {type: 'string', brand: 'ReportingId'},
  breakdown: {
    type: 'enum',
    enum: [
      {label: 'Minutely', value: 10},
      {label: 'Hourly', value: 20},
    ],
  },
  date: {type: 'string'},
};

export function parseReportingTaskPayload(json: Record<string, unknown>): ReportingTaskPayload {
  try {
    const parsedReportingTaskPayloadMap = asMapOrThrow(json);
    const parsedReportingTaskPayloadReportingId = asStringOrThrow<ReportingId>(
      parsedReportingTaskPayloadMap['reportingId']
    );
    const parsedReportingTaskPayloadBreakdown = parseReportingBreakdown(
      asNumberOrThrow(parsedReportingTaskPayloadMap['breakdown'])
    );
    const parsedReportingTaskPayloadDate = asStringOrThrow(parsedReportingTaskPayloadMap['date']);
    const parsedReportingTaskPayload = {
      reportingId: parsedReportingTaskPayloadReportingId,
      breakdown: parsedReportingTaskPayloadBreakdown,
      date: parsedReportingTaskPayloadDate,
    };
    return parsedReportingTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTicketsTaskPayload {}

export const HoobiizTicketsTaskPayloadMetadata = {};

export function parseHoobiizTicketsTaskPayload(
  json: Record<string, unknown>
): HoobiizTicketsTaskPayload {
  try {
    const parsedHoobiizTicketsTaskPayload = {};
    return parsedHoobiizTicketsTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizMediaTaskPayload {
  bucket: string;
  objectKey: string;
}

export const HoobiizMediaTaskPayloadMetadata = {
  bucket: {type: 'string'},
  objectKey: {type: 'string'},
};

export function parseHoobiizMediaTaskPayload(
  json: Record<string, unknown>
): HoobiizMediaTaskPayload {
  try {
    const parsedHoobiizMediaTaskPayloadMap = asMapOrThrow(json);
    const parsedHoobiizMediaTaskPayloadBucket = asStringOrThrow(
      parsedHoobiizMediaTaskPayloadMap['bucket']
    );
    const parsedHoobiizMediaTaskPayloadObjectKey = asStringOrThrow(
      parsedHoobiizMediaTaskPayloadMap['objectKey']
    );
    const parsedHoobiizMediaTaskPayload = {
      bucket: parsedHoobiizMediaTaskPayloadBucket,
      objectKey: parsedHoobiizMediaTaskPayloadObjectKey,
    };
    return parsedHoobiizMediaTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ExpertTicketTaskPayload {}

export const ExpertTicketTaskPayloadMetadata = {};

export function parseExpertTicketTaskPayload(
  json: Record<string, unknown>
): ExpertTicketTaskPayload {
  try {
    const parsedExpertTicketTaskPayload = {};
    return parsedExpertTicketTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizPdfTaskPayload {
  orderId: HoobiizOrderItemId;
}

export const HoobiizPdfTaskPayloadMetadata = {
  orderId: {type: 'string', brand: 'HoobiizOrderItemId'},
};

export function parseHoobiizPdfTaskPayload(json: Record<string, unknown>): HoobiizPdfTaskPayload {
  try {
    const parsedHoobiizPdfTaskPayloadMap = asMapOrThrow(json);
    const parsedHoobiizPdfTaskPayloadOrderId = asStringOrThrow<HoobiizOrderItemId>(
      parsedHoobiizPdfTaskPayloadMap['orderId']
    );
    const parsedHoobiizPdfTaskPayload = {
      orderId: parsedHoobiizPdfTaskPayloadOrderId,
    };
    return parsedHoobiizPdfTaskPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type TaskPayload<T extends TaskType> = T extends TaskType.AnalyticsDedup
  ? AnalyticsDedupTaskPayload
  : T extends TaskType.AnalyticsAggregation
    ? AnalyticsAggregationTaskPayload
    : T extends TaskType.Build
      ? BuildTaskPayload
      : T extends TaskType.DataLake
        ? DataLakeTaskPayload
        : T extends TaskType.QontoDataSource
          ? QontoDataSourceTaskPayload
          : T extends TaskType.Reporting
            ? ReportingTaskPayload
            : T extends TaskType.HoobiizTickets
              ? HoobiizTicketsTaskPayload
              : T extends TaskType.HoobiizMedia
                ? HoobiizMediaTaskPayload
                : T extends TaskType.ExpertTicket
                  ? ExpertTicketTaskPayload
                  : T extends TaskType.HoobiizPdf
                    ? HoobiizPdfTaskPayload
                    : never;

const taskPayloadParsers = {
  [TaskType.AnalyticsDedup]: parseAnalyticsDedupTaskPayload,
  [TaskType.AnalyticsAggregation]: parseAnalyticsAggregationTaskPayload,
  [TaskType.Build]: parseBuildTaskPayload,
  [TaskType.DataLake]: parseDataLakeTaskPayload,
  [TaskType.QontoDataSource]: parseQontoDataSourceTaskPayload,
  [TaskType.Reporting]: parseReportingTaskPayload,
  [TaskType.HoobiizTickets]: parseHoobiizTicketsTaskPayload,
  [TaskType.HoobiizMedia]: parseHoobiizMediaTaskPayload,
  [TaskType.ExpertTicket]: parseExpertTicketTaskPayload,
  [TaskType.HoobiizPdf]: parseHoobiizPdfTaskPayload,
};

export function parseTaskPayload(
  type: TaskType,
  json: Record<string, unknown>
): TaskPayload<TaskType> {
  return taskPayloadParsers[type](json);
}

export const taskPayloadMetadata = {
  [TaskType.AnalyticsDedup]: AnalyticsDedupTaskPayloadMetadata,
  [TaskType.AnalyticsAggregation]: AnalyticsAggregationTaskPayloadMetadata,
  [TaskType.Build]: BuildTaskPayloadMetadata,
  [TaskType.DataLake]: DataLakeTaskPayloadMetadata,
  [TaskType.QontoDataSource]: QontoDataSourceTaskPayloadMetadata,
  [TaskType.Reporting]: ReportingTaskPayloadMetadata,
  [TaskType.HoobiizTickets]: HoobiizTicketsTaskPayloadMetadata,
  [TaskType.HoobiizMedia]: HoobiizMediaTaskPayloadMetadata,
  [TaskType.ExpertTicket]: ExpertTicketTaskPayloadMetadata,
  [TaskType.HoobiizPdf]: HoobiizPdfTaskPayloadMetadata,
};

export interface TaskItemGeneric<T extends TaskType> {
  taskId: TaskId;
  domainId: string;
  status: TaskStatus;
  creationTime: number;
  startTime?: number;
  endTime?: number;
  heartBeat?: number;
  taskType: T;
  doNotProcessBefore: number;
  version: number;
  hasChild: boolean;
  reason?: string;
  instanceInfo?: string;
  payload: TaskPayload<T>;
  instanceRequestId?: InstanceRequestId;
  history?: TaskHistory;
  results?: string;
}

export type AnalyticsDedupTaskItem = TaskItemGeneric<TaskType.AnalyticsDedup>;
export type AnalyticsAggregationTaskItem = TaskItemGeneric<TaskType.AnalyticsAggregation>;
export type BuildTaskItem = TaskItemGeneric<TaskType.Build>;
export type DataLakeTaskItem = TaskItemGeneric<TaskType.DataLake>;
export type QontoDataSourceTaskItem = TaskItemGeneric<TaskType.QontoDataSource>;
export type ReportingTaskItem = TaskItemGeneric<TaskType.Reporting>;
export type HoobiizTicketsTaskItem = TaskItemGeneric<TaskType.HoobiizTickets>;
export type HoobiizMediaTaskItem = TaskItemGeneric<TaskType.HoobiizMedia>;
export type ExpertTicketTaskItem = TaskItemGeneric<TaskType.ExpertTicket>;
export type HoobiizPdfTaskItem = TaskItemGeneric<TaskType.HoobiizPdf>;

export type TaskItem =
  | AnalyticsDedupTaskItem
  | AnalyticsAggregationTaskItem
  | BuildTaskItem
  | DataLakeTaskItem
  | QontoDataSourceTaskItem
  | ReportingTaskItem
  | HoobiizTicketsTaskItem
  | HoobiizMediaTaskItem
  | ExpertTicketTaskItem
  | HoobiizPdfTaskItem;

export interface TaskPolicyItem {
  taskType: TaskType; // a
  maxParallelTasks: number; // b
  heartBeatPeriodMs: number; // c
  maxHeartBeatAgeMs: number; // d
  maxInFlightTimeMs: number; // e
  minVcpus: number; // f
  minMemory: number; // g
  commitHash: CommitHash; // h
  maxScheduledTimeMs: number; // i
  maxRescheduleCount: number; // j
  autoSleepPeriodMs: number; // k
  autoSleepDurationMs: number; // l
  minTimeBeforeTaskCheckMs: number; // m
  burstable?: boolean; // n
  minReleaseYear?: number; // o
  architecture?: string; // p
}

export interface ParameterItem {
  parameterKey: ParameterKey; // a
  stringValue?: string; // b
  numberValue?: number; // c
  booleanValue?: boolean; // d
  isSecret?: boolean; // e
}

export interface InstanceTypeInstanceRequestPayload {
  serviceName: string;
  instanceTypeName: InstanceTypeName;
  subnetIds: string[];
  commitHash: CommitHash;
  maxRunningDurationMs?: number;
  envVars: {BUILD_ID?: BuildId; TASK_ID?: TaskId; TASK_VERSION?: string};
}

export const InstanceTypeInstanceRequestPayloadMetadata = {
  serviceName: {type: 'string'},
  instanceTypeName: {type: 'string', brand: 'InstanceTypeName'},
  subnetIds: {type: 'array', schema: {type: 'string'}},
  commitHash: {type: 'string', brand: 'CommitHash'},
  maxRunningDurationMs: {type: 'number', optional: true},
  envVars: {
    type: 'object',
    schema: {
      BUILD_ID: {type: 'string', brand: 'BuildId', optional: true},
      TASK_ID: {type: 'string', brand: 'TaskId', optional: true},
      TASK_VERSION: {type: 'string', optional: true},
    },
  },
};

export function parseInstanceTypeInstanceRequestPayload(
  json: Record<string, unknown>
): InstanceTypeInstanceRequestPayload {
  try {
    const parsedInstanceTypeInstanceRequestPayloadMap = asMapOrThrow(json);
    const parsedInstanceTypeInstanceRequestPayloadServiceName = asStringOrThrow(
      parsedInstanceTypeInstanceRequestPayloadMap['serviceName']
    );
    const parsedInstanceTypeInstanceRequestPayloadInstanceTypeName =
      asStringOrThrow<InstanceTypeName>(
        parsedInstanceTypeInstanceRequestPayloadMap['instanceTypeName']
      );
    const parsedInstanceTypeInstanceRequestPayloadSubnetIds = asArrayOrThrow(
      parsedInstanceTypeInstanceRequestPayloadMap['subnetIds']
    ).map(element => {
      const parsedElement = asStringOrThrow(element);
      return parsedElement;
    });
    const parsedInstanceTypeInstanceRequestPayloadCommitHash = asStringOrThrow<CommitHash>(
      parsedInstanceTypeInstanceRequestPayloadMap['commitHash']
    );
    const parsedInstanceTypeInstanceRequestPayloadMaxRunningDurationMs =
      parsedInstanceTypeInstanceRequestPayloadMap['maxRunningDurationMs'] === undefined
        ? undefined
        : asNumberOrThrow(parsedInstanceTypeInstanceRequestPayloadMap['maxRunningDurationMs']);
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsMap = asMapOrThrow(
      parsedInstanceTypeInstanceRequestPayloadMap['envVars']
    );
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsBUILD_ID =
      parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['BUILD_ID'] === undefined
        ? undefined
        : asStringOrThrow<BuildId>(parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['BUILD_ID']);
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_ID =
      parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_ID'] === undefined
        ? undefined
        : asStringOrThrow<TaskId>(parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_ID']);
    const parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_VERSION =
      parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_VERSION'] === undefined
        ? undefined
        : asStringOrThrow(parsedInstanceTypeInstanceRequestPayloadEnvVarsMap['TASK_VERSION']);
    const parsedInstanceTypeInstanceRequestPayloadEnvVars = {
      BUILD_ID: parsedInstanceTypeInstanceRequestPayloadEnvVarsBUILD_ID,
      TASK_ID: parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_ID,
      TASK_VERSION: parsedInstanceTypeInstanceRequestPayloadEnvVarsTASK_VERSION,
    };
    const parsedInstanceTypeInstanceRequestPayload = {
      serviceName: parsedInstanceTypeInstanceRequestPayloadServiceName,
      instanceTypeName: parsedInstanceTypeInstanceRequestPayloadInstanceTypeName,
      subnetIds: parsedInstanceTypeInstanceRequestPayloadSubnetIds,
      commitHash: parsedInstanceTypeInstanceRequestPayloadCommitHash,
      maxRunningDurationMs: parsedInstanceTypeInstanceRequestPayloadMaxRunningDurationMs,
      envVars: parsedInstanceTypeInstanceRequestPayloadEnvVars,
    };
    return parsedInstanceTypeInstanceRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface MinVcpusMemoryInstanceRequestPayload {
  serviceName: string;
  minVcpus: number;
  minMemory: number;
  burstable?: boolean;
  minReleaseYear?: number;
  architecture?: string;
  subnetIds: string[];
  commitHash: CommitHash;
  maxRunningDurationMs?: number;
  envVars: {BUILD_ID?: BuildId; TASK_ID?: TaskId; TASK_VERSION?: string};
}

export const MinVcpusMemoryInstanceRequestPayloadMetadata = {
  serviceName: {type: 'string'},
  minVcpus: {type: 'number'},
  minMemory: {type: 'number'},
  burstable: {type: 'boolean', optional: true},
  minReleaseYear: {type: 'number', optional: true},
  architecture: {type: 'string', optional: true},
  subnetIds: {type: 'array', schema: {type: 'string'}},
  commitHash: {type: 'string', brand: 'CommitHash'},
  maxRunningDurationMs: {type: 'number', optional: true},
  envVars: {
    type: 'object',
    schema: {
      BUILD_ID: {type: 'string', brand: 'BuildId', optional: true},
      TASK_ID: {type: 'string', brand: 'TaskId', optional: true},
      TASK_VERSION: {type: 'string', optional: true},
    },
  },
};

export function parseMinVcpusMemoryInstanceRequestPayload(
  json: Record<string, unknown>
): MinVcpusMemoryInstanceRequestPayload {
  try {
    const parsedMinVcpusMemoryInstanceRequestPayloadMap = asMapOrThrow(json);
    const parsedMinVcpusMemoryInstanceRequestPayloadServiceName = asStringOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['serviceName']
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadMinVcpus = asNumberOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['minVcpus']
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadMinMemory = asNumberOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['minMemory']
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadBurstable =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['burstable'] === undefined
        ? undefined
        : asBooleanOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['burstable']);
    const parsedMinVcpusMemoryInstanceRequestPayloadMinReleaseYear =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['minReleaseYear'] === undefined
        ? undefined
        : asNumberOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['minReleaseYear']);
    const parsedMinVcpusMemoryInstanceRequestPayloadArchitecture =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['architecture'] === undefined
        ? undefined
        : asStringOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['architecture']);
    const parsedMinVcpusMemoryInstanceRequestPayloadSubnetIds = asArrayOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['subnetIds']
    ).map(element => {
      const parsedElement = asStringOrThrow(element);
      return parsedElement;
    });
    const parsedMinVcpusMemoryInstanceRequestPayloadCommitHash = asStringOrThrow<CommitHash>(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['commitHash']
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadMaxRunningDurationMs =
      parsedMinVcpusMemoryInstanceRequestPayloadMap['maxRunningDurationMs'] === undefined
        ? undefined
        : asNumberOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadMap['maxRunningDurationMs']);
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap = asMapOrThrow(
      parsedMinVcpusMemoryInstanceRequestPayloadMap['envVars']
    );
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsBUILD_ID =
      parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['BUILD_ID'] === undefined
        ? undefined
        : asStringOrThrow<BuildId>(
            parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['BUILD_ID']
          );
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_ID =
      parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_ID'] === undefined
        ? undefined
        : asStringOrThrow<TaskId>(parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_ID']);
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_VERSION =
      parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_VERSION'] === undefined
        ? undefined
        : asStringOrThrow(parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsMap['TASK_VERSION']);
    const parsedMinVcpusMemoryInstanceRequestPayloadEnvVars = {
      BUILD_ID: parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsBUILD_ID,
      TASK_ID: parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_ID,
      TASK_VERSION: parsedMinVcpusMemoryInstanceRequestPayloadEnvVarsTASK_VERSION,
    };
    const parsedMinVcpusMemoryInstanceRequestPayload = {
      serviceName: parsedMinVcpusMemoryInstanceRequestPayloadServiceName,
      minVcpus: parsedMinVcpusMemoryInstanceRequestPayloadMinVcpus,
      minMemory: parsedMinVcpusMemoryInstanceRequestPayloadMinMemory,
      burstable: parsedMinVcpusMemoryInstanceRequestPayloadBurstable,
      minReleaseYear: parsedMinVcpusMemoryInstanceRequestPayloadMinReleaseYear,
      architecture: parsedMinVcpusMemoryInstanceRequestPayloadArchitecture,
      subnetIds: parsedMinVcpusMemoryInstanceRequestPayloadSubnetIds,
      commitHash: parsedMinVcpusMemoryInstanceRequestPayloadCommitHash,
      maxRunningDurationMs: parsedMinVcpusMemoryInstanceRequestPayloadMaxRunningDurationMs,
      envVars: parsedMinVcpusMemoryInstanceRequestPayloadEnvVars,
    };
    return parsedMinVcpusMemoryInstanceRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface TerminationInstanceRequestPayload {
  instanceId: string;
}

export const TerminationInstanceRequestPayloadMetadata = {instanceId: {type: 'string'}};

export function parseTerminationInstanceRequestPayload(
  json: Record<string, unknown>
): TerminationInstanceRequestPayload {
  try {
    const parsedTerminationInstanceRequestPayloadMap = asMapOrThrow(json);
    const parsedTerminationInstanceRequestPayloadInstanceId = asStringOrThrow(
      parsedTerminationInstanceRequestPayloadMap['instanceId']
    );
    const parsedTerminationInstanceRequestPayload = {
      instanceId: parsedTerminationInstanceRequestPayloadInstanceId,
    };
    return parsedTerminationInstanceRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type InstanceRequestPayload<T extends InstanceRequestType> =
  T extends InstanceRequestType.InstanceType
    ? InstanceTypeInstanceRequestPayload
    : T extends InstanceRequestType.MinVcpusMemory
      ? MinVcpusMemoryInstanceRequestPayload
      : T extends InstanceRequestType.Termination
        ? TerminationInstanceRequestPayload
        : never;

const instanceRequestPayloadParsers = {
  [InstanceRequestType.InstanceType]: parseInstanceTypeInstanceRequestPayload,
  [InstanceRequestType.MinVcpusMemory]: parseMinVcpusMemoryInstanceRequestPayload,
  [InstanceRequestType.Termination]: parseTerminationInstanceRequestPayload,
};

export function parseInstanceRequestPayload(
  type: InstanceRequestType,
  json: Record<string, unknown>
): InstanceRequestPayload<InstanceRequestType> {
  return instanceRequestPayloadParsers[type](json);
}

export const instanceRequestPayloadMetadata = {
  [InstanceRequestType.InstanceType]: InstanceTypeInstanceRequestPayloadMetadata,
  [InstanceRequestType.MinVcpusMemory]: MinVcpusMemoryInstanceRequestPayloadMetadata,
  [InstanceRequestType.Termination]: TerminationInstanceRequestPayloadMetadata,
};

export interface InstanceRequestItemGeneric<T extends InstanceRequestType> {
  instanceRequestId: InstanceRequestId;
  creationTime: number;
  instanceRequestType: T;
  payload: InstanceRequestPayload<T>;
}

export type InstanceTypeInstanceRequestItem =
  InstanceRequestItemGeneric<InstanceRequestType.InstanceType>;
export type MinVcpusMemoryInstanceRequestItem =
  InstanceRequestItemGeneric<InstanceRequestType.MinVcpusMemory>;
export type TerminationInstanceRequestItem =
  InstanceRequestItemGeneric<InstanceRequestType.Termination>;

export type InstanceRequestItem =
  | InstanceTypeInstanceRequestItem
  | MinVcpusMemoryInstanceRequestItem
  | TerminationInstanceRequestItem;

export interface ReportWithFailuresItem {
  reportingId: ReportingId; // a
  taskId: TaskId; // b
  breakdown: ReportingBreakdown; // c
  date: string; // d
  alertsCount: number; // e
  errorsCount: number; // f
  totalCount: number; // g
  dateMonth: string; // h
}

export interface DatalakeElementOutputs {
  columns: {name: string; type: DatalakeOutputColumnType}[];
}

export function parseDatalakeElementOutputs(json: Record<string, unknown>): DatalakeElementOutputs {
  try {
    const parsedDatalakeElementOutputsMap = asMapOrThrow(json);
    const parsedDatalakeElementOutputsColumns = asArrayOrThrow(
      parsedDatalakeElementOutputsMap['columns']
    ).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementName = asStringOrThrow(parsedElementMap['name']);
      const parsedElementType = parseDatalakeOutputColumnType(
        asNumberOrThrow(parsedElementMap['type'])
      );
      const parsedElement = {
        name: parsedElementName,
        type: parsedElementType,
      };
      return parsedElement;
    });
    const parsedDatalakeElementOutputs = {
      columns: parsedDatalakeElementOutputsColumns,
    };
    return parsedDatalakeElementOutputs;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse DatalakeElementOutputs payload: ${errorAsString(
        err
      )}. Payload for DatalakeElementOutputs was ${JSON.stringify(json)}`
    );
  }
}

export interface DatalakeElementLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

export function parseDatalakeElementLayout(json: Record<string, unknown>): DatalakeElementLayout {
  try {
    const parsedDatalakeElementLayoutMap = asMapOrThrow(json);
    const parsedDatalakeElementLayoutX = asNumberOrThrow(parsedDatalakeElementLayoutMap['x']);
    const parsedDatalakeElementLayoutY = asNumberOrThrow(parsedDatalakeElementLayoutMap['y']);
    const parsedDatalakeElementLayoutWidth = asNumberOrThrow(
      parsedDatalakeElementLayoutMap['width']
    );
    const parsedDatalakeElementLayoutHeight = asNumberOrThrow(
      parsedDatalakeElementLayoutMap['height']
    );
    const parsedDatalakeElementLayout = {
      x: parsedDatalakeElementLayoutX,
      y: parsedDatalakeElementLayoutY,
      width: parsedDatalakeElementLayoutWidth,
      height: parsedDatalakeElementLayoutHeight,
    };
    return parsedDatalakeElementLayout;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse DatalakeElementLayout payload: ${errorAsString(
        err
      )}. Payload for DatalakeElementLayout was ${JSON.stringify(json)}`
    );
  }
}

export interface WebEventsDataSourceDatalakeElementPayload {
  group: DatalakeElementTypeGroup.DataSource;
  partitionType: DatalakePartitionType.Hourly;
  domainName: DomainName;
  domainNames?: DomainName[];
  analyticsBufferAddresses: {ip: string; port: number}[];
}

export const WebEventsDataSourceDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'DataSource',
  },
  partitionType: {
    type: 'enum-constant',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
    enumValue: 'Hourly',
  },
  domainName: {type: 'string', brand: 'DomainName'},
  domainNames: {type: 'array', optional: true, schema: {type: 'string', brand: 'DomainName'}},
  analyticsBufferAddresses: {
    type: 'array',
    schema: {type: 'object', schema: {ip: {type: 'string'}, port: {type: 'number'}}},
  },
};

export function parseWebEventsDataSourceDatalakeElementPayload(
  json: Record<string, unknown>
): WebEventsDataSourceDatalakeElementPayload {
  try {
    const parsedWebEventsDataSourceDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedWebEventsDataSourceDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.DataSource as const
    );
    const parsedWebEventsDataSourceDatalakeElementPayloadPartitionType = asConstantOrThrow(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['partitionType'],
      DatalakePartitionType.Hourly as const
    );
    const parsedWebEventsDataSourceDatalakeElementPayloadDomainName = asStringOrThrow<DomainName>(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['domainName']
    );
    const parsedWebEventsDataSourceDatalakeElementPayloadDomainNames =
      parsedWebEventsDataSourceDatalakeElementPayloadMap['domainNames'] === undefined
        ? undefined
        : asArrayOrThrow(parsedWebEventsDataSourceDatalakeElementPayloadMap['domainNames']).map(
            element => {
              const parsedElement = asStringOrThrow<DomainName>(element);
              return parsedElement;
            }
          );
    const parsedWebEventsDataSourceDatalakeElementPayloadAnalyticsBufferAddresses = asArrayOrThrow(
      parsedWebEventsDataSourceDatalakeElementPayloadMap['analyticsBufferAddresses']
    ).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementIp = asStringOrThrow(parsedElementMap['ip']);
      const parsedElementPort = asNumberOrThrow(parsedElementMap['port']);
      const parsedElement = {
        ip: parsedElementIp,
        port: parsedElementPort,
      };
      return parsedElement;
    });
    const parsedWebEventsDataSourceDatalakeElementPayload = {
      group: parsedWebEventsDataSourceDatalakeElementPayloadGroup,
      partitionType: parsedWebEventsDataSourceDatalakeElementPayloadPartitionType,
      domainName: parsedWebEventsDataSourceDatalakeElementPayloadDomainName,
      domainNames: parsedWebEventsDataSourceDatalakeElementPayloadDomainNames,
      analyticsBufferAddresses:
        parsedWebEventsDataSourceDatalakeElementPayloadAnalyticsBufferAddresses,
    };
    return parsedWebEventsDataSourceDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface FileDropDataSourceDatalakeElementPayload {
  group: DatalakeElementTypeGroup.DataSource;
  partitionType: DatalakePartitionType.Daily;
}

export const FileDropDataSourceDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'DataSource',
  },
  partitionType: {
    type: 'enum-constant',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
    enumValue: 'Daily',
  },
};

export function parseFileDropDataSourceDatalakeElementPayload(
  json: Record<string, unknown>
): FileDropDataSourceDatalakeElementPayload {
  try {
    const parsedFileDropDataSourceDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedFileDropDataSourceDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedFileDropDataSourceDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.DataSource as const
    );
    const parsedFileDropDataSourceDatalakeElementPayloadPartitionType = asConstantOrThrow(
      parsedFileDropDataSourceDatalakeElementPayloadMap['partitionType'],
      DatalakePartitionType.Daily as const
    );
    const parsedFileDropDataSourceDatalakeElementPayload = {
      group: parsedFileDropDataSourceDatalakeElementPayloadGroup,
      partitionType: parsedFileDropDataSourceDatalakeElementPayloadPartitionType,
    };
    return parsedFileDropDataSourceDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface QontoDataSourceDatalakeElementPayload {
  group: DatalakeElementTypeGroup.DataSource;
  partitionType: DatalakePartitionType.None;
  iban: string;
  login: string;
  secretKey: string;
}

export const QontoDataSourceDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'DataSource',
  },
  partitionType: {
    type: 'enum-constant',
    enum: [
      {label: 'None', value: 10},
      {label: 'Hourly', value: 20},
      {label: 'Daily', value: 30},
    ],
    enumValue: 'None',
  },
  iban: {type: 'string'},
  login: {type: 'string'},
  secretKey: {type: 'string'},
};

export function parseQontoDataSourceDatalakeElementPayload(
  json: Record<string, unknown>
): QontoDataSourceDatalakeElementPayload {
  try {
    const parsedQontoDataSourceDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedQontoDataSourceDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.DataSource as const
    );
    const parsedQontoDataSourceDatalakeElementPayloadPartitionType = asConstantOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['partitionType'],
      DatalakePartitionType.None as const
    );
    const parsedQontoDataSourceDatalakeElementPayloadIban = asStringOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['iban']
    );
    const parsedQontoDataSourceDatalakeElementPayloadLogin = asStringOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['login']
    );
    const parsedQontoDataSourceDatalakeElementPayloadSecretKey = asStringOrThrow(
      parsedQontoDataSourceDatalakeElementPayloadMap['secretKey']
    );
    const parsedQontoDataSourceDatalakeElementPayload = {
      group: parsedQontoDataSourceDatalakeElementPayloadGroup,
      partitionType: parsedQontoDataSourceDatalakeElementPayloadPartitionType,
      iban: parsedQontoDataSourceDatalakeElementPayloadIban,
      login: parsedQontoDataSourceDatalakeElementPayloadLogin,
      secretKey: parsedQontoDataSourceDatalakeElementPayloadSecretKey,
    };
    return parsedQontoDataSourceDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface IpGeolocTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  sourceColumn: number;
}

export const IpGeolocTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number'},
};

export function parseIpGeolocTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): IpGeolocTransformationDatalakeElementPayload {
  try {
    const parsedIpGeolocTransformationDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedIpGeolocTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedIpGeolocTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const
    );
    const parsedIpGeolocTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedIpGeolocTransformationDatalakeElementPayloadMap['source']
      );
    const parsedIpGeolocTransformationDatalakeElementPayloadSourceColumn = asNumberOrThrow(
      parsedIpGeolocTransformationDatalakeElementPayloadMap['sourceColumn']
    );
    const parsedIpGeolocTransformationDatalakeElementPayload = {
      group: parsedIpGeolocTransformationDatalakeElementPayloadGroup,
      source: parsedIpGeolocTransformationDatalakeElementPayloadSource,
      sourceColumn: parsedIpGeolocTransformationDatalakeElementPayloadSourceColumn,
    };
    return parsedIpGeolocTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface CalculationsTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  firstColumnIndex: number;
  secondColumnIndex: number;
  operator: DatalakeCalculationOperator;
  outputName: string;
}

export const CalculationsTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  firstColumnIndex: {type: 'number'},
  secondColumnIndex: {type: 'number'},
  operator: {type: 'enum', enum: [{label: 'Multiply', value: 10}]},
  outputName: {type: 'string'},
};

export function parseCalculationsTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): CalculationsTransformationDatalakeElementPayload {
  try {
    const parsedCalculationsTransformationDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedCalculationsTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const
    );
    const parsedCalculationsTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedCalculationsTransformationDatalakeElementPayloadMap['source']
      );
    const parsedCalculationsTransformationDatalakeElementPayloadFirstColumnIndex = asNumberOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['firstColumnIndex']
    );
    const parsedCalculationsTransformationDatalakeElementPayloadSecondColumnIndex = asNumberOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['secondColumnIndex']
    );
    const parsedCalculationsTransformationDatalakeElementPayloadOperator =
      parseDatalakeCalculationOperator(
        asNumberOrThrow(parsedCalculationsTransformationDatalakeElementPayloadMap['operator'])
      );
    const parsedCalculationsTransformationDatalakeElementPayloadOutputName = asStringOrThrow(
      parsedCalculationsTransformationDatalakeElementPayloadMap['outputName']
    );
    const parsedCalculationsTransformationDatalakeElementPayload = {
      group: parsedCalculationsTransformationDatalakeElementPayloadGroup,
      source: parsedCalculationsTransformationDatalakeElementPayloadSource,
      firstColumnIndex: parsedCalculationsTransformationDatalakeElementPayloadFirstColumnIndex,
      secondColumnIndex: parsedCalculationsTransformationDatalakeElementPayloadSecondColumnIndex,
      operator: parsedCalculationsTransformationDatalakeElementPayloadOperator,
      outputName: parsedCalculationsTransformationDatalakeElementPayloadOutputName,
    };
    return parsedCalculationsTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ExtractStringTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  sourceColumn: number;
  startsWith: string;
  endsWith: string;
  outputName: string;
}

export const ExtractStringTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number'},
  startsWith: {type: 'string'},
  endsWith: {type: 'string'},
  outputName: {type: 'string'},
};

export function parseExtractStringTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): ExtractStringTransformationDatalakeElementPayload {
  try {
    const parsedExtractStringTransformationDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedExtractStringTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const
    );
    const parsedExtractStringTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedExtractStringTransformationDatalakeElementPayloadMap['source']
      );
    const parsedExtractStringTransformationDatalakeElementPayloadSourceColumn = asNumberOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['sourceColumn']
    );
    const parsedExtractStringTransformationDatalakeElementPayloadStartsWith = asStringOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['startsWith']
    );
    const parsedExtractStringTransformationDatalakeElementPayloadEndsWith = asStringOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['endsWith']
    );
    const parsedExtractStringTransformationDatalakeElementPayloadOutputName = asStringOrThrow(
      parsedExtractStringTransformationDatalakeElementPayloadMap['outputName']
    );
    const parsedExtractStringTransformationDatalakeElementPayload = {
      group: parsedExtractStringTransformationDatalakeElementPayloadGroup,
      source: parsedExtractStringTransformationDatalakeElementPayloadSource,
      sourceColumn: parsedExtractStringTransformationDatalakeElementPayloadSourceColumn,
      startsWith: parsedExtractStringTransformationDatalakeElementPayloadStartsWith,
      endsWith: parsedExtractStringTransformationDatalakeElementPayloadEndsWith,
      outputName: parsedExtractStringTransformationDatalakeElementPayloadOutputName,
    };
    return parsedExtractStringTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface LabelTransformationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Transformation;
  source: DatalakeElementItemId;
  sourceColumn: number;
  labels: {regex: string; label: string}[];
  defaultLabel: string;
  outputName: string;
}

export const LabelTransformationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Transformation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number'},
  labels: {
    type: 'array',
    schema: {type: 'object', schema: {regex: {type: 'string'}, label: {type: 'string'}}},
  },
  defaultLabel: {type: 'string'},
  outputName: {type: 'string'},
};

export function parseLabelTransformationDatalakeElementPayload(
  json: Record<string, unknown>
): LabelTransformationDatalakeElementPayload {
  try {
    const parsedLabelTransformationDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedLabelTransformationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Transformation as const
    );
    const parsedLabelTransformationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedLabelTransformationDatalakeElementPayloadMap['source']
      );
    const parsedLabelTransformationDatalakeElementPayloadSourceColumn = asNumberOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['sourceColumn']
    );
    const parsedLabelTransformationDatalakeElementPayloadLabels = asArrayOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['labels']
    ).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementRegex = asStringOrThrow(parsedElementMap['regex']);
      const parsedElementLabel = asStringOrThrow(parsedElementMap['label']);
      const parsedElement = {
        regex: parsedElementRegex,
        label: parsedElementLabel,
      };
      return parsedElement;
    });
    const parsedLabelTransformationDatalakeElementPayloadDefaultLabel = asStringOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['defaultLabel']
    );
    const parsedLabelTransformationDatalakeElementPayloadOutputName = asStringOrThrow(
      parsedLabelTransformationDatalakeElementPayloadMap['outputName']
    );
    const parsedLabelTransformationDatalakeElementPayload = {
      group: parsedLabelTransformationDatalakeElementPayloadGroup,
      source: parsedLabelTransformationDatalakeElementPayloadSource,
      sourceColumn: parsedLabelTransformationDatalakeElementPayloadSourceColumn,
      labels: parsedLabelTransformationDatalakeElementPayloadLabels,
      defaultLabel: parsedLabelTransformationDatalakeElementPayloadDefaultLabel,
      outputName: parsedLabelTransformationDatalakeElementPayloadOutputName,
    };
    return parsedLabelTransformationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface SumsAggregationDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Aggregation;
  source: DatalakeElementItemId;
  sourceColumn?: number;
  sourcePrimaryKeyColumn: number;
}

export const SumsAggregationDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Aggregation',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  sourceColumn: {type: 'number', optional: true},
  sourcePrimaryKeyColumn: {type: 'number'},
};

export function parseSumsAggregationDatalakeElementPayload(
  json: Record<string, unknown>
): SumsAggregationDatalakeElementPayload {
  try {
    const parsedSumsAggregationDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedSumsAggregationDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedSumsAggregationDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Aggregation as const
    );
    const parsedSumsAggregationDatalakeElementPayloadSource =
      asStringOrThrow<DatalakeElementItemId>(
        parsedSumsAggregationDatalakeElementPayloadMap['source']
      );
    const parsedSumsAggregationDatalakeElementPayloadSourceColumn =
      parsedSumsAggregationDatalakeElementPayloadMap['sourceColumn'] === undefined
        ? undefined
        : asNumberOrThrow(parsedSumsAggregationDatalakeElementPayloadMap['sourceColumn']);
    const parsedSumsAggregationDatalakeElementPayloadSourcePrimaryKeyColumn = asNumberOrThrow(
      parsedSumsAggregationDatalakeElementPayloadMap['sourcePrimaryKeyColumn']
    );
    const parsedSumsAggregationDatalakeElementPayload = {
      group: parsedSumsAggregationDatalakeElementPayloadGroup,
      source: parsedSumsAggregationDatalakeElementPayloadSource,
      sourceColumn: parsedSumsAggregationDatalakeElementPayloadSourceColumn,
      sourcePrimaryKeyColumn: parsedSumsAggregationDatalakeElementPayloadSourcePrimaryKeyColumn,
    };
    return parsedSumsAggregationDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface GraphOutputDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Output;
  source: DatalakeElementItemId;
  breakdown: DatalakeTimeBreakdown;
  xAxisColumnIndex: number;
  yAxisColumnIndex: number;
}

export const GraphOutputDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Output',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  breakdown: {type: 'enum', enum: [{label: 'Hourly', value: 10}]},
  xAxisColumnIndex: {type: 'number'},
  yAxisColumnIndex: {type: 'number'},
};

export function parseGraphOutputDatalakeElementPayload(
  json: Record<string, unknown>
): GraphOutputDatalakeElementPayload {
  try {
    const parsedGraphOutputDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedGraphOutputDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedGraphOutputDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Output as const
    );
    const parsedGraphOutputDatalakeElementPayloadSource = asStringOrThrow<DatalakeElementItemId>(
      parsedGraphOutputDatalakeElementPayloadMap['source']
    );
    const parsedGraphOutputDatalakeElementPayloadBreakdown = parseDatalakeTimeBreakdown(
      asNumberOrThrow(parsedGraphOutputDatalakeElementPayloadMap['breakdown'])
    );
    const parsedGraphOutputDatalakeElementPayloadXAxisColumnIndex = asNumberOrThrow(
      parsedGraphOutputDatalakeElementPayloadMap['xAxisColumnIndex']
    );
    const parsedGraphOutputDatalakeElementPayloadYAxisColumnIndex = asNumberOrThrow(
      parsedGraphOutputDatalakeElementPayloadMap['yAxisColumnIndex']
    );
    const parsedGraphOutputDatalakeElementPayload = {
      group: parsedGraphOutputDatalakeElementPayloadGroup,
      source: parsedGraphOutputDatalakeElementPayloadSource,
      breakdown: parsedGraphOutputDatalakeElementPayloadBreakdown,
      xAxisColumnIndex: parsedGraphOutputDatalakeElementPayloadXAxisColumnIndex,
      yAxisColumnIndex: parsedGraphOutputDatalakeElementPayloadYAxisColumnIndex,
    };
    return parsedGraphOutputDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface RestApiOutputDatalakeElementPayload {
  group: DatalakeElementTypeGroup.Output;
  source: DatalakeElementItemId;
  breakdown: DatalakeTimeBreakdown;
  format: DatalakeRestApiFormat;
}

export const RestApiOutputDatalakeElementPayloadMetadata = {
  group: {
    type: 'enum-constant',
    enum: [
      {label: 'DataSource', value: 10},
      {label: 'Transformation', value: 20},
      {label: 'Aggregation', value: 30},
      {label: 'Output', value: 40},
    ],
    enumValue: 'Output',
  },
  source: {type: 'string', brand: 'DatalakeElementItemId'},
  breakdown: {type: 'enum', enum: [{label: 'Hourly', value: 10}]},
  format: {
    type: 'enum',
    enum: [
      {label: 'Json', value: 10},
      {label: 'Csv', value: 20},
    ],
  },
};

export function parseRestApiOutputDatalakeElementPayload(
  json: Record<string, unknown>
): RestApiOutputDatalakeElementPayload {
  try {
    const parsedRestApiOutputDatalakeElementPayloadMap = asMapOrThrow(json);
    const parsedRestApiOutputDatalakeElementPayloadGroup = asConstantOrThrow(
      parsedRestApiOutputDatalakeElementPayloadMap['group'],
      DatalakeElementTypeGroup.Output as const
    );
    const parsedRestApiOutputDatalakeElementPayloadSource = asStringOrThrow<DatalakeElementItemId>(
      parsedRestApiOutputDatalakeElementPayloadMap['source']
    );
    const parsedRestApiOutputDatalakeElementPayloadBreakdown = parseDatalakeTimeBreakdown(
      asNumberOrThrow(parsedRestApiOutputDatalakeElementPayloadMap['breakdown'])
    );
    const parsedRestApiOutputDatalakeElementPayloadFormat = parseDatalakeRestApiFormat(
      asNumberOrThrow(parsedRestApiOutputDatalakeElementPayloadMap['format'])
    );
    const parsedRestApiOutputDatalakeElementPayload = {
      group: parsedRestApiOutputDatalakeElementPayloadGroup,
      source: parsedRestApiOutputDatalakeElementPayloadSource,
      breakdown: parsedRestApiOutputDatalakeElementPayloadBreakdown,
      format: parsedRestApiOutputDatalakeElementPayloadFormat,
    };
    return parsedRestApiOutputDatalakeElementPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type DatalakeElementPayload<T extends DatalakeElementType> =
  T extends DatalakeElementType.WebEventsDataSource
    ? WebEventsDataSourceDatalakeElementPayload
    : T extends DatalakeElementType.FileDropDataSource
      ? FileDropDataSourceDatalakeElementPayload
      : T extends DatalakeElementType.QontoDataSource
        ? QontoDataSourceDatalakeElementPayload
        : T extends DatalakeElementType.IpGeolocTransformation
          ? IpGeolocTransformationDatalakeElementPayload
          : T extends DatalakeElementType.CalculationsTransformation
            ? CalculationsTransformationDatalakeElementPayload
            : T extends DatalakeElementType.ExtractStringTransformation
              ? ExtractStringTransformationDatalakeElementPayload
              : T extends DatalakeElementType.SumsAggregation
                ? SumsAggregationDatalakeElementPayload
                : T extends DatalakeElementType.GraphOutput
                  ? GraphOutputDatalakeElementPayload
                  : T extends DatalakeElementType.RestApiOutput
                    ? RestApiOutputDatalakeElementPayload
                    : T extends DatalakeElementType.LabelTransformation
                      ? LabelTransformationDatalakeElementPayload
                      : never;

export interface DatalakeElementItemByGroup {
  [DatalakeElementTypeGroup.DataSource]:
    | WebEventsDataSourceDatalakeElementItem
    | FileDropDataSourceDatalakeElementItem
    | QontoDataSourceDatalakeElementItem;
  [DatalakeElementTypeGroup.Transformation]:
    | IpGeolocTransformationDatalakeElementItem
    | CalculationsTransformationDatalakeElementItem
    | ExtractStringTransformationDatalakeElementItem
    | LabelTransformationDatalakeElementItem;
  [DatalakeElementTypeGroup.Aggregation]: SumsAggregationDatalakeElementItem;
  [DatalakeElementTypeGroup.Output]:
    | GraphOutputDatalakeElementItem
    | RestApiOutputDatalakeElementItem;
}

export const DatalakeElementTypeByGroup: Record<DatalakeElementTypeGroup, DatalakeElementType[]> = {
  [DatalakeElementTypeGroup.DataSource]: [
    DatalakeElementType.WebEventsDataSource,
    DatalakeElementType.FileDropDataSource,
    DatalakeElementType.QontoDataSource,
  ],
  [DatalakeElementTypeGroup.Transformation]: [
    DatalakeElementType.IpGeolocTransformation,
    DatalakeElementType.CalculationsTransformation,
    DatalakeElementType.ExtractStringTransformation,
    DatalakeElementType.LabelTransformation,
  ],
  [DatalakeElementTypeGroup.Aggregation]: [DatalakeElementType.SumsAggregation],
  [DatalakeElementTypeGroup.Output]: [
    DatalakeElementType.GraphOutput,
    DatalakeElementType.RestApiOutput,
  ],
  /* {
  "group": {
    "type": "enum-constant",
    "enum": "DatalakeElementTypeGroup",
    "enumValue": "DataSource"
  }
} */
};

const datalakeElementPayloadParsers = {
  [DatalakeElementType.WebEventsDataSource]: parseWebEventsDataSourceDatalakeElementPayload,
  [DatalakeElementType.FileDropDataSource]: parseFileDropDataSourceDatalakeElementPayload,
  [DatalakeElementType.QontoDataSource]: parseQontoDataSourceDatalakeElementPayload,
  [DatalakeElementType.IpGeolocTransformation]: parseIpGeolocTransformationDatalakeElementPayload,
  [DatalakeElementType.CalculationsTransformation]:
    parseCalculationsTransformationDatalakeElementPayload,
  [DatalakeElementType.ExtractStringTransformation]:
    parseExtractStringTransformationDatalakeElementPayload,
  [DatalakeElementType.LabelTransformation]: parseLabelTransformationDatalakeElementPayload,
  [DatalakeElementType.SumsAggregation]: parseSumsAggregationDatalakeElementPayload,
  [DatalakeElementType.GraphOutput]: parseGraphOutputDatalakeElementPayload,
  [DatalakeElementType.RestApiOutput]: parseRestApiOutputDatalakeElementPayload,
};

export function parseDatalakeElementPayload(
  type: DatalakeElementType,
  json: Record<string, unknown>
): DatalakeElementPayload<DatalakeElementType> {
  return datalakeElementPayloadParsers[type](json);
}

export const datalakeElementPayloadMetadata = {
  [DatalakeElementType.WebEventsDataSource]: WebEventsDataSourceDatalakeElementPayloadMetadata,
  [DatalakeElementType.FileDropDataSource]: FileDropDataSourceDatalakeElementPayloadMetadata,
  [DatalakeElementType.QontoDataSource]: QontoDataSourceDatalakeElementPayloadMetadata,
  [DatalakeElementType.IpGeolocTransformation]:
    IpGeolocTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.CalculationsTransformation]:
    CalculationsTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.ExtractStringTransformation]:
    ExtractStringTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.LabelTransformation]: LabelTransformationDatalakeElementPayloadMetadata,
  [DatalakeElementType.SumsAggregation]: SumsAggregationDatalakeElementPayloadMetadata,
  [DatalakeElementType.GraphOutput]: GraphOutputDatalakeElementPayloadMetadata,
  [DatalakeElementType.RestApiOutput]: RestApiOutputDatalakeElementPayloadMetadata,
};

export interface DatalakeElementItemGeneric<T extends DatalakeElementType> {
  elementId: DatalakeElementItemId;
  type: T;
  outputs: DatalakeElementOutputs;
  params: DatalakeElementPayload<T>;
  datalakeId: DataLakeId;
  accountId: AccountId;
  layout: DatalakeElementLayout;
}

export type WebEventsDataSourceDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.WebEventsDataSource>;
export type FileDropDataSourceDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.FileDropDataSource>;
export type QontoDataSourceDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.QontoDataSource>;
export type IpGeolocTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.IpGeolocTransformation>;
export type CalculationsTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.CalculationsTransformation>;
export type ExtractStringTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.ExtractStringTransformation>;
export type SumsAggregationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.SumsAggregation>;
export type GraphOutputDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.GraphOutput>;
export type RestApiOutputDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.RestApiOutput>;
export type LabelTransformationDatalakeElementItem =
  DatalakeElementItemGeneric<DatalakeElementType.LabelTransformation>;

export type DatalakeElementItem =
  | WebEventsDataSourceDatalakeElementItem
  | FileDropDataSourceDatalakeElementItem
  | QontoDataSourceDatalakeElementItem
  | IpGeolocTransformationDatalakeElementItem
  | CalculationsTransformationDatalakeElementItem
  | ExtractStringTransformationDatalakeElementItem
  | SumsAggregationDatalakeElementItem
  | GraphOutputDatalakeElementItem
  | RestApiOutputDatalakeElementItem
  | LabelTransformationDatalakeElementItem;

export interface DatalakeApiTokenItem {
  token: DatalakeApiToken; // a
  accountId: AccountId; // b
  datalakeId: DataLakeId; // c
  elementId: DatalakeElementItemId; // d
}

export interface DatalakeApiStatsItem {
  elementId: DatalakeElementItemId; // a
  minuteTs: number; // b
  counter: number; // c
  expiresAt: number; // d
  datalakeId: DataLakeId; // e
}

export interface DashboardItem {
  id: DashboardId; // a
  accountId: AccountId; // b
  jsonStr: string; // c
}

export interface ServiceAdArtisanItem {
  serviceAdArtisanId: ServiceAdArtisanId; // a
  name: string; // b
  phone: string; // c
}

export interface PendingAccountValidationServiceAdCustomerPayload {}

export const PendingAccountValidationServiceAdCustomerPayloadMetadata = {};

export function parsePendingAccountValidationServiceAdCustomerPayload(
  json: Record<string, unknown>
): PendingAccountValidationServiceAdCustomerPayload {
  try {
    const parsedPendingAccountValidationServiceAdCustomerPayload = {};
    return parsedPendingAccountValidationServiceAdCustomerPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface AccountValidatedServiceAdCustomerPayload {
  name: string;
  email: string;
}

export const AccountValidatedServiceAdCustomerPayloadMetadata = {
  name: {type: 'string'},
  email: {type: 'string'},
};

export function parseAccountValidatedServiceAdCustomerPayload(
  json: Record<string, unknown>
): AccountValidatedServiceAdCustomerPayload {
  try {
    const parsedAccountValidatedServiceAdCustomerPayloadMap = asMapOrThrow(json);
    const parsedAccountValidatedServiceAdCustomerPayloadName = asStringOrThrow(
      parsedAccountValidatedServiceAdCustomerPayloadMap['name']
    );
    const parsedAccountValidatedServiceAdCustomerPayloadEmail = asStringOrThrow(
      parsedAccountValidatedServiceAdCustomerPayloadMap['email']
    );
    const parsedAccountValidatedServiceAdCustomerPayload = {
      name: parsedAccountValidatedServiceAdCustomerPayloadName,
      email: parsedAccountValidatedServiceAdCustomerPayloadEmail,
    };
    return parsedAccountValidatedServiceAdCustomerPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type ServiceAdCustomerPayload<T extends ServiceAdCustomerStatus> =
  T extends ServiceAdCustomerStatus.PendingAccountValidation
    ? PendingAccountValidationServiceAdCustomerPayload
    : T extends ServiceAdCustomerStatus.AccountValidated
      ? AccountValidatedServiceAdCustomerPayload
      : never;

const serviceAdCustomerPayloadParsers = {
  [ServiceAdCustomerStatus.PendingAccountValidation]:
    parsePendingAccountValidationServiceAdCustomerPayload,
  [ServiceAdCustomerStatus.AccountValidated]: parseAccountValidatedServiceAdCustomerPayload,
};

export function parseServiceAdCustomerPayload(
  type: ServiceAdCustomerStatus,
  json: Record<string, unknown>
): ServiceAdCustomerPayload<ServiceAdCustomerStatus> {
  return serviceAdCustomerPayloadParsers[type](json);
}

export const serviceAdCustomerPayloadMetadata = {
  [ServiceAdCustomerStatus.PendingAccountValidation]:
    PendingAccountValidationServiceAdCustomerPayloadMetadata,
  [ServiceAdCustomerStatus.AccountValidated]: AccountValidatedServiceAdCustomerPayloadMetadata,
};

export interface ServiceAdCustomerItemGeneric<T extends ServiceAdCustomerStatus> {
  serviceAdCustomerId: ServiceAdCustomerId;
  domainId: string;
  phone: string;
  status: T;
  payload: ServiceAdCustomerPayload<T>;
  createdAt: string;
  updatedAt: string;
}

export type PendingAccountValidationServiceAdCustomerItem =
  ServiceAdCustomerItemGeneric<ServiceAdCustomerStatus.PendingAccountValidation>;
export type AccountValidatedServiceAdCustomerItem =
  ServiceAdCustomerItemGeneric<ServiceAdCustomerStatus.AccountValidated>;

export type ServiceAdCustomerItem =
  | PendingAccountValidationServiceAdCustomerItem
  | AccountValidatedServiceAdCustomerItem;

export interface ServiceAdRequestStatusHistory {
  content: {status: ServiceAdRequestStatus; updatedAt: string; artisanId?: ServiceAdArtisanId}[];
}

export function parseServiceAdRequestStatusHistory(
  json: Record<string, unknown>
): ServiceAdRequestStatusHistory {
  try {
    const parsedServiceAdRequestStatusHistoryMap = asMapOrThrow(json);
    const parsedServiceAdRequestStatusHistoryContent = asArrayOrThrow(
      parsedServiceAdRequestStatusHistoryMap['content']
    ).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementStatus = parseServiceAdRequestStatus(
        asNumberOrThrow(parsedElementMap['status'])
      );
      const parsedElementUpdatedAt = asStringOrThrow(parsedElementMap['updatedAt']);
      const parsedElementArtisanId =
        parsedElementMap['artisanId'] === undefined
          ? undefined
          : asStringOrThrow<ServiceAdArtisanId>(parsedElementMap['artisanId']);
      const parsedElement = {
        status: parsedElementStatus,
        updatedAt: parsedElementUpdatedAt,
        artisanId: parsedElementArtisanId,
      };
      return parsedElement;
    });
    const parsedServiceAdRequestStatusHistory = {
      content: parsedServiceAdRequestStatusHistoryContent,
    };
    return parsedServiceAdRequestStatusHistory;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse ServiceAdRequestStatusHistory payload: ${errorAsString(
        err
      )}. Payload for ServiceAdRequestStatusHistory was ${JSON.stringify(json)}`
    );
  }
}

export interface PendingPhoneValidationServiceAdRequestPayload {
  phoneValidationToken: string;
}

export const PendingPhoneValidationServiceAdRequestPayloadMetadata = {
  phoneValidationToken: {type: 'string'},
};

export function parsePendingPhoneValidationServiceAdRequestPayload(
  json: Record<string, unknown>
): PendingPhoneValidationServiceAdRequestPayload {
  try {
    const parsedPendingPhoneValidationServiceAdRequestPayloadMap = asMapOrThrow(json);
    const parsedPendingPhoneValidationServiceAdRequestPayloadPhoneValidationToken = asStringOrThrow(
      parsedPendingPhoneValidationServiceAdRequestPayloadMap['phoneValidationToken']
    );
    const parsedPendingPhoneValidationServiceAdRequestPayload = {
      phoneValidationToken: parsedPendingPhoneValidationServiceAdRequestPayloadPhoneValidationToken,
    };
    return parsedPendingPhoneValidationServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface PendingArtisanAcceptationServiceAdRequestPayload {
  artisanId: ServiceAdArtisanId;
}

export const PendingArtisanAcceptationServiceAdRequestPayloadMetadata = {
  artisanId: {type: 'string', brand: 'ServiceAdArtisanId'},
};

export function parsePendingArtisanAcceptationServiceAdRequestPayload(
  json: Record<string, unknown>
): PendingArtisanAcceptationServiceAdRequestPayload {
  try {
    const parsedPendingArtisanAcceptationServiceAdRequestPayloadMap = asMapOrThrow(json);
    const parsedPendingArtisanAcceptationServiceAdRequestPayloadArtisanId =
      asStringOrThrow<ServiceAdArtisanId>(
        parsedPendingArtisanAcceptationServiceAdRequestPayloadMap['artisanId']
      );
    const parsedPendingArtisanAcceptationServiceAdRequestPayload = {
      artisanId: parsedPendingArtisanAcceptationServiceAdRequestPayloadArtisanId,
    };
    return parsedPendingArtisanAcceptationServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ArtisanAcceptedServiceAdRequestPayload {
  artisanId: ServiceAdArtisanId;
  artisanName: string;
}

export const ArtisanAcceptedServiceAdRequestPayloadMetadata = {
  artisanId: {type: 'string', brand: 'ServiceAdArtisanId'},
  artisanName: {type: 'string'},
};

export function parseArtisanAcceptedServiceAdRequestPayload(
  json: Record<string, unknown>
): ArtisanAcceptedServiceAdRequestPayload {
  try {
    const parsedArtisanAcceptedServiceAdRequestPayloadMap = asMapOrThrow(json);
    const parsedArtisanAcceptedServiceAdRequestPayloadArtisanId =
      asStringOrThrow<ServiceAdArtisanId>(
        parsedArtisanAcceptedServiceAdRequestPayloadMap['artisanId']
      );
    const parsedArtisanAcceptedServiceAdRequestPayloadArtisanName = asStringOrThrow(
      parsedArtisanAcceptedServiceAdRequestPayloadMap['artisanName']
    );
    const parsedArtisanAcceptedServiceAdRequestPayload = {
      artisanId: parsedArtisanAcceptedServiceAdRequestPayloadArtisanId,
      artisanName: parsedArtisanAcceptedServiceAdRequestPayloadArtisanName,
    };
    return parsedArtisanAcceptedServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ArtisanDeniedServiceAdRequestPayload {}

export const ArtisanDeniedServiceAdRequestPayloadMetadata = {};

export function parseArtisanDeniedServiceAdRequestPayload(
  json: Record<string, unknown>
): ArtisanDeniedServiceAdRequestPayload {
  try {
    const parsedArtisanDeniedServiceAdRequestPayload = {};
    return parsedArtisanDeniedServiceAdRequestPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type ServiceAdRequestPayload<T extends ServiceAdRequestStatus> =
  T extends ServiceAdRequestStatus.PendingPhoneValidation
    ? PendingPhoneValidationServiceAdRequestPayload
    : T extends ServiceAdRequestStatus.PendingArtisanAcceptation
      ? PendingArtisanAcceptationServiceAdRequestPayload
      : T extends ServiceAdRequestStatus.ArtisanAccepted
        ? ArtisanAcceptedServiceAdRequestPayload
        : T extends ServiceAdRequestStatus.ArtisanDenied
          ? ArtisanDeniedServiceAdRequestPayload
          : never;

const serviceAdRequestPayloadParsers = {
  [ServiceAdRequestStatus.PendingPhoneValidation]:
    parsePendingPhoneValidationServiceAdRequestPayload,
  [ServiceAdRequestStatus.PendingArtisanAcceptation]:
    parsePendingArtisanAcceptationServiceAdRequestPayload,
  [ServiceAdRequestStatus.ArtisanAccepted]: parseArtisanAcceptedServiceAdRequestPayload,
  [ServiceAdRequestStatus.ArtisanDenied]: parseArtisanDeniedServiceAdRequestPayload,
};

export function parseServiceAdRequestPayload(
  type: ServiceAdRequestStatus,
  json: Record<string, unknown>
): ServiceAdRequestPayload<ServiceAdRequestStatus> {
  return serviceAdRequestPayloadParsers[type](json);
}

export const serviceAdRequestPayloadMetadata = {
  [ServiceAdRequestStatus.PendingPhoneValidation]:
    PendingPhoneValidationServiceAdRequestPayloadMetadata,
  [ServiceAdRequestStatus.PendingArtisanAcceptation]:
    PendingArtisanAcceptationServiceAdRequestPayloadMetadata,
  [ServiceAdRequestStatus.ArtisanAccepted]: ArtisanAcceptedServiceAdRequestPayloadMetadata,
  [ServiceAdRequestStatus.ArtisanDenied]: ArtisanDeniedServiceAdRequestPayloadMetadata,
};

export interface ServiceAdRequestItemGeneric<T extends ServiceAdRequestStatus> {
  serviceAdRequestId: ServiceAdRequestId;
  address: string;
  phone: string;
  description: string;
  status: T;
  payload: ServiceAdRequestPayload<T>;
  createdAt: string;
  updatedAt: string;
  statusHistory: ServiceAdRequestStatusHistory;
  domainId: string;
}

export type PendingPhoneValidationServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.PendingPhoneValidation>;
export type PendingArtisanAcceptationServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.PendingArtisanAcceptation>;
export type ArtisanAcceptedServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.ArtisanAccepted>;
export type ArtisanDeniedServiceAdRequestItem =
  ServiceAdRequestItemGeneric<ServiceAdRequestStatus.ArtisanDenied>;

export type ServiceAdRequestItem =
  | PendingPhoneValidationServiceAdRequestItem
  | PendingArtisanAcceptationServiceAdRequestItem
  | ArtisanAcceptedServiceAdRequestItem
  | ArtisanDeniedServiceAdRequestItem;

export interface HoobiizStripeEventItem {
  id: StripePaymentIntentId; // a
  body: string; // b
}

export function parseHoobiizMediaVersions(
  json: Record<string, unknown>
): {bytes: number; mimeType: string; width: number; height: number; ext: string}[] {
  try {
    const parsedHoobiizMediaVersions = asArrayOrThrow(json).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementBytes = asNumberOrThrow(parsedElementMap['bytes']);
      const parsedElementMimeType = asStringOrThrow(parsedElementMap['mimeType']);
      const parsedElementWidth = asNumberOrThrow(parsedElementMap['width']);
      const parsedElementHeight = asNumberOrThrow(parsedElementMap['height']);
      const parsedElementExt = asStringOrThrow(parsedElementMap['ext']);
      const parsedElement = {
        bytes: parsedElementBytes,
        mimeType: parsedElementMimeType,
        width: parsedElementWidth,
        height: parsedElementHeight,
        ext: parsedElementExt,
      };
      return parsedElement;
    });
    return parsedHoobiizMediaVersions;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizMediaVersions payload: ${errorAsString(
        err
      )}. Payload for HoobiizMediaVersions was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizMediaPlaceholder {
  width: number;
  height: number;
  base64: string;
}

export function parseHoobiizMediaPlaceholder(
  json: Record<string, unknown>
): HoobiizMediaPlaceholder {
  try {
    const parsedHoobiizMediaPlaceholderMap = asMapOrThrow(json);
    const parsedHoobiizMediaPlaceholderWidth = asNumberOrThrow(
      parsedHoobiizMediaPlaceholderMap['width']
    );
    const parsedHoobiizMediaPlaceholderHeight = asNumberOrThrow(
      parsedHoobiizMediaPlaceholderMap['height']
    );
    const parsedHoobiizMediaPlaceholderBase64 = asStringOrThrow(
      parsedHoobiizMediaPlaceholderMap['base64']
    );
    const parsedHoobiizMediaPlaceholder = {
      width: parsedHoobiizMediaPlaceholderWidth,
      height: parsedHoobiizMediaPlaceholderHeight,
      base64: parsedHoobiizMediaPlaceholderBase64,
    };
    return parsedHoobiizMediaPlaceholder;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizMediaPlaceholder payload: ${errorAsString(
        err
      )}. Payload for HoobiizMediaPlaceholder was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizMediaItem {
  id: HoobiizMediaId; // a
  hash?: string; // b
  versions: {bytes: number; mimeType: string; width: number; height: number; ext: string}[]; // c
  placeholder: HoobiizMediaPlaceholder; // h
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizCat1Item {
  id: HoobiizCat1Id; // a
  name: string; // b
  description: string; // c
  mediaId: HoobiizMediaId; // d
  order: number; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizCat2Item {
  id: HoobiizCat2Id; // a
  name: string; // b
  description: string; // c
  mediaId: HoobiizMediaId; // d
  cat1Id: HoobiizCat1Id; // e
  order: number; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizCat3Item {
  id: HoobiizCat3Id; // a
  name: string; // b
  description: string; // c
  mediaId: HoobiizMediaId; // d
  cat2Id: HoobiizCat2Id; // e
  order: number; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizVendorAddresses(
  json: Record<string, unknown>
): HoobiizVendorLocation[] {
  try {
    const parsedHoobiizVendorAddresses = asArrayOrThrow(json).map(element => {
      const parsedElement = parseHoobiizVendorLocation(asMapOrThrow(element));
      return parsedElement;
    });
    return parsedHoobiizVendorAddresses;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorAddresses payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorAddresses was ${JSON.stringify(json)}`
    );
  }
}

export function parseHoobiizVendorMediaIds(json: Record<string, unknown>): HoobiizMediaId[] {
  try {
    const parsedHoobiizVendorMediaIds = asArrayOrThrow(json).map(element => {
      const parsedElement = asStringOrThrow<HoobiizMediaId>(element);
      return parsedElement;
    });
    return parsedHoobiizVendorMediaIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizVendorMediaIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizVendorMediaIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizVendorItem {
  id: HoobiizVendorId; // a
  name: string; // b
  slugName: string; // c
  addresses: HoobiizVendorLocation[]; // d
  contact: HoobiizVendorContact; // e
  markdown: Markdown; // f
  mediaIds: HoobiizMediaId[]; // g
  visibility: HoobiizVisibility; // h
  comments: string; // i
  externalUrl?: string; // j
  logoMediaId?: HoobiizMediaId; // k
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizActivityCatIds(json: Record<string, unknown>): string[] {
  try {
    const parsedHoobiizActivityCatIds = asArrayOrThrow(json).map(element => {
      const parsedElement = asStringOrThrow(element);
      return parsedElement;
    });
    return parsedHoobiizActivityCatIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizActivityCatIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizActivityCatIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizActivityAddressVendor {
  type: HoobiizActivityAddressType.Vendor;
}

export function parseHoobiizActivityAddressVendor(): Omit<HoobiizActivityAddressVendor, 'type'> {
  return {};
}

export interface HoobiizActivityAddressAtHome {
  type: HoobiizActivityAddressType.AtHome;
}

export function parseHoobiizActivityAddressAtHome(): Omit<HoobiizActivityAddressAtHome, 'type'> {
  return {};
}

export interface HoobiizActivityAddressManual {
  type: HoobiizActivityAddressType.Manual;
  address: AddressLookupResult;
}

export function parseHoobiizActivityAddressManual(
  json: Record<string, unknown>
): Omit<HoobiizActivityAddressManual, 'type'> {
  try {
    const parsedHoobiizActivityAddressManualMap = asMapOrThrow(json);
    const parsedHoobiizActivityAddressManualAddress = parseAddressLookupResult(
      asMapOrThrow(parsedHoobiizActivityAddressManualMap['address'])
    );
    const parsedHoobiizActivityAddressManual = {
      address: parsedHoobiizActivityAddressManualAddress,
    };
    return parsedHoobiizActivityAddressManual;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizActivityAddressManual: ${errorAsString(err)}`);
  }
}

export type HoobiizActivityAddress =
  | HoobiizActivityAddressVendor
  | HoobiizActivityAddressAtHome
  | HoobiizActivityAddressManual;

export function parseHoobiizActivityAddress(json: Record<string, unknown>): HoobiizActivityAddress {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizActivityAddressType.Vendor) {
      return {type, ...parseHoobiizActivityAddressVendor()};
    } else if (type === HoobiizActivityAddressType.AtHome) {
      return {type, ...parseHoobiizActivityAddressAtHome()};
    } else if (type === HoobiizActivityAddressType.Manual) {
      return {type, ...parseHoobiizActivityAddressManual(json)};
    }
    throw new Error(`Failure to parse HoobiizActivityAddress: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizActivityAddress: ${errorAsString(err)}.`);
  }
}

export function parseHoobiizActivityMediaIds(json: Record<string, unknown>): HoobiizMediaId[] {
  try {
    const parsedHoobiizActivityMediaIds = asArrayOrThrow(json).map(element => {
      const parsedElement = asStringOrThrow<HoobiizMediaId>(element);
      return parsedElement;
    });
    return parsedHoobiizActivityMediaIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizActivityMediaIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizActivityMediaIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizActivityItem {
  id: HoobiizActivityId; // a
  vendorId: HoobiizVendorId; // b
  catIds: string[]; // c
  label: string; // d
  slugName: string; // e
  address: HoobiizActivityAddress; // f
  mediaIds: HoobiizMediaId[]; // g
  markdown: Markdown; // h
  visibility: HoobiizVisibility; // i
  comments: string; // j
  externalUrl?: string; // k
  cse?: boolean; // l
  headerOverride?: string; // m
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizStockTicketInfo(json: Record<string, unknown>): HoobiizTicketInfo[] {
  try {
    const parsedHoobiizStockTicketInfo = asArrayOrThrow(json).map(element => {
      const parsedElement = parseHoobiizTicketInfo(asMapOrThrow(element));
      return parsedElement;
    });
    return parsedHoobiizStockTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizStockTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizStockTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

/**
 * Tickets from the stock can be used at any time within a period.
 */
export interface HoobiizStockReservationFlexible {
  type: HoobiizStockReservationType.Flexible;
  schedule: HoobiizFlexibleStockSchedule;
  period: HoobiizTimePeriod;
}

export function parseHoobiizStockReservationFlexible(
  json: Record<string, unknown>
): Omit<HoobiizStockReservationFlexible, 'type'> {
  try {
    const parsedHoobiizStockReservationFlexibleMap = asMapOrThrow(json);
    const parsedHoobiizStockReservationFlexibleSchedule = parseHoobiizFlexibleStockSchedule(
      asMapOrThrow(parsedHoobiizStockReservationFlexibleMap['schedule'])
    );
    const parsedHoobiizStockReservationFlexiblePeriod = parseHoobiizTimePeriod(
      asMapOrThrow(parsedHoobiizStockReservationFlexibleMap['period'])
    );
    const parsedHoobiizStockReservationFlexible = {
      schedule: parsedHoobiizStockReservationFlexibleSchedule,
      period: parsedHoobiizStockReservationFlexiblePeriod,
    };
    return parsedHoobiizStockReservationFlexible;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockReservationFlexible: ${errorAsString(err)}`);
  }
}

/**
 * Tickets from the stock can only be used at a specific day and time.
 */
export interface HoobiizStockReservationFixed {
  type: HoobiizStockReservationType.Fixed;
  period: HoobiizTimePeriod;
}

export function parseHoobiizStockReservationFixed(
  json: Record<string, unknown>
): Omit<HoobiizStockReservationFixed, 'type'> {
  try {
    const parsedHoobiizStockReservationFixedMap = asMapOrThrow(json);
    const parsedHoobiizStockReservationFixedPeriod = parseHoobiizTimePeriod(
      asMapOrThrow(parsedHoobiizStockReservationFixedMap['period'])
    );
    const parsedHoobiizStockReservationFixed = {
      period: parsedHoobiizStockReservationFixedPeriod,
    };
    return parsedHoobiizStockReservationFixed;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockReservationFixed: ${errorAsString(err)}`);
  }
}

export type HoobiizStockReservation =
  | HoobiizStockReservationFlexible
  | HoobiizStockReservationFixed;

export function parseHoobiizStockReservation(
  json: Record<string, unknown>
): HoobiizStockReservation {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizStockReservationType.Flexible) {
      return {type, ...parseHoobiizStockReservationFlexible(json)};
    } else if (type === HoobiizStockReservationType.Fixed) {
      return {type, ...parseHoobiizStockReservationFixed(json)};
    }
    throw new Error(`Failure to parse HoobiizStockReservation: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizStockReservation: ${errorAsString(err)}.`);
  }
}

export interface HoobiizStockItem {
  id: HoobiizStockId; // a
  activityId: HoobiizActivityId; // b
  batchId: HoobiizStockBatchId; // c
  quantity: number; // d
  reserved: number; // e
  bought: number; // f
  remaining: number; // l
  ticketInfo: HoobiizTicketInfo[]; // g
  visibility: HoobiizVisibility; // h
  reservation: HoobiizStockReservation; // i
  mode: HoobiizStockMode; // j
  weeklyTemplateId?: HoobiizStockWeeklyTemplateId; // k
  terms?: string; // m
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizExpertTicketStockTicketInfo(
  json: Record<string, unknown>
): HoobiizExpertTicketInfo[] {
  try {
    const parsedHoobiizExpertTicketStockTicketInfo = asArrayOrThrow(json).map(element => {
      const parsedElement = parseHoobiizExpertTicketInfo(asMapOrThrow(element));
      return parsedElement;
    });
    return parsedHoobiizExpertTicketStockTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizExpertTicketStockTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizExpertTicketStockTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizExpertTicketStockItem {
  id: HoobiizExpertTicketStockId; // a
  activityId: HoobiizActivityId; // b
  ticketInfo: HoobiizExpertTicketInfo[]; // c
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizStockWeeklyTemplateTicketInfo(
  json: Record<string, unknown>
): HoobiizTicketInfo[] {
  try {
    const parsedHoobiizStockWeeklyTemplateTicketInfo = asArrayOrThrow(json).map(element => {
      const parsedElement = parseHoobiizTicketInfo(asMapOrThrow(element));
      return parsedElement;
    });
    return parsedHoobiizStockWeeklyTemplateTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizStockWeeklyTemplateTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizStockWeeklyTemplateTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizStockWeeklyTemplateItem {
  id: HoobiizStockWeeklyTemplateId; // a
  activityId: HoobiizActivityId; // b
  batchId: HoobiizStockBatchId; // c
  quantity: number; // d
  ticketInfo: HoobiizTicketInfo[]; // e
  visibility: HoobiizVisibility; // f
  period: HoobiizTimePeriod; // g
  mode: HoobiizStockMode; // h
  timeOfDay: HoobiizTimeOfDay; // i
  weekday: HoobiizWeekday; // j
  terms?: string; // k
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizOfferTargetGlobal {
  type: HoobiizOfferTargetType.Global;
}

export function parseHoobiizOfferTargetGlobal(): Omit<HoobiizOfferTargetGlobal, 'type'> {
  return {};
}

export interface HoobiizOfferTargetVendor {
  type: HoobiizOfferTargetType.Vendor;
  vendorId: HoobiizVendorId;
}

export function parseHoobiizOfferTargetVendor(
  json: Record<string, unknown>
): Omit<HoobiizOfferTargetVendor, 'type'> {
  try {
    const parsedHoobiizOfferTargetVendorMap = asMapOrThrow(json);
    const parsedHoobiizOfferTargetVendorVendorId = asStringOrThrow<HoobiizVendorId>(
      parsedHoobiizOfferTargetVendorMap['vendorId']
    );
    const parsedHoobiizOfferTargetVendor = {
      vendorId: parsedHoobiizOfferTargetVendorVendorId,
    };
    return parsedHoobiizOfferTargetVendor;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferTargetVendor: ${errorAsString(err)}`);
  }
}

export interface HoobiizOfferTargetActivity {
  type: HoobiizOfferTargetType.Activity;
  activityId: HoobiizActivityId;
  reservation?: {period?: HoobiizTimePeriod; hours: HoobiizWeekPeriods};
}

export function parseHoobiizOfferTargetActivity(
  json: Record<string, unknown>
): Omit<HoobiizOfferTargetActivity, 'type'> {
  try {
    const parsedHoobiizOfferTargetActivityMap = asMapOrThrow(json);
    const parsedHoobiizOfferTargetActivityActivityId = asStringOrThrow<HoobiizActivityId>(
      parsedHoobiizOfferTargetActivityMap['activityId']
    );
    const parsedHoobiizOfferTargetActivityReservationMap =
      parsedHoobiizOfferTargetActivityMap['reservation'] === undefined
        ? undefined
        : asMapOrThrow(parsedHoobiizOfferTargetActivityMap['reservation']);
    const parsedHoobiizOfferTargetActivityReservation =
      parsedHoobiizOfferTargetActivityReservationMap === undefined
        ? undefined
        : (() => {
            const parsedHoobiizOfferTargetActivityReservationPeriod =
              parsedHoobiizOfferTargetActivityReservationMap['period'] === undefined
                ? undefined
                : parseHoobiizTimePeriod(
                    asMapOrThrow(parsedHoobiizOfferTargetActivityReservationMap['period'])
                  );
            const parsedHoobiizOfferTargetActivityReservationHours = parseHoobiizWeekPeriods(
              asMapOrThrow(parsedHoobiizOfferTargetActivityReservationMap['hours'])
            );
            return {
              period: parsedHoobiizOfferTargetActivityReservationPeriod,
              hours: parsedHoobiizOfferTargetActivityReservationHours,
            };
          })();
    const parsedHoobiizOfferTargetActivity = {
      activityId: parsedHoobiizOfferTargetActivityActivityId,
      reservation: parsedHoobiizOfferTargetActivityReservation,
    };
    return parsedHoobiizOfferTargetActivity;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferTargetActivity: ${errorAsString(err)}`);
  }
}

export type HoobiizOfferTarget =
  | HoobiizOfferTargetGlobal
  | HoobiizOfferTargetVendor
  | HoobiizOfferTargetActivity;

export function parseHoobiizOfferTarget(json: Record<string, unknown>): HoobiizOfferTarget {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizOfferTargetType.Global) {
      return {type, ...parseHoobiizOfferTargetGlobal()};
    } else if (type === HoobiizOfferTargetType.Vendor) {
      return {type, ...parseHoobiizOfferTargetVendor(json)};
    } else if (type === HoobiizOfferTargetType.Activity) {
      return {type, ...parseHoobiizOfferTargetActivity(json)};
    }
    throw new Error(`Failure to parse HoobiizOfferTarget: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferTarget: ${errorAsString(err)}.`);
  }
}

export interface HoobiizOfferFlashDisabled {
  type: HoobiizOfferFlashType.Disabled;
}

export function parseHoobiizOfferFlashDisabled(): Omit<HoobiizOfferFlashDisabled, 'type'> {
  return {};
}

export interface HoobiizOfferFlashPeriod {
  type: HoobiizOfferFlashType.Period;
  period: HoobiizTimePeriod;
}

export function parseHoobiizOfferFlashPeriod(
  json: Record<string, unknown>
): Omit<HoobiizOfferFlashPeriod, 'type'> {
  try {
    const parsedHoobiizOfferFlashPeriodMap = asMapOrThrow(json);
    const parsedHoobiizOfferFlashPeriodPeriod = parseHoobiizTimePeriod(
      asMapOrThrow(parsedHoobiizOfferFlashPeriodMap['period'])
    );
    const parsedHoobiizOfferFlashPeriod = {
      period: parsedHoobiizOfferFlashPeriodPeriod,
    };
    return parsedHoobiizOfferFlashPeriod;
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferFlashPeriod: ${errorAsString(err)}`);
  }
}

export type HoobiizOfferFlash = HoobiizOfferFlashDisabled | HoobiizOfferFlashPeriod;

export function parseHoobiizOfferFlash(json: Record<string, unknown>): HoobiizOfferFlash {
  try {
    const type = asStringOrThrow(json.type);
    if (type === HoobiizOfferFlashType.Disabled) {
      return {type, ...parseHoobiizOfferFlashDisabled()};
    } else if (type === HoobiizOfferFlashType.Period) {
      return {type, ...parseHoobiizOfferFlashPeriod(json)};
    }
    throw new Error(`Failure to parse HoobiizOfferFlash: Unknown type ${type}.`);
  } catch (err: unknown) {
    throw new Error(`Failure to parse HoobiizOfferFlash: ${errorAsString(err)}.`);
  }
}

export interface HoobiizOfferItem {
  id: HoobiizOfferId; // a
  label: string; // b
  target: HoobiizOfferTarget; // c
  flash: HoobiizOfferFlash; // d
  discount: HoobiizDiscount; // e
  visibility: HoobiizVisibility; // f
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizTicketFileItem {
  id: HoobiizTicketFileId; // a
  mimeType: string; // b
  size: number; // c
  createdAt: number; // y
  updatedAt: number; // z
}

export function parseHoobiizTicketPurchaseFileIds(
  json: Record<string, unknown>
): HoobiizTicketFileId[] {
  try {
    const parsedHoobiizTicketPurchaseFileIds = asArrayOrThrow(json).map(element => {
      const parsedElement = asStringOrThrow<HoobiizTicketFileId>(element);
      return parsedElement;
    });
    return parsedHoobiizTicketPurchaseFileIds;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizTicketPurchaseFileIds payload: ${errorAsString(
        err
      )}. Payload for HoobiizTicketPurchaseFileIds was ${JSON.stringify(json)}`
    );
  }
}

export interface HoobiizTicketPurchaseItem {
  id: HoobiizTicketPurchaseId; // a
  stockId: HoobiizStockId; // b
  activityId: HoobiizActivityId; // c
  vendorId: HoobiizVendorId; // d
  ticketInfoId: HoobiizTicketInfoId; // e
  offerId: HoobiizOfferId; // f
  userId: FrontendUserId; // g
  paymentIntentId: StripePaymentIntentId; // h
  status: HoobiizTicketPurchaseStatus; // i
  fileIds: HoobiizTicketFileId[]; // j
  createdAt: number; // y
  updatedAt: number; // z
}

export interface TicketHoobiizCartItemPayload {
  stockIds: HoobiizStockId[];
  offerId?: HoobiizOfferId;
  ticketInfoId: HoobiizTicketInfoId;
  quantity: number;
  options: {optionId: HoobiizTicketInfoOptionId; quantity: number}[];
}

export const TicketHoobiizCartItemPayloadMetadata = {
  stockIds: {type: 'array', schema: {type: 'string', brand: 'HoobiizStockId'}},
  offerId: {type: 'string', brand: 'HoobiizOfferId', optional: true},
  ticketInfoId: {type: 'string', brand: 'HoobiizTicketInfoId'},
  quantity: {type: 'number'},
  options: {
    type: 'array',
    schema: {
      type: 'object',
      schema: {
        optionId: {type: 'string', brand: 'HoobiizTicketInfoOptionId'},
        quantity: {type: 'number'},
      },
    },
  },
};

export function parseTicketHoobiizCartItemPayload(
  json: Record<string, unknown>
): TicketHoobiizCartItemPayload {
  try {
    const parsedTicketHoobiizCartItemPayloadMap = asMapOrThrow(json);
    const parsedTicketHoobiizCartItemPayloadStockIds = asArrayOrThrow(
      parsedTicketHoobiizCartItemPayloadMap['stockIds']
    ).map(element => {
      const parsedElement = asStringOrThrow<HoobiizStockId>(element);
      return parsedElement;
    });
    const parsedTicketHoobiizCartItemPayloadOfferId =
      parsedTicketHoobiizCartItemPayloadMap['offerId'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizOfferId>(parsedTicketHoobiizCartItemPayloadMap['offerId']);
    const parsedTicketHoobiizCartItemPayloadTicketInfoId = asStringOrThrow<HoobiizTicketInfoId>(
      parsedTicketHoobiizCartItemPayloadMap['ticketInfoId']
    );
    const parsedTicketHoobiizCartItemPayloadQuantity = asNumberOrThrow(
      parsedTicketHoobiizCartItemPayloadMap['quantity']
    );
    const parsedTicketHoobiizCartItemPayloadOptions = asArrayOrThrow(
      parsedTicketHoobiizCartItemPayloadMap['options']
    ).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementOptionId = asStringOrThrow<HoobiizTicketInfoOptionId>(
        parsedElementMap['optionId']
      );
      const parsedElementQuantity = asNumberOrThrow(parsedElementMap['quantity']);
      const parsedElement = {
        optionId: parsedElementOptionId,
        quantity: parsedElementQuantity,
      };
      return parsedElement;
    });
    const parsedTicketHoobiizCartItemPayload = {
      stockIds: parsedTicketHoobiizCartItemPayloadStockIds,
      offerId: parsedTicketHoobiizCartItemPayloadOfferId,
      ticketInfoId: parsedTicketHoobiizCartItemPayloadTicketInfoId,
      quantity: parsedTicketHoobiizCartItemPayloadQuantity,
      options: parsedTicketHoobiizCartItemPayloadOptions,
    };
    return parsedTicketHoobiizCartItemPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ExpertTicketHoobiizCartItemPayload {
  stockId: HoobiizExpertTicketStockId;
  ticketInfoId: HoobiizExpertTicketInfoId;
  quantity: number;
  date?: number;
}

export const ExpertTicketHoobiizCartItemPayloadMetadata = {
  stockId: {type: 'string', brand: 'HoobiizExpertTicketStockId'},
  ticketInfoId: {type: 'string', brand: 'HoobiizExpertTicketInfoId'},
  quantity: {type: 'number'},
  date: {type: 'number', optional: true},
};

export function parseExpertTicketHoobiizCartItemPayload(
  json: Record<string, unknown>
): ExpertTicketHoobiizCartItemPayload {
  try {
    const parsedExpertTicketHoobiizCartItemPayloadMap = asMapOrThrow(json);
    const parsedExpertTicketHoobiizCartItemPayloadStockId =
      asStringOrThrow<HoobiizExpertTicketStockId>(
        parsedExpertTicketHoobiizCartItemPayloadMap['stockId']
      );
    const parsedExpertTicketHoobiizCartItemPayloadTicketInfoId =
      asStringOrThrow<HoobiizExpertTicketInfoId>(
        parsedExpertTicketHoobiizCartItemPayloadMap['ticketInfoId']
      );
    const parsedExpertTicketHoobiizCartItemPayloadQuantity = asNumberOrThrow(
      parsedExpertTicketHoobiizCartItemPayloadMap['quantity']
    );
    const parsedExpertTicketHoobiizCartItemPayloadDate =
      parsedExpertTicketHoobiizCartItemPayloadMap['date'] === undefined
        ? undefined
        : asNumberOrThrow(parsedExpertTicketHoobiizCartItemPayloadMap['date']);
    const parsedExpertTicketHoobiizCartItemPayload = {
      stockId: parsedExpertTicketHoobiizCartItemPayloadStockId,
      ticketInfoId: parsedExpertTicketHoobiizCartItemPayloadTicketInfoId,
      quantity: parsedExpertTicketHoobiizCartItemPayloadQuantity,
      date: parsedExpertTicketHoobiizCartItemPayloadDate,
    };
    return parsedExpertTicketHoobiizCartItemPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type HoobiizCartItemPayload<T extends HoobiizCartItemType> =
  T extends HoobiizCartItemType.Ticket
    ? TicketHoobiizCartItemPayload
    : T extends HoobiizCartItemType.ExpertTicket
      ? ExpertTicketHoobiizCartItemPayload
      : never;

const hoobiizCartItemPayloadParsers = {
  [HoobiizCartItemType.Ticket]: parseTicketHoobiizCartItemPayload,
  [HoobiizCartItemType.ExpertTicket]: parseExpertTicketHoobiizCartItemPayload,
};

export function parseHoobiizCartItemPayload(
  type: HoobiizCartItemType,
  json: Record<string, unknown>
): HoobiizCartItemPayload<HoobiizCartItemType> {
  return hoobiizCartItemPayloadParsers[type](json);
}

export const hoobiizCartItemPayloadMetadata = {
  [HoobiizCartItemType.Ticket]: TicketHoobiizCartItemPayloadMetadata,
  [HoobiizCartItemType.ExpertTicket]: ExpertTicketHoobiizCartItemPayloadMetadata,
};

export interface HoobiizCartItemItemGeneric<T extends HoobiizCartItemType> {
  id: HoobiizCartItemId;
  userId: FrontendUserId;
  itemType: T;
  itemData: HoobiizCartItemPayload<T>;
  addedAt: number;
}

export type TicketHoobiizCartItemItem = HoobiizCartItemItemGeneric<HoobiizCartItemType.Ticket>;
export type ExpertTicketHoobiizCartItemItem =
  HoobiizCartItemItemGeneric<HoobiizCartItemType.ExpertTicket>;

export type HoobiizCartItemItem = TicketHoobiizCartItemItem | ExpertTicketHoobiizCartItemItem;

export interface HoobiizOrderItemFailureItem {
  id: string; // a
  v: number; // b
  ts: number; // c
  orderId: HoobiizOrderItemId; // d
  paymentId: StripePaymentIntentId; // e
  error: string; // f
}

export interface HoobiizOrderItemTicketInfo {
  id: string;
  label: string;
  description?: string;
}

export function parseHoobiizOrderItemTicketInfo(
  json: Record<string, unknown>
): HoobiizOrderItemTicketInfo {
  try {
    const parsedHoobiizOrderItemTicketInfoMap = asMapOrThrow(json);
    const parsedHoobiizOrderItemTicketInfoId = asStringOrThrow(
      parsedHoobiizOrderItemTicketInfoMap['id']
    );
    const parsedHoobiizOrderItemTicketInfoLabel = asStringOrThrow(
      parsedHoobiizOrderItemTicketInfoMap['label']
    );
    const parsedHoobiizOrderItemTicketInfoDescription =
      parsedHoobiizOrderItemTicketInfoMap['description'] === undefined
        ? undefined
        : asStringOrThrow(parsedHoobiizOrderItemTicketInfoMap['description']);
    const parsedHoobiizOrderItemTicketInfo = {
      id: parsedHoobiizOrderItemTicketInfoId,
      label: parsedHoobiizOrderItemTicketInfoLabel,
      description: parsedHoobiizOrderItemTicketInfoDescription,
    };
    return parsedHoobiizOrderItemTicketInfo;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOrderItemTicketInfo payload: ${errorAsString(
        err
      )}. Payload for HoobiizOrderItemTicketInfo was ${JSON.stringify(json)}`
    );
  }
}

export function parseHoobiizOrderItemOptions(json: Record<string, unknown>): {
  option: {id: HoobiizTicketInfoOptionId; label: string; description?: string};
  quantity: number;
  total: HoobiizPrices;
}[] {
  try {
    const parsedHoobiizOrderItemOptions = asArrayOrThrow(json).map(element => {
      const parsedElementMap = asMapOrThrow(element);
      const parsedElementOptionMap = asMapOrThrow(parsedElementMap['option']);
      const parsedElementOptionId = asStringOrThrow<HoobiizTicketInfoOptionId>(
        parsedElementOptionMap['id']
      );
      const parsedElementOptionLabel = asStringOrThrow(parsedElementOptionMap['label']);
      const parsedElementOptionDescription =
        parsedElementOptionMap['description'] === undefined
          ? undefined
          : asStringOrThrow(parsedElementOptionMap['description']);
      const parsedElementOption = {
        id: parsedElementOptionId,
        label: parsedElementOptionLabel,
        description: parsedElementOptionDescription,
      };
      const parsedElementQuantity = asNumberOrThrow(parsedElementMap['quantity']);
      const parsedElementTotal = parseHoobiizPrices(asMapOrThrow(parsedElementMap['total']));
      const parsedElement = {
        option: parsedElementOption,
        quantity: parsedElementQuantity,
        total: parsedElementTotal,
      };
      return parsedElement;
    });
    return parsedHoobiizOrderItemOptions;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse HoobiizOrderItemOptions payload: ${errorAsString(
        err
      )}. Payload for HoobiizOrderItemOptions was ${JSON.stringify(json)}`
    );
  }
}

export interface TicketHoobiizOrderItemPayload {
  stockIds: HoobiizStockId[];
  offerId?: HoobiizOfferId;
  ticketFiles?: {id: HoobiizTicketFileId; mimeType: string; size: number}[];
}

export const TicketHoobiizOrderItemPayloadMetadata = {
  stockIds: {type: 'array', schema: {type: 'string', brand: 'HoobiizStockId'}},
  offerId: {type: 'string', brand: 'HoobiizOfferId', optional: true},
  ticketFiles: {
    type: 'array',
    optional: true,
    schema: {
      type: 'object',
      schema: {
        id: {type: 'string', brand: 'HoobiizTicketFileId'},
        mimeType: {type: 'string'},
        size: {type: 'number'},
      },
    },
  },
};

export function parseTicketHoobiizOrderItemPayload(
  json: Record<string, unknown>
): TicketHoobiizOrderItemPayload {
  try {
    const parsedTicketHoobiizOrderItemPayloadMap = asMapOrThrow(json);
    const parsedTicketHoobiizOrderItemPayloadStockIds = asArrayOrThrow(
      parsedTicketHoobiizOrderItemPayloadMap['stockIds']
    ).map(element => {
      const parsedElement = asStringOrThrow<HoobiizStockId>(element);
      return parsedElement;
    });
    const parsedTicketHoobiizOrderItemPayloadOfferId =
      parsedTicketHoobiizOrderItemPayloadMap['offerId'] === undefined
        ? undefined
        : asStringOrThrow<HoobiizOfferId>(parsedTicketHoobiizOrderItemPayloadMap['offerId']);
    const parsedTicketHoobiizOrderItemPayloadTicketFiles =
      parsedTicketHoobiizOrderItemPayloadMap['ticketFiles'] === undefined
        ? undefined
        : asArrayOrThrow(parsedTicketHoobiizOrderItemPayloadMap['ticketFiles']).map(element => {
            const parsedElementMap = asMapOrThrow(element);
            const parsedElementId = asStringOrThrow<HoobiizTicketFileId>(parsedElementMap['id']);
            const parsedElementMimeType = asStringOrThrow(parsedElementMap['mimeType']);
            const parsedElementSize = asNumberOrThrow(parsedElementMap['size']);
            const parsedElement = {
              id: parsedElementId,
              mimeType: parsedElementMimeType,
              size: parsedElementSize,
            };
            return parsedElement;
          });
    const parsedTicketHoobiizOrderItemPayload = {
      stockIds: parsedTicketHoobiizOrderItemPayloadStockIds,
      offerId: parsedTicketHoobiizOrderItemPayloadOfferId,
      ticketFiles: parsedTicketHoobiizOrderItemPayloadTicketFiles,
    };
    return parsedTicketHoobiizOrderItemPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface ExpertTicketHoobiizOrderItemPayload {
  productId: HoobiizExpertTicketProductId;
  date?: number;
  reservationId: string;
  stockId: HoobiizExpertTicketStockId;
  documents?: {saleId: string; index: number; contentType?: string; contentDisposition?: string}[];
}

export const ExpertTicketHoobiizOrderItemPayloadMetadata = {
  productId: {type: 'string', brand: 'HoobiizExpertTicketProductId'},
  date: {type: 'number', optional: true},
  reservationId: {type: 'string'},
  stockId: {type: 'string', brand: 'HoobiizExpertTicketStockId'},
  documents: {
    type: 'array',
    optional: true,
    schema: {
      type: 'object',
      schema: {
        saleId: {type: 'string'},
        index: {type: 'number'},
        contentType: {type: 'string', optional: true},
        contentDisposition: {type: 'string', optional: true},
      },
    },
  },
};

export function parseExpertTicketHoobiizOrderItemPayload(
  json: Record<string, unknown>
): ExpertTicketHoobiizOrderItemPayload {
  try {
    const parsedExpertTicketHoobiizOrderItemPayloadMap = asMapOrThrow(json);
    const parsedExpertTicketHoobiizOrderItemPayloadProductId =
      asStringOrThrow<HoobiizExpertTicketProductId>(
        parsedExpertTicketHoobiizOrderItemPayloadMap['productId']
      );
    const parsedExpertTicketHoobiizOrderItemPayloadDate =
      parsedExpertTicketHoobiizOrderItemPayloadMap['date'] === undefined
        ? undefined
        : asNumberOrThrow(parsedExpertTicketHoobiizOrderItemPayloadMap['date']);
    const parsedExpertTicketHoobiizOrderItemPayloadReservationId = asStringOrThrow(
      parsedExpertTicketHoobiizOrderItemPayloadMap['reservationId']
    );
    const parsedExpertTicketHoobiizOrderItemPayloadStockId =
      asStringOrThrow<HoobiizExpertTicketStockId>(
        parsedExpertTicketHoobiizOrderItemPayloadMap['stockId']
      );
    const parsedExpertTicketHoobiizOrderItemPayloadDocuments =
      parsedExpertTicketHoobiizOrderItemPayloadMap['documents'] === undefined
        ? undefined
        : asArrayOrThrow(parsedExpertTicketHoobiizOrderItemPayloadMap['documents']).map(element => {
            const parsedElementMap = asMapOrThrow(element);
            const parsedElementSaleId = asStringOrThrow(parsedElementMap['saleId']);
            const parsedElementIndex = asNumberOrThrow(parsedElementMap['index']);
            const parsedElementContentType =
              parsedElementMap['contentType'] === undefined
                ? undefined
                : asStringOrThrow(parsedElementMap['contentType']);
            const parsedElementContentDisposition =
              parsedElementMap['contentDisposition'] === undefined
                ? undefined
                : asStringOrThrow(parsedElementMap['contentDisposition']);
            const parsedElement = {
              saleId: parsedElementSaleId,
              index: parsedElementIndex,
              contentType: parsedElementContentType,
              contentDisposition: parsedElementContentDisposition,
            };
            return parsedElement;
          });
    const parsedExpertTicketHoobiizOrderItemPayload = {
      productId: parsedExpertTicketHoobiizOrderItemPayloadProductId,
      date: parsedExpertTicketHoobiizOrderItemPayloadDate,
      reservationId: parsedExpertTicketHoobiizOrderItemPayloadReservationId,
      stockId: parsedExpertTicketHoobiizOrderItemPayloadStockId,
      documents: parsedExpertTicketHoobiizOrderItemPayloadDocuments,
    };
    return parsedExpertTicketHoobiizOrderItemPayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type HoobiizOrderItemPayload<T extends HoobiizOrderItemType> =
  T extends HoobiizOrderItemType.Ticket
    ? TicketHoobiizOrderItemPayload
    : T extends HoobiizOrderItemType.ExpertTicket
      ? ExpertTicketHoobiizOrderItemPayload
      : never;

const hoobiizOrderItemPayloadParsers = {
  [HoobiizOrderItemType.Ticket]: parseTicketHoobiizOrderItemPayload,
  [HoobiizOrderItemType.ExpertTicket]: parseExpertTicketHoobiizOrderItemPayload,
};

export function parseHoobiizOrderItemPayload(
  type: HoobiizOrderItemType,
  json: Record<string, unknown>
): HoobiizOrderItemPayload<HoobiizOrderItemType> {
  return hoobiizOrderItemPayloadParsers[type](json);
}

export const hoobiizOrderItemPayloadMetadata = {
  [HoobiizOrderItemType.Ticket]: TicketHoobiizOrderItemPayloadMetadata,
  [HoobiizOrderItemType.ExpertTicket]: ExpertTicketHoobiizOrderItemPayloadMetadata,
};

export interface HoobiizOrderItemItemGeneric<T extends HoobiizOrderItemType> {
  id: HoobiizOrderItemId;
  userId: FrontendUserId;
  itemType: T;
  itemData: HoobiizOrderItemPayload<T>;
  total: HoobiizPrices;
  fees: CurrencyAmount;
  ticketInfo: HoobiizOrderItemTicketInfo;
  ticketInfoTotal: HoobiizPrices;
  quantity: number;
  activityId: HoobiizActivityId;
  options: {
    option: {id: HoobiizTicketInfoOptionId; label: string; description?: string};
    quantity: number;
    total: HoobiizPrices;
  }[];
  inCartAt: number;
  startedAt: number;
  paymentId: StripePaymentIntentId;
  paymentClientSecret?: string;
  status: HoobiizOrderStatus;
  endedAt?: number;
  confirmationToken?: string;
}

export type TicketHoobiizOrderItemItem = HoobiizOrderItemItemGeneric<HoobiizOrderItemType.Ticket>;
export type ExpertTicketHoobiizOrderItemItem =
  HoobiizOrderItemItemGeneric<HoobiizOrderItemType.ExpertTicket>;

export type HoobiizOrderItemItem = TicketHoobiizOrderItemItem | ExpertTicketHoobiizOrderItemItem;

export interface HoobiizExpertTicketProviderItem {
  id: HoobiizExpertTicketProviderId; // a
  payload: ExpertTicketProviderPayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketProductBaseItem {
  id: HoobiizExpertTicketProductBaseId; // a
  payload: ExpertTicketProductBasePayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketProductItem {
  id: HoobiizExpertTicketProductId; // a
  payload: ExpertTicketProductPayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketTicketItem {
  id: HoobiizExpertTicketTicketId; // a
  payload: ExpertTicketTicketPayload; // b
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketPriceItem {
  id: string; // a
  productId: HoobiizExpertTicketProductId; // b
  ts: number; // c
  cents: number; // d
  createdAt: number; // y
  updatedAt: number; // z
}

export interface HoobiizExpertTicketMetadataItem {
  key: HoobiizExpertTicketMetadataKey; // a
  value: string; // b
}

export interface HoobiizUserGroupItem {
  hashKey: string; // a
  parentGroupId: HoobiizUserGroupId; // b
  lookupKey: string; // c
  groupProfile?: HoobiizUserProfile; // d
  groupDirectUserCount?: number; // e
  groupNestedUserCount?: number; // f
}

export function parseDoNotUseBoolArr(json: Record<string, unknown>): boolean[] {
  try {
    const parsedDoNotUseBoolArr = asArrayOrThrow(json).map(element => {
      const parsedElement = asBooleanOrThrow(element);
      return parsedElement;
    });
    return parsedDoNotUseBoolArr;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse DoNotUseBoolArr payload: ${errorAsString(
        err
      )}. Payload for DoNotUseBoolArr was ${JSON.stringify(json)}`
    );
  }
}

export interface FooDoNotUsePayload {
  foo: number;
}

export const FooDoNotUsePayloadMetadata = {foo: {type: 'number'}};

export function parseFooDoNotUsePayload(json: Record<string, unknown>): FooDoNotUsePayload {
  try {
    const parsedFooDoNotUsePayloadMap = asMapOrThrow(json);
    const parsedFooDoNotUsePayloadFoo = asNumberOrThrow(parsedFooDoNotUsePayloadMap['foo']);
    const parsedFooDoNotUsePayload = {
      foo: parsedFooDoNotUsePayloadFoo,
    };
    return parsedFooDoNotUsePayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export interface BarDoNotUsePayload {
  bar: string;
}

export const BarDoNotUsePayloadMetadata = {bar: {type: 'string'}};

export function parseBarDoNotUsePayload(json: Record<string, unknown>): BarDoNotUsePayload {
  try {
    const parsedBarDoNotUsePayloadMap = asMapOrThrow(json);
    const parsedBarDoNotUsePayloadBar = asStringOrThrow(parsedBarDoNotUsePayloadMap['bar']);
    const parsedBarDoNotUsePayload = {
      bar: parsedBarDoNotUsePayloadBar,
    };
    return parsedBarDoNotUsePayload;
  } catch (err: unknown) {
    throw new Error(
      `Failure to parse payload: ${errorAsString(err)}. Payload was ${JSON.stringify(json)}`
    );
  }
}

export type DoNotUsePayload<T extends DoNotUseType> = T extends DoNotUseType.Foo
  ? FooDoNotUsePayload
  : T extends DoNotUseType.Bar
    ? BarDoNotUsePayload
    : never;

const doNotUsePayloadParsers = {
  [DoNotUseType.Foo]: parseFooDoNotUsePayload,
  [DoNotUseType.Bar]: parseBarDoNotUsePayload,
};

export function parseDoNotUsePayload(
  type: DoNotUseType,
  json: Record<string, unknown>
): DoNotUsePayload<DoNotUseType> {
  return doNotUsePayloadParsers[type](json);
}

export const doNotUsePayloadMetadata = {
  [DoNotUseType.Foo]: FooDoNotUsePayloadMetadata,
  [DoNotUseType.Bar]: BarDoNotUsePayloadMetadata,
};

export interface DoNotUseItemGeneric<T extends DoNotUseType> {
  id: DoNotUseId;
  type: T;
  bool1: boolean;
  string1?: string;
  string2: string;
  number3: number;
  boolArr: boolean[];
  trusted: DoNotUseData<number>;
  payload: DoNotUsePayload<T>;
  numberSet: Set<number>;
  stringSet: Set<string>;
}

export type FooDoNotUseItem = DoNotUseItemGeneric<DoNotUseType.Foo>;
export type BarDoNotUseItem = DoNotUseItemGeneric<DoNotUseType.Bar>;

export type DoNotUseItem = FooDoNotUseItem | BarDoNotUseItem;

export interface DoNotUse2Item {
  id: number; // a
  val?: string; // b
}

export interface DoNotUse3Item {
  id: string; // a
  createdAt: number; // y
  updatedAt: number; // z
}

export type AnyItem =
  | AccountItem
  | RedisItem
  | FrontendUserItem
  | LockItem
  | MaestroDataItem
  | MonitoringItem
  | StreamShardItem
  | DetailedMonitoringItem
  | BuildItem
  | AnalyticsDataItem
  | TaskItem
  | TaskPolicyItem
  | ParameterItem
  | InstanceRequestItem
  | ReportWithFailuresItem
  | DatalakeElementItem
  | DatalakeApiTokenItem
  | DatalakeApiStatsItem
  | DashboardItem
  | ServiceAdArtisanItem
  | ServiceAdCustomerItem
  | ServiceAdRequestItem
  | HoobiizStripeEventItem
  | HoobiizMediaItem
  | HoobiizCat1Item
  | HoobiizCat2Item
  | HoobiizCat3Item
  | HoobiizVendorItem
  | HoobiizActivityItem
  | HoobiizStockItem
  | HoobiizExpertTicketStockItem
  | HoobiizStockWeeklyTemplateItem
  | HoobiizOfferItem
  | HoobiizTicketFileItem
  | HoobiizTicketPurchaseItem
  | HoobiizCartItemItem
  | HoobiizOrderItemFailureItem
  | HoobiizOrderItemItem
  | HoobiizExpertTicketProviderItem
  | HoobiizExpertTicketProductBaseItem
  | HoobiizExpertTicketProductItem
  | HoobiizExpertTicketTicketItem
  | HoobiizExpertTicketPriceItem
  | HoobiizExpertTicketMetadataItem
  | HoobiizUserGroupItem
  | DoNotUseItem
  | DoNotUse2Item
  | DoNotUse3Item;

export type AnyPayload =
  | FrontendUserDataContent
  | AddressLookupResult
  | CurrencyAmount
  | HoobiizDiscount
  | HoobiizTimePeriod
  | HoobiizTimeOfDay
  | HoobiizWeekPeriods
  | HoobiizOpeningHours
  | HoobiizOpeningHoursOverride
  | HoobiizFlexibleStockSchedule
  | HoobiizVendorLocation
  | HoobiizVendorContact
  | HoobiizTicketData
  | HoobiizStockMode
  | HoobiizTicketInfo
  | HoobiizExpertTicketInfo
  | HoobiizTicketInfoOption
  | HoobiizPrices
  | HoobiizPermission
  | HoobiizUserProfile
  | HoobiizActivityAddress
  | HoobiizStockReservation
  | HoobiizOfferTarget
  | HoobiizOfferFlash;

export type DynamoTable =
  | 'Account'
  | 'Redis'
  | 'FrontendUser'
  | 'Lock'
  | 'MaestroData'
  | 'Monitoring'
  | 'StreamShard'
  | 'DetailedMonitoring'
  | 'Build'
  | 'AnalyticsData'
  | 'Task'
  | 'TaskPolicy'
  | 'Parameter'
  | 'InstanceRequest'
  | 'ReportWithFailures'
  | 'DatalakeElement'
  | 'DatalakeApiToken'
  | 'DatalakeApiStats'
  | 'Dashboard'
  | 'ServiceAdArtisan'
  | 'ServiceAdCustomer'
  | 'ServiceAdRequest'
  | 'HoobiizStripeEvent'
  | 'HoobiizMedia'
  | 'HoobiizCat1'
  | 'HoobiizCat2'
  | 'HoobiizCat3'
  | 'HoobiizVendor'
  | 'HoobiizActivity'
  | 'HoobiizStock'
  | 'HoobiizExpertTicketStock'
  | 'HoobiizStockWeeklyTemplate'
  | 'HoobiizOffer'
  | 'HoobiizTicketFile'
  | 'HoobiizTicketPurchase'
  | 'HoobiizCartItem'
  | 'HoobiizOrderItemFailure'
  | 'HoobiizOrderItem'
  | 'HoobiizExpertTicketProvider'
  | 'HoobiizExpertTicketProductBase'
  | 'HoobiizExpertTicketProduct'
  | 'HoobiizExpertTicketTicket'
  | 'HoobiizExpertTicketPrice'
  | 'HoobiizExpertTicketMetadata'
  | 'HoobiizUserGroup'
  | 'DoNotUse'
  | 'DoNotUse2'
  | 'DoNotUse3';
